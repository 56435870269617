<div class="modal-wrapper">
  <div class="title">
    <h1 class="page-title">
      {{'messages.addAPatientLabel' | translate}}
    </h1>

    <mat-icon
      matTooltip="{{'messages.addAPatientTooltipLabel' | translate}}"
      [svgIcon]="'support'"
      matTooltipClass="tooltip">
    </mat-icon>
  </div>

  <div class="small-icon-button">
    <mat-form-field appearance="outline" class="input-container-item-large search-filter">
      <mat-icon matPrefix [svgIcon]="'search'"></mat-icon>

      <input [formControl]="searchName" [placeholder]="'general.searchLabel' | translate" matInput/>

      <button (click)="resetSearch()" mat-icon-button matSuffix class="m-r-1" *ngIf="searchName.value">
        <mat-icon [svgIcon]="'close'"></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div *ngFor="let patient of patients | search:searchName.value"
       [ngClass]="{'selected': selectedPatientsIds.includes(patient.patientId)}"
       class="patient-item">
    <div class="name">
      <span>{{patient.firstName + ' ' + patient.lastName}}</span>

      <mat-icon [svgIcon]="'check'" *ngIf="selectedPatientsIds.includes(patient.patientId)">done</mat-icon>
    </div>

    <div class="basic-icon-button">
      <button [disabled]="selectedPatientsIds.length > 0" (click)="selectPatient(patient.patientId)"
              *ngIf="!selectedPatientsIds.includes(patient.patientId) else removeButton"
              class="button-white transparent-button"
              mat-button>
        <mat-icon [svgIcon]="'add-user'"></mat-icon>

        <span>{{'messages.addAPatientLabel' | translate}}</span>
      </button>
    </div>

    <ng-template #removeButton>
      <button class="transparent-button basic-icon-button button-transparent-red"
              (click)="selectPatient(patient.patientId)" mat-button>
        <mat-icon [svgIcon]="'delete'"></mat-icon>

        {{'general.cancelLabel' | translate}}
      </button>
    </ng-template>
  </div>

  <p *ngIf="!(patients | search:searchName.value)?.length"
     class="no-record-found">{{'messages.noParticipantsToChatLabel' | translate}}
  </p>

  <div class="button-container">
    <button class="transparent-button" mat-button mat-dialog-close>{{'general.cancelLabel' | translate}}</button>

    <button (click)="save()" [disabled]="!selectedPatientsIds.length" class="button-primary"
            mat-button>{{'general.saveLabel' | translate}}</button>
  </div>
</div>
