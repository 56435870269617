import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestAppointmentInterface } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SelectedAppointmentService {
  public selectedAppointment: BehaviorSubject<RequestAppointmentInterface> = new BehaviorSubject(null);

  public setSelectedAppointment(appointment: RequestAppointmentInterface): void {
    this.selectedAppointment.next(appointment);
  }
}
