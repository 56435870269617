import { SubscriptionPaymentIntervalTypeEnum } from '../enums';

export const subscriptionPaymentIntervalTypeConstant = [
  {
    value: SubscriptionPaymentIntervalTypeEnum.Monthly,
    displayedName: 'providerService.monthlyLabel'
  },
  {
    value: SubscriptionPaymentIntervalTypeEnum.EveryThreeMonths,
    displayedName: 'providerService.everyThreeMonthsLabel'
  },
  {
    value: SubscriptionPaymentIntervalTypeEnum.EverySixMonths,
    displayedName: 'providerService.everySixMonthsLabel'
  },
  {
    value: SubscriptionPaymentIntervalTypeEnum.Yearly,
    displayedName: 'providerService.yearlyLabel'
  }
];
