import { Injectable } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { User } from '../models/user.model';

import { DoctorStatusEnum, QueryParameterActionEnum, QueryParameterKeyEnum, RoleEnum, TimeModeEnum } from '../enums';

import { PatientGeneralInfoInterface, PersonalInfoInterface } from '../interfaces';
import { DateTimeService } from './date-time.service';
import { LanguageService } from './language.service';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated: boolean;
  public isDoctor: boolean;
  public timeMode: TimeModeEnum;
  public timeZoneId: string;
  public status$: ReplaySubject<DoctorStatusEnum> = new ReplaySubject(1);
  public userInfo$: BehaviorSubject<PersonalInfoInterface> = new BehaviorSubject(null);
  private currentUserSubject$: BehaviorSubject<User> = new BehaviorSubject(null);
  public currentUser$: Observable<User> = this.currentUserSubject$.asObservable();

  private patientSubject$: BehaviorSubject<PatientGeneralInfoInterface> = new BehaviorSubject(null);
  public patient$: Observable<PatientGeneralInfoInterface> = this.patientSubject$.asObservable();

  constructor(
              private readonly dateTimeService: DateTimeService,
              private readonly languageService: LanguageService,
              private readonly router: Router,
              private readonly oidcSecurityService: OidcSecurityService) {
  }

  public checkAuth(): void {
    this.setRedirectUrl();
    this.setAuthorizeUrl();
    this.oidcSecurityService.checkAuth().subscribe({
      next: (loginResponse) => this.handleAuthentication(loginResponse.isAuthenticated),
      error: () => this.authorize()
    });
  }

  private handleAuthentication(isAuthenticated: boolean): void {
    if (isAuthenticated) {
      this.handleRedirectUrl()
      this.handleAuthenticatedUser();
    } else {
      this.authorize();
    }
  }

  private updateUserDetails(value: any): void {
    this.isAuthenticated = true;
    this.isDoctor = value?.role === RoleEnum.Doctor;
    this.timeZoneId = value?.time_zone_id;
    this.timeMode = +TimeModeEnum[value?.time_mode];
    this.languageService.currentLanguage = value?.locale?.toLowerCase();
    this.dateTimeService.format = this.timeMode === TimeModeEnum.TwelveHours ? 'M/DD/YYYY' : 'DD.MM.YYYY';
    this.dateTimeService.locale = this.languageService.currentLanguage;
    this.currentUserSubject$.next(new User(value));
  }

  private setRedirectUrl(): void {
    if(localStorage.getItem('redirectUrl') === null && window.location.pathname !== '/') {
      localStorage.setItem('redirectUrl', window.location.href );
    }
  }

  private handleRedirectUrl(): void {
    if(localStorage.getItem('redirectUrl') !== null) {
      let location = localStorage.getItem('redirectUrl');

      const urlObject = new URL(location);

      const queryParams = {};
      urlObject.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });

      if(urlObject.pathname !== '/') {
        this.router.navigate([urlObject.pathname], { queryParams });
      }
      localStorage.removeItem('redirectUrl')
    }
  }

  private getQueryParameterFromUrl(): { action: string | null, role: string | null } {
    const urlParams = new URLSearchParams(window.location.search);
    const actionParam = urlParams.get(QueryParameterKeyEnum.Action);
    if (actionParam) {
      switch (actionParam) {
        case QueryParameterActionEnum.Invitation:
          return { action: QueryParameterActionEnum.Invitation, role: urlParams.get(QueryParameterKeyEnum.Role) };
        default:
          return { action: null, role: null };
      }
    }

    return { action: null, role: null };
  }

  private setAuthorizeUrl(): void {
    if (window.location.search?.length) {
      sessionStorage.setItem('authorizeUrl', window.location.search);
      this.router.navigate([], {
        queryParams: null,
        queryParamsHandling: 'merge'
      })
    }
  }

  public handleAuthenticatedUser(): void {
    this.oidcSecurityService.userData$.pipe(filter(value => !!value.userData)).subscribe((value) => {
      this.updateUserDetails(value.userData);

    });
  }

  public setPatient(patient: PatientGeneralInfoInterface): void {
    this.patientSubject$.next(patient);
  }

  public authorize(): void {
    const customParams = this.getQueryParameterFromUrl();
    const params: { [key: string]: string } = {};

    if (customParams.action) {
      params[QueryParameterKeyEnum.Action] = customParams.action;
    }

    if (customParams.role) {
      params[QueryParameterKeyEnum.Role] = customParams.role;
    }

    this.oidcSecurityService.authorize('', { customParams: params });
  }

  public renewToken(): void {
    this.oidcSecurityService.forceRefreshSession().subscribe();
  }

  public logout(): void {
    localStorage.removeItem('mobileHeader');
    this.oidcSecurityService.logoff().subscribe();
  }

  public changePassword(): void {
    this.oidcSecurityService.authorize('', {
      customParams: {
        [QueryParameterKeyEnum.Action]: QueryParameterActionEnum.ChangePassword
      }
    });
  }

  public getRedirectUrl(): string | null {
    return sessionStorage.getItem('authorizeUrl');
  }
}
