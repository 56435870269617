import { Component, Input } from '@angular/core';

import { DoctorConfigurationInterface, DoctorInterface } from '../../../core/interfaces';
import { ServiceTabsEnum } from '../../../core/enums';

@Component({
  selector: 'vi-clinic-doctor-profile-personal-info',
  templateUrl: './doctor-profile-personal-info.component.html',
  styleUrls: ['./doctor-profile-personal-info.component.scss']
})
export class DoctorProfilePersonalInfoComponent {
  @Input() public personalInfo: DoctorInterface;
  @Input() public isDialog: boolean = false;
  @Input() public doctorConfiguration: DoctorConfigurationInterface;

  public serviceTabsEnum = ServiceTabsEnum;
}
