import { Component, Input } from '@angular/core';

@Component({
  selector: 'vi-clinic-image-empty-state',
  templateUrl: './image-empty-state.component.html',
  styleUrls: ['./image-empty-state.component.scss']
})
export class ImageEmptyStateComponent {
  @Input() public src: string;
  @Input() public title: string;
  @Input() public subtitle: string;
}
