import { PaymentProviderTypeEnum, PaymentStatusEnum } from '../../enums';

export interface PaymentInterface {
  providerType: PaymentProviderTypeEnum;
  paymentStatus: PaymentStatusEnum;
  amount: number;
  currency: string;
  description: string;
  id: string;
  coveredBySubscription: boolean
}
