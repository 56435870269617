<div class="modal-wrapper">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="{{'patients.addViClinicPatientLabel' | translate}}">
      <div class="content-wrapper">
        <div class="title">
          <h1 class="page-title">
            {{'patients.addAnExistingViClinicPatientLabel' | translate}}
          </h1>

          <mat-icon
            matTooltip="{{'patients.addAnExistingViClinicPatientTooltipLabel' | translate}}"
            [svgIcon]="'support'"
            matTooltipClass="tooltip">
          </mat-icon>
        </div>

        <mat-form-field appearance="outline" class="input-container-item-large m-t-2 mb-2">
          <mat-label>{{'patientEHR.searchPatientLabel' | translate}}</mat-label>

          <input [matAutocomplete]="auto"
                 [formControl]="patientControl"
                 matInput/>

          <button mat-icon-button matSuffix class="m-r-1" (click)="resetSearch()" *ngIf="patientControl.value">
            <mat-icon [svgIcon]="'close'"></mat-icon>
          </button>

          <mat-autocomplete [displayWith]="displayFn" #auto="matAutocomplete" autoActiveFirstOption class="no-scroll">
            <div (scroll)="onListScroll($event)" class="option-scroll">
              <mat-option
                *ngFor="let patient of patients"
                [value]="patient">
                {{patient.fullName}} {{patient.phoneNumber | mask}}
              </mat-option>

              <mat-option *ngIf="patients?.length === 0" class="link-button alarm-message">
                {{'patientVisit.noRecordsLabel' | translate}}
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>

        <div class="button-container">
          <button (click)="onNoClick(false)" class="transparent-button" mat-button>
            {{'general.cancelLabel' | translate}}
          </button>

          <button (click)="addFavoriteUser()" [disabled]="!patientId" class="button-primary" mat-button>
            {{'general.addLabel' | translate}}
          </button>
        </div>
      </div>

    </mat-tab>
    <mat-tab label="{{'patients.registerNewPatientLabel' | translate}}">
      <div class="content-wrapper">
        <div class="d-flex gap-1-6 align-items-center m-t-2">
          <h1 class="page-title">
            {{'patients.registerNewPatientLabel' | translate}}
          </h1>

          <mat-icon
            matTooltip="{{'patients.registerNewPatientTooltipLabel' | translate}}"
            matTooltipClass="wide-tooltip"
            [svgIcon]="'support'"
            matTooltipPosition="right">
          </mat-icon>
        </div>

        <vi-clinic-register-new-patient #inviteUser
                               (closeDialog)="onNoClick(false)"
                               (sendRegistrationEmitter)="createPatient($event)">
        </vi-clinic-register-new-patient>
      </div>
    </mat-tab>
    <mat-tab label="{{'patients.inviteToViClinicLabel' | translate}}">
      <div class="content-wrapper">
        <h1 class="page-title">
          {{'myNetwork.invitePatientsLabel' | translate}}
        </h1>

        <vi-clinic-invite-user class="h-100" #inviteUser
                               (closeDialog)="onNoClick(false)"
                               (sendInvitationEmitter)="sendInvitation($event)">
        </vi-clinic-invite-user>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
