<mat-accordion>
  <mat-expansion-panel *ngFor="let symptom of symptoms">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="d-flex align-items-center gap-1-6">
          <div class="panel-title">{{'symptoms.symptomLabel' | translate}}</div>
          <div class="panel-date">{{ symptom.createdTimestamp | localDate:'longDateTime'  }}</div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="d-flex flex-col gap-1-6">
      <div class="panel-block">
        <div class="content-label">{{'symptoms.currentSymptomLabel' | translate}}</div>
        <div class="content-description">{{symptom.symptom}}
        </div>
      </div>
      <div class="panel-block">
        <div class="content-label">{{'symptoms.drViSummaryLabel' | translate}}</div>
        <div class="content-description">{{ symptom.summary }}</div>
      </div>

      <div class="d-flex">
        <button (click)="openTranscription(symptom.id)" class="button-primary" mat-button>{{'symptoms.showConversationLabel' | translate}}</button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
