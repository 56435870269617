export interface PrescriptionInterface {
  tradeName?: string;
  genericName?: string;
  strength: number;
  strengthType: number;
  usageCount: number;
  usageForm: number;
  usageRoute: number;
  usageReps: number;
  usageFrequency: number;
  during: number;
  description: string;
  appointmentId?: string;
  id?: string;
  userId?: string;
  recommendationId?: string;
}
