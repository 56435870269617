import { Component } from '@angular/core';
import { AuthService } from '../../services';


@Component({
  templateUrl: './rejected.component.html',
  styleUrls: ['./rejected.component.scss']
})
export class RejectedComponent {
  constructor(private readonly authService: AuthService) {
  }

  public logout(): void {
    this.authService.logout();
  }
}
