<div (scroll)="onListScroll($event)" class="paginator">
  <ng-content></ng-content>

  <p *ngIf="noRecords" class="no-record-found">{{'myNetwork.noRecordsFoundLabel' | translate}}</p>

 <div class="show-more-wrapper">
   <button *ngIf="showMoreButton && loadDataByClick"  (click)="loadData()" mat-button class="button-primary">{{'general.showMoreLabel' | translate}}
   </button>

   <mat-icon *ngIf="isLoading" class="paginator-spinner">
     <mat-spinner color="accent" diameter="18">
     </mat-spinner>
   </mat-icon>
 </div>
</div>
