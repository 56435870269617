import { Injectable } from '@angular/core';
import { WorkExperienceInterface } from '../../interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkExperienceApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/WorkExperience`;

  constructor(private readonly httpClient: HttpClient) { }

  public saveWorkExperience(data: WorkExperienceInterface): Observable<WorkExperienceInterface> {
    return this.httpClient.post<WorkExperienceInterface>(this.apiUrl, data);
  }

  public updateWorkExperience(data: WorkExperienceInterface, id: string): Observable<WorkExperienceInterface> {
    return this.httpClient.put<WorkExperienceInterface>(`${this.apiUrl}/${id}`, data);
  }

  public deleteWorkExperience(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}/${id}`);
  }
}
