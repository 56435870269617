export interface FavoriteDoctorInterface {
  id: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  specialtyName: string;
  specialtyDescription: string;
  biography: string;
  countryName: string;
  countryCode: string;
  city: string;
  state: string;
  specialtyId: string;
  isVirtualAssistant: boolean;
  fullName?: string;
}
