import { SubscriptionPriceInterface } from './subscription-price.interface';
import { ProviderServiceFeatureTypeEnum, ProviderServiceStatusEnum } from '../../enums';
import { PaymentPriceInterface } from './payment-price.interface';

export interface ProviderServiceInterface {
  name: string,
  description: string,
  serviceFeatureTypes: ProviderServiceFeatureTypeEnum,
  subscriptionPrices: SubscriptionPriceInterface[],
  paymentPrices: PaymentPriceInterface[];
  status: ProviderServiceStatusEnum,
  id: string
}


