import { Injectable } from '@angular/core';
import { EducationExperienceInterface } from '../../interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class EducationApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/Education`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public saveEducationExperience(data: EducationExperienceInterface): Observable<EducationExperienceInterface> {
    return this.httpClient.post<EducationExperienceInterface>(this.apiUrl, data);
  }

  public updateEducationExperience(data: EducationExperienceInterface, id: string): Observable<EducationExperienceInterface> {
    return this.httpClient.put<EducationExperienceInterface>(`${this.apiUrl}/${id}`, data);
  }

  public deleteEducationExperience(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}/${id}`);
  }
}
