import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  PostInvitationUserInterface,
  ValidateInvitationInterface,
  ValidateInvitationResponseInterface
} from '../../interfaces';


@Injectable({ providedIn: 'root' })
export class InvitationsApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/Invitation`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public acceptInvitationByCode(code: string): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/by-code?code=${code}`, null);
  }

  public validateInvitation(body: ValidateInvitationInterface): Observable<ValidateInvitationResponseInterface> {
    return this.httpClient.post<ValidateInvitationResponseInterface>(`${this.apiUrl}/validate`, body);
  }

  public postInvitation(user: number, inviteUser: PostInvitationUserInterface): Observable<PostInvitationUserInterface> {
    return this.httpClient.post<PostInvitationUserInterface>(`${this.apiUrl}/${user}`, [inviteUser]);
  }

  public deleteInvitation(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${id}`);
  }
}
