<div class="modal-wrapper">
  <vi-clinic-provide-ehr-access
    (hasEhrAccess)="hasEhrAccess = $event"
    [selectedUser]="data.selectedPatient"
    [medicalHistoryAvailability]="data.medicalHistoryAvailability">
  </vi-clinic-provide-ehr-access>

  <div class="button-container">
    <button class="transparent-button" mat-button mat-dialog-close>{{'general.cancelLabel' | translate}}</button>

    <button (click)="save()" [disabled]="!hasEhrAccess" class="button-primary"
            mat-button>{{'general.saveLabel' | translate}}</button>
  </div>
</div>
