import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';

import { Observable, throwError } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';


@Injectable()
export class Interceptor implements HttpInterceptor {

  private oidc: OidcSecurityService;

  constructor(private readonly injector: Injector) {
  }

  public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): any {
    if (!this.oidc) {
      this.oidc = this.injector.get(OidcSecurityService);
    }

    if (!this.oidc) {
      return next.handle(httpRequest);
    }

    if (httpRequest.url.includes('connect/token')) {
      return this.handleTokenRequest(httpRequest, next);
    }

    return this.oidc.getAccessToken().pipe(
      first(),
      mergeMap((token) => {
        if (!!token) {
          const clonedRequest = httpRequest.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });

          return next.handle(clonedRequest).pipe(
            map((event) => event),
            catchError((error) => this.handleError(error))
          );
        } else {
          return next.handle(httpRequest);
        }
      })
    );
  }

  private handleTokenRequest(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(httpRequest).pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((error as HttpErrorResponse).status) {
            case 400:
              this.oidc.logoff().subscribe()

              return throwError(error);
            default:
              return throwError(error);
          }
        }
      }))
  }

  private handleError(error: any): Observable<HttpEvent<any>> {
    if (error instanceof HttpErrorResponse) {
      switch ((error as HttpErrorResponse).status) {
        case 401:
          this.oidc.logoff().subscribe();

          return throwError(error);
        default:
          return throwError(error);
      }
    } else {
      return throwError(error);
    }
  }
}
