import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEs from '@angular/common/locales/es';
import { SharedModule } from '../shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RejectedComponent } from './components/rejected/rejected.component';
import { PendingApprovalComponent } from './components/pending-approval/pending-approval.component';
import { Interceptor } from './helpers/interceptor';
import { SpinnerInterceptor } from './helpers/spinner.interceptor';
import { LanguageInterceptor } from './helpers/language.interceptor.service';
import { environment } from '../../environments/environment';
import { MobileHeaderComponent } from './components/mobile-header/mobile-header.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(localeEs);

const COMPONENTS = [
  ForbiddenComponent,
  NotFoundComponent,
  SpinnerComponent,
  SidebarComponent,
  RejectedComponent,
  PendingApprovalComponent,
  MobileHeaderComponent,
];
const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  SharedModule,
  HttpClientModule,
  AuthModule.forRoot({
    config: {
      authority: environment.securityAddress,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.clientId,
      scope: 'openid profile core offline_access',
      responseType: 'code',
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      logLevel: LogLevel.Warn,
      renewTimeBeforeTokenExpiresInSeconds: 120,
      triggerAuthorizationResultEvent: true,
      startCheckSession: false,
      useRefreshToken: true,
      disableRefreshIdTokenAuthTimeValidation: true,
      renewUserInfoAfterTokenRenew: true
    }
  }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  }),
  CommonModule,
  FormsModule,
  CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  })
];
const INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: Interceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SpinnerInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LanguageInterceptor,
    multi: true
  }
];

@NgModule({
    declarations: [
      ...COMPONENTS
    ],
    imports: [
      ...MODULES
    ],
    exports: [
      ...COMPONENTS
    ],
    providers: [
      ...INTERCEPTORS
    ]
})
export class CoreModule {
}
