import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecommendationInterface } from '../../../core/interfaces';
import { ValidationService } from '../../../core/services';
import { RecommendationFormGroup } from '../../../core/types';


@Component({
  selector: 'vi-clinic-edit-recommendation',
  templateUrl: './edit-recommendation.component.html',
  styleUrls: ['./edit-recommendation.component.scss']
})
export class EditRecommendationComponent implements OnInit {
  @Input() public data: {
    summary: RecommendationInterface,
    isEdit: boolean
  };
  @Input() public isOffline: boolean = false;

  @Output() public closeForm = new EventEmitter<void>();
  @Output() public setForm = new EventEmitter<RecommendationInterface>();

  public recommendationFormGroup: FormGroup<RecommendationFormGroup>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly validationService: ValidationService
  ) {
  }

  public ngOnInit(): void {
    this.form();
  }

  public onCloseForm(): void {
    this.closeForm.emit();
  }

  public saveForm(): void {
    this.setForm.emit(this.recommendationFormGroup.value as RecommendationInterface);
  }

  private form(): void {
    this.recommendationFormGroup = this.fb.group<RecommendationFormGroup>({
      note: this.fb.control(this.data?.summary?.note || '', [Validators.required, this.validationService.whitespaceValidator]),
      furtherInvestigation: this.fb.control(this.data?.summary?.furtherInvestigation || '', [Validators.required, this.validationService.whitespaceValidator]),
      testResults: this.fb.control(this.data?.summary?.testResults || '', [this.validationService.whitespaceValidator]),
      diagnose: this.fb.control(this.data?.summary?.diagnose || '', [Validators.required, this.validationService.whitespaceValidator]),
      id: this.fb.control(this.data?.summary?.id)
    });
  }
}
