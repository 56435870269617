export interface CardInterface {
  brand: string;
  country: string;
  expirationMonth: number;
  expirationYear: number;
  id: string;
  isDefault: boolean;
  lastFour: string;
  name: string;
  shortExpirationYear?: string;
  isExpired?: boolean;
}
