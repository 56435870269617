import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'vi-clinic-chat-footer',
  templateUrl: './chat-footer.component.html',
  styleUrls: ['./chat-footer.component.scss']
})
export class ChatFooterComponent {
  @Input() public isAttachmentsAllowed: boolean = true;
  @Input() public isLoading: boolean = false;
  @Output() public attachFileClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public sendMessageEmitter: EventEmitter<string> = new EventEmitter<string>();

  public sendMessageControl: FormControl<string> = this.fb.control('');
  public isSendMessageControlFocused: boolean = false;

  constructor(private readonly fb: FormBuilder) {
  }

  public sendMessage(): void {
    this.sendMessageEmitter.emit(this.sendMessageControl.value);
    this.sendMessageControl.reset();
  }
}
