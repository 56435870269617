import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubscriptionRequestInterface } from '../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionRequestsApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/SubscriptionRequests`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getSubscriptionRequest(id: string): Observable<SubscriptionRequestInterface> {
    return this.httpClient.get<SubscriptionRequestInterface>(`${this.apiUrl}/${id}`);
  }

  public getSubscriptionRequests(): Observable<SubscriptionRequestInterface[]> {
    return this.httpClient.get<SubscriptionRequestInterface[]>(this.apiUrl);
  }

  public createSubscriptionRequest(subscriptionPaymentOptionId: string): Observable<SubscriptionRequestInterface> {
    return this.httpClient.post<SubscriptionRequestInterface>(this.apiUrl, { subscriptionPaymentOptionId });
  }

  public approveSubscriptionRequest(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${id}/approve`, null);
  }

  public rejectSubscriptionRequest(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${id}/reject`, null);
  }

  public cancelSubscriptionRequest(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${id}/cancel`, null);
  }

  public acceptSubscriptionRequest(id: string): Observable<{ result: string }> {
    return this.httpClient.patch<{ result: string }>(`${this.apiUrl}/${id}/accept`, null);
  }
}
