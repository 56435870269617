import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileUploaderTypeEnum } from '../../../core/enums';
import { NotificationService, ValidationService } from '../../../core/services';
import { FileUploadFormGroup } from '../../../core/types/form-groups/file-upload-form-group';
import { FileSizeConverterPipe } from '../../pipes/file-size-converter.pipe';

@Component({
  selector: 'vi-clinic-chat-file-upload-dialog',
  templateUrl: './chat-file-upload-dialog.component.html',
  styleUrls: ['./chat-file-upload-dialog.component.scss'],
  providers: [FileSizeConverterPipe]
})
export class ChatFileUploadDialogComponent {
  @ViewChild('messageAttachment') messageAttachment: ElementRef<HTMLInputElement>;

  // CONSTANTS
  public FILE_UPLOADER_TYPE: FileUploaderTypeEnum = FileUploaderTypeEnum.SINGLE; // If we want multiple file upload change this variable to MULTIPLE
  public MAX_FILE_SIZE: number = 50000000; // 50MB (50,000,000 bytes), change to allow more file size upload (MB NOT MiB)
  public ALLOWED_FILE_TYPES: string[] = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'text/plain', 'application/pdf'];
  public ACCEPT_EXTENSION_TYPES = '.jpg, .jpeg, .png, .doc, .docx, .txt, .pdf'; // Allowed file type extensions

  public fileUploaderTypeEnum = FileUploaderTypeEnum;
  public uploadExtensionsLabel: string = '';
  public fileForm: FormGroup<FileUploadFormGroup>;

  constructor(
    private readonly dialogRef: MatDialogRef<ChatFileUploadDialogComponent>,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly fb: FormBuilder,
    private readonly validationService: ValidationService,
    private readonly fileSizeConverterPipe: FileSizeConverterPipe
  ) {
    this.createForm();
    this.translateExtensionLabels();
  }

  private createForm(): void {
    this.fileForm = this.fb.group<FileUploadFormGroup>({
      file: this.fb.control(null, [this.validationService.fileSizeValidator(this.MAX_FILE_SIZE), this.validationService.fileTypeValidator(this.ALLOWED_FILE_TYPES)])
    });
  }

  private translateExtensionLabels(): void {
    this.uploadExtensionsLabel = this.translateService.instant(
      'fileUpload.uploadExtensionsLabel',
      { extensions: this.ACCEPT_EXTENSION_TYPES, size: this.fileSizeConverterPipe.transform(this.MAX_FILE_SIZE) }
    );
  }

  public onFileSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (file) {
        this.fileForm.get('file').patchValue(file);
      }
    }
  }

  public removeFile(): void {
    this.messageAttachment.nativeElement.value = null;
    this.fileForm.reset();
  }

  public sendMessage(message: string): void {
    if (this.fileForm.get('file').value && this.fileForm.invalid) {
      this.notificationService.showErrorNotification(
        null,
        this.translateService.instant('fileUpload.failed')
      );
    } else {
      this.dialogRef.close({ message, file: this.fileForm.get('file').value });
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
