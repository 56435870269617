import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProviderServiceFeatureTypeEnum } from '../../../core/enums';
import { subscriptionServiceOptionsConstant } from '../../../core/constants';

@Component({
  selector: 'vi-clinic-subscription-option',
  templateUrl: './subscription-option.component.html',
  styleUrls: ['./subscription-option.component.scss']
})
export class SubscriptionOptionComponent implements OnChanges {
  @Input() public serviceFeatureTypes: number;
  @Input() public isPatientMode: boolean = false;

  public options: { name: string, code: number, icon: string }[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if ('serviceFeatureTypes' in changes && changes.serviceFeatureTypes.currentValue) {
      this.options = [];
      const subscriptionServicesValues = Object.values(ProviderServiceFeatureTypeEnum).filter(x => typeof x === 'number');

      subscriptionServicesValues.forEach((value: number) => {
        if (this.serviceFeatureTypes & value) {
          const option = subscriptionServiceOptionsConstant.find((el) => el.code === value)
          this.options.push(option);
        }})
    }
  }
}
