<div class="h-100 d-flex flex-col justify-content-space-between" [formGroup]="inviteFormGroup">
  <div>
    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item-large">
        <mat-label>{{ 'profileSettings.firstNameLabel' | translate }}</mat-label>

        <input formControlName="firstName" matInput/>
      </mat-form-field>
    </div>

    <vi-clinic-control-validation-message [control]="inviteFormGroup?.controls.firstName">
    </vi-clinic-control-validation-message>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item-large">
        <mat-label>{{ 'profileSettings.lastNameLabel' | translate }}</mat-label>

        <input formControlName="lastName" matInput/>
      </mat-form-field>
    </div>

    <vi-clinic-control-validation-message [control]="inviteFormGroup.controls.lastName">
    </vi-clinic-control-validation-message>

    <div class="input-container">
      <ngx-intl-tel-input #telInput
                          *ngIf="(onlyCountries$ | async) as onlyCountries"
                          (click)="countriesClicked($event, telInput)"
                          [(ngModel)]="phoneObject"
                          [cssClass]="phoneNumber.invalid && (phoneNumber.touched && phoneNumber.dirty) ? 'invalid' : ''"
                          [enableAutoCountrySelect]="true"
                          [enablePlaceholder]="true"
                          [maxLength]="15"
                          [onlyCountries]="onlyCountries"
                          [phoneValidation]="true"
                          [preferredCountries]="preferredCountries"
                          [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
                          [searchCountryFlag]="true"
                          [selectFirstCountry]="false"
                          [selectedCountryISO]="selectCountry || countryISO.UnitedStates"
                          [separateDialCode]="separateDialCode"
                          formControlName="phoneNumber">
      </ngx-intl-tel-input>

      <span class="phone-number-top-label">{{ 'profileSettings.phoneNumberLabel' | translate }}*</span>
    </div>

    <div class="input-container">
      <mat-form-field appearance="outline" class="input-container-item-large">
        <mat-label>{{ 'profileSettings.emailAddressLabel' | translate }}</mat-label>

        <input formControlName="email" matInput/>
      </mat-form-field>
    </div>

    <vi-clinic-control-validation-message [control]="inviteFormGroup.controls.email">
    </vi-clinic-control-validation-message>
  </div>

  <div class="button-container">
    <button (click)="closeDialog.emit()" class="transparent-button" mat-button>
      {{ 'general.cancelLabel' | translate }}
    </button>

    <button (click)="sendInvitation()" [disabled]="inviteFormGroup.invalid"
            class="button-primary" mat-button>
      {{ 'myNetwork.sendInvitationLabel' | translate }}
    </button>
  </div>
</div>
