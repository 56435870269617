import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): any {
    const languageCode : string = localStorage.getItem('currentLanguageLocale');

    if (httpRequest.url.includes(environment.apiAddress)) {
      return next.handle(
        httpRequest.clone({
          setParams: { language: languageCode }
        }));
    }

    return next.handle(httpRequest);
  }
}


