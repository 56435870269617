<mat-horizontal-stepper #stepper [linear]="true">
  <mat-step>
    <ng-template matStepLabel>
      <h1 class="page-title">
        {{ 'general.acceptRequestLabel' | translate }}
      </h1>
    </ng-template>

    <div class="verification-step-content">
      <div class="d-flex gap-1-6 align-items-center m-t-2">
        <h1 class="page-title">
          {{ 'patients.verifyRegistrationLabel' | translate }}
        </h1>

        <mat-icon
          matTooltip="{{ 'patients.verifyRegistrationTooltipLabel' | translate }}"
          matTooltipClass="wide-tooltip"
          [svgIcon]="'support'"
          matTooltipPosition="right">
        </mat-icon>
      </div>

      <h2 class="step-label m-t-1-6 m-b-1-6">{{ 'patients.stepTreeLabel' | translate }}</h2>

      <div class="input-container">
        <mat-form-field appearance="outline" class="input-container-item-large">
          <mat-label>{{ 'patients.addVerificationDigitCodeReceivedByPatientLabel' | translate }}</mat-label>

          <input matInput [formControl]="smsCode" type="number"/>
        </mat-form-field>

        <div class="d-flex gap-1-6 align-items-center">
          <button (click)="resendCode()" class="button-primary" mat-button>
            {{ 'patients.resendVerificationCodePatientLabel' | translate }}
          </button>

          <mat-icon
            matTooltip="{{ 'patients.resendVerificationCodePatientTooltipLabel' | translate }}"
            matTooltipClass="wide-tooltip"
            [svgIcon]="'support'"
            matTooltipPosition="right">
          </mat-icon>
        </div>

        <div class="m-t-auto w-100">
          <h2 class="step-label m-b-1-6">{{ 'patients.stepFourLabel' | translate }}</h2>

          <div class="button-container w-100">
            <button (click)="closeDialog()" class="transparent-button" mat-button>
              {{ 'general.closeLabel' | translate }}
            </button>

            <button [disabled]="!smsCode.value" (click)="sendCode(stepper)" class="button-primary" mat-button>
              {{ 'general.saveLabel' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>
      <h1 class="page-title">
        {{ 'patients.patientsEHRLabel' | translate }} {{ 'patientEHR.personalInformationLabel' | translate | lowercase }}
      </h1>
    </ng-template>

    <vi-clinic-offline-ehr-general-information (noClick)="closeDialog()"
                                               (patient)="addPersonalInfo($event)"
                                               [patientId]="data.patientId">
    </vi-clinic-offline-ehr-general-information>
  </mat-step>
</mat-horizontal-stepper>

