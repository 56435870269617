import { PaymentProviderTypeEnum } from '../../enums';

export interface PriceResponseInterface {
  price: number;
  minPrice: number,
  maxPrice: number,
  currency: string;
  paymentProvider: PaymentProviderTypeEnum;
  coveredBySubscription: boolean;
}
