import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const COMPONENTS = [AppComponent];
const MODULES = [
  CoreModule,
  SharedModule,
  AppRoutingModule
];


@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    ...MODULES
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
