import {
  EducationExperienceInterface,
  LanguageInterface,
  LicenseInterface,
  WorkExperienceInterface
} from '../profile-settings';

export interface DoctorInterface {
  averageRating: number;
  biography: string;
  city: string;
  countryCode: string;
  countryName: string;
  defaultLanguage: number;
  educations: EducationExperienceInterface[];
  firstName: string;
  id: string;
  isVirtualAssistant: boolean;
  languages: LanguageInterface[];
  lastName: string;
  photoUrl: string;
  specialtyDescription: string;
  specialtyId: string;
  specialtyName: string;
  state: string;
  workExperiences: WorkExperienceInterface[];
  isFavorite: boolean;
  medicalLicenses: LicenseInterface[];
  fullName: string;
}
