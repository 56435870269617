import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PatientVisitComponent } from '../../components/patient-visit/patient-visit.component';

import { PrescriptionInterface } from '../../../core/interfaces';


@Component({
  templateUrl: './edit-prescription-dialog.component.html',
  styleUrls: ['./edit-prescription-dialog.component.scss']
})
export class EditPrescriptionDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<PatientVisitComponent>,
              @Inject(MAT_DIALOG_DATA) public readonly data: {
                prescription?: PrescriptionInterface,
                isEdit: boolean
              }) {
  }

  public saveForm(data: PrescriptionInterface): void {
    this.dialogRef.close(data);
  }

  public closeForm(): void {
    this.dialogRef.close();
  }
}
