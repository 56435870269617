import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '../services';

import { RoleEnum } from '../enums';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(private readonly authService: AuthService,
              private readonly router: Router) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean> {
    const roles: RoleEnum[] = route.data.roles;

    return this.authService.currentUser$.pipe(
      filter(user => !!user),
      map((user) => {
        if (user.role === RoleEnum.Patient && route.routeConfig.path === 'requests') {
          this.router.navigate(['visits']);

          return false;
        }

        if (roles.includes(user.role)) {
          return true;
        } else {
          this.router.navigate(['/403']);

          return false;
        }
      })
    );
  }
}

export const RoleGuard: CanActivateFn = (next: ActivatedRouteSnapshot): Observable<boolean> | boolean => inject(RoleGuardService).canActivate(next);

