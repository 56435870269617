import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  CreatePatientInterface,
  PostInvitationUserInterface,
} from '../../../core/interfaces';
import {
  FavoriteUserApiService,
  InvitationsApiService,
  NotificationService,
  PatientApiService,
  PatientSearchApiService
} from '../../../core/services';
import { RegistrationProcessConfirmationDialogComponent } from '../registration-process-confirmation-dialog/registration-process-confirmation-dialog.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { InvitationUserEnum } from '../../../core/enums';
import { InviteUserComponent } from '../../components/invite-user/invite-user.component';
import { PatientSearchInterface } from '../../../core/interfaces/patients/patient-search.interface';
import { RegistrationPatientDialogComponent } from '../registration-patient-dialog/registration-patient-dialog.component';


@Component({
  selector: 'vi-clinic-add-new-patients-dialog',
  templateUrl: './add-new-patients-dialog.component.html',
  styleUrls: ['../add-new-patients-dialog/add-new-patients-dialog.component.scss'],
})
export class AddNewPatientsDialogComponent implements OnInit, OnDestroy {
  @ViewChild('inviteUser') public inviteUserComponent: InviteUserComponent;

  public patientId: string;
  public patients: PatientSearchInterface[] = [];
  public patientControl: FormControl<string | PatientSearchInterface> = this.fb.control(null);

  private patientsPage: number = 0;
  private totalPatient: number = 0;

  private reachedListBottom$: Subject<void> = new Subject();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly dialog: MatDialog,
              private readonly dialogRef: MatDialogRef<AddNewPatientsDialogComponent>,
              private readonly fb: FormBuilder,
              private readonly invitationsApiService: InvitationsApiService,
              private readonly patientSearchApiService: PatientSearchApiService,
              private readonly notificationService: NotificationService,
              private readonly patientApiService: PatientApiService,
              private readonly favoriteUserApiService: FavoriteUserApiService) {
  }


  @HostListener('window:beforeunload', ['$event'])
  public unloadHandler(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = '';
  }

  public ngOnInit(): void {
    this.subscribeToSearchControl();
    this.subscribeScrollBottom();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onNoClick(result: boolean | string): void {
    if (!result) {
      this.openConfirmDialog();
    } else {
      this.dialogRef.close(result);
    }
  }

  public createPatient(event: CreatePatientInterface): void {
    this.patientApiService.createPatient(event).pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
          this.registerPatient(result.id);
          this.notificationService.showSuccessNotification();
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.showErrorNotification(error?.error?.detail);
        });
  }

  public sendInvitation(data: PostInvitationUserInterface): void {
    this.invitationsApiService.postInvitation(InvitationUserEnum.Patient, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.inviteUserComponent.inviteFormGroup.reset();
          this.notificationService.showSuccessNotification();
          this.dialogRef.close();
        },
        (error) => this.notificationService.showErrorNotification(error.error.detail)
      );
  }

  public displayFn(user: PatientSearchInterface): string {
    return user && user.fullName ? user.fullName : '';
  }

  public resetSearch(): void {
    this.patientControl.reset();
    this.patients = [];
    this.patientId = null;
    this.patientsPage = 0;
  }

  public addFavoriteUser(): void {
    this.favoriteUserApiService.favoriteUser(this.patientId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.notificationService.showSuccessNotification();
          this.dialogRef.close(true);
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail)
      );
  }

  public onListScroll($event) {
    const offsetBottom = $event.target.scrollHeight - $event.target.offsetHeight - $event.target.scrollTop;

    if (offsetBottom < 100 && this.totalPatient > this.patients.length) {
      this.reachedListBottom$.next();
    }
  }

  private getPatients(search: string, onScroll = false): void {
    if (!!search && search?.length > 1) {
      this.patientSearchApiService.patientSearch(this.patientsPage, 20, search)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (patients) => {
            if (onScroll) {
              this.patients.push(...patients.items);
              this.patientsPage++;
            } else {
              this.patients = patients.items
              this.patientsPage = 1;
            }

            this.totalPatient = patients.total;
            this.patients.forEach(element => element.fullName = element.firstName + ' ' + element.lastName);
          },
          (error) => this.notificationService.showErrorNotification(error?.error?.detail)
        );
    }
  }

  private openConfirmDialog(): void {
    const confirmDialog = this.dialog.open(RegistrationProcessConfirmationDialogComponent, {
      data: { title: 'notification.anSavedDataLabel' },
      disableClose: true
    });
    confirmDialog.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(value => value ? this.dialogRef.close() : '');
  }

  private subscribeToSearchControl(): void {
    this.patientControl.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (typeof value === 'string' || value === null) {
          this.patientsPage = 0;
          if (value) {
            this.getPatients(value as string);
          }
          this.patientId = null;
        } else {
          this.patientId = value.patientId;
        }
      });
  }

  private subscribeScrollBottom(): void {
    this.reachedListBottom$
      .pipe(
        debounceTime(100),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
          this.getPatients(this.patientControl.value as string, true);
        }
      );
  }

  private registerPatient(patientId: string): void {
    this.dialog.open(RegistrationPatientDialogComponent, {
      data: { patientId }, disableClose: true
    });
  }
}
