import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { CountryISO, NgxIntlTelInputComponent, SearchCountryField } from 'ngx-intl-tel-input-gg';

import { PostInvitationUserFormGroup } from '../../../core/types';

import { AuthService, StaticDataService, ValidationService } from '../../../core/services';

import { CreatePatientInterface, PhoneNumberInterface } from '../../../core/interfaces';

@Component({
  selector: 'vi-clinic-register-new-patient',
  templateUrl: './register-new-patient.component.html',
  styleUrls: ['./register-new-patient.component.scss']
})
export class RegisterNewPatientComponent implements OnInit, OnDestroy {
  @Output() public sendRegistrationEmitter = new EventEmitter<CreatePatientInterface>();
  @Output() public closeDialog = new EventEmitter();

  public inviteFormGroup: FormGroup<PostInvitationUserFormGroup>;
  public phoneObject: PhoneNumberInterface;
  public preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  public searchCountryField = SearchCountryField;
  public separateDialCode = true;
  public countryISO = CountryISO;
  public selectCountry: string;

  public onlyCountries$: Observable<string[]> = new Observable<string[]>();
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  public get phoneNumber(): FormControl<string> {
    return this.inviteFormGroup.controls['phoneNumber'] as FormControl<string>;
  }

  constructor(private readonly fb: FormBuilder,
              private readonly validationService: ValidationService,
              private readonly authService: AuthService,
              private readonly staticDataService: StaticDataService,) {
  }

  public ngOnInit(): void {
    this.setupForms();
    this.getCountries();
    this.selectDefaultCountry();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public setupForms(): void {
    this.inviteFormGroup = this.fb.group<PostInvitationUserFormGroup>({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      phoneNumber: this.fb.control(null, [Validators.required]),
      email: this.fb.control('', [this.validationService.emailValidator, Validators.required])
    });
  }

  public sendInvitation(): void {
    const patient: CreatePatientInterface = {
      email: this.inviteFormGroup.controls.email.value,
      phoneNumber: this.phoneObject.number.replace(/[\s-]/g, ''),
      phonePrefix: this.phoneObject.dialCode,
      phoneCountryCode: this.phoneObject.countryCode,
      firstName: this.inviteFormGroup.controls.firstName.value,
      lastName: this.inviteFormGroup.controls.lastName.value,
    };

    this.sendRegistrationEmitter.emit(patient);
  }

  public countriesClicked(ev: PointerEvent | MouseEvent, input: NgxIntlTelInputComponent): void {
    filterTelInputCountries(ev, input);
  }

  private getCountries(): void {
    this.onlyCountries$ = this.staticDataService.getCountries()
      .pipe(map(countries => {
        return countries.map(c => c.code.toLowerCase());
      }));
  }

  private selectDefaultCountry(): void {
    this.authService.userInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((patientInfo) => this.selectCountry = patientInfo.address.countryCode.toUpperCase());
  }
}

export function filterTelInputCountries(ev: PointerEvent | MouseEvent, input: NgxIntlTelInputComponent): void {
  if (!(ev.srcElement instanceof HTMLInputElement)) {
    if (input.preferredCountries.length) {
      input.preferredCountries.forEach(countryCode => {
        const index = input.allCountries.findIndex(country => countryCode === country.iso2);
        if (index !== -1) {
          input.allCountries.splice(index, 1);
        }
      });
    }
  }
}
