import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DoctorStatusEnum } from '../enums';

import { AuthService } from '../services';


@Injectable({
  providedIn: 'root'
})
export class StatusGuardService {

  constructor(private readonly authService: AuthService,
              private readonly router: Router) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const acceptableStatuses: number[] = route.data.statuses;

    if (this.authService.isDoctor === false) {
      return true;
    }

    return this.authService.status$.pipe(
      map((status) => this.checkAccess(acceptableStatuses, status))
    );
  }

  private checkAccess(acceptableStatuses: number[], status: number): boolean {
    const isPageAvailable = acceptableStatuses.includes(status);

    if (!isPageAvailable) {
      if (status === DoctorStatusEnum.PendingApproval) {
        this.router.navigate(['/pending-approval']).then();
      } else if (status === DoctorStatusEnum.ChangeRequested || status === DoctorStatusEnum.Registered) {
        this.router.navigate(['/profile-settings']).then();
      } else if (status === DoctorStatusEnum.Rejected) {
        this.router.navigate(['/rejected']).then();
      } else {
        this.router.navigate(['/requests']).then();
      }
      return false;
    }

    return isPageAvailable;
  }
}

export const StatusGuard: CanActivateFn = (next: ActivatedRouteSnapshot): Observable<boolean> | boolean => inject(StatusGuardService).canActivate(next);
