import { RequestAppointmentInterface } from './request-appointment.interface';

export interface UnknownPatientsRequestsPaginationInterface {
  currentPage: number;
  pageSize: number;
  total: number;
  totalPages: number;
  count: number;
  items: RequestAppointmentInterface[];
  skip: number;
  take: number;
}

