import { PreviousVisitInfoInterface } from './previous-visit-info.interface';
import { PatientEhrGeneralInfoInterface } from './patient-ehr-general-info.interface';
import { QuestionAnswerInterface } from './question-answer.interface';

export interface MedicalHistoryInterface extends PatientEhrGeneralInfoInterface {
  age: number;
  questionAnswers: QuestionAnswerInterface[];
  previousVisitsInfo: PreviousVisitInfoInterface[];
  lastUpdateDate: Date;
  id: string;
}
