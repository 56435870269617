import { RequestVisitStepperEnum } from '../enums';

export const stepperListConstant = [
  {
    index: RequestVisitStepperEnum.service,
    label: 'One-time visits',
  },
  {
    index: RequestVisitStepperEnum.requestVisit,
    label: 'visits.requestVisitLabel',
  },
  {
    index: RequestVisitStepperEnum.scheduleVisit,
    label: 'visits.scheduleVisitSpecificTimeLabel',
  },
  {
    index: RequestVisitStepperEnum.choseFamilyMember,
    label: 'visits.choseFamilyMemberLabel',
  },
  {
    index: RequestVisitStepperEnum.choseLanguage,
    label: 'visits.choseLanguageLabel',
  },
  {
    index: RequestVisitStepperEnum.chooseSpecialty,
    label: 'visits.provideVisitReasonLabel',
  },
  {
    index: RequestVisitStepperEnum.medicalHistory,
    label: 'visits.completeMedicalHistoryLabel'
  },
  {
    index: RequestVisitStepperEnum.contactInfo,
    label: 'visits.contactInformationLabel'
  },
  {
    index: RequestVisitStepperEnum.summary,
    label: 'visits.summaryOfVisitRequestLabel'
  }
];
