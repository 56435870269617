import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DoctorApiService } from '../../../core/services';

import { ScheduleOptionInterface } from '../../../core/interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'vi-clinic-available-time-options',
  templateUrl: './available-time-options.component.html',
  styleUrls: ['./available-time-options.component.scss']
})
export class AvailableTimeOptionsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public timeOptions: ScheduleOptionInterface[];
  @Input() public isDoctor = true;

  @Output() public timeOptionEvent = new EventEmitter<string>();
  public timeOptionsFormGroup: FormGroup;
  public timeOptionsValidChecked: { [key: string]: boolean } = {};
  private subscriptions: Subscription = new Subscription();
  private reserveDates: string[];
  private unsubscribe$: Subject<void> = new Subject<void>();
  private currentDate: Date = new Date();

  constructor(private readonly doctorApiService: DoctorApiService,
              private readonly fb: FormBuilder) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.timeOptions) {
      this.setForm();
      this.subscribeToFormChanges();
      this.timeOptionsValidChecked = {};
      if (this.isDoctor) {
        this.fetchCurrentDoctorSchedule();
      }
    }
  }

  public ngOnInit(): void {
    if (this.isDoctor) {
      this.fetchCurrentDoctorSchedule();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setTimeOptionsValidChecked(): void {
    this.timeOptions.forEach((option) => {
      this.timeOptionsValidChecked[option.id] = this.reserveDates?.includes(option.value) || this.currentDate > new Date(option.value);
    });
  }

  private subscribeToFormChanges(): void {
    const subscription = this.timeOptionsFormGroup.valueChanges.subscribe((value) => {
      this.timeOptionEvent.emit(value.scheduleOptionId);
    });

    this.subscriptions.add(subscription);
  }

  private setForm(): void {
    this.subscriptions?.unsubscribe();
    this.subscriptions = new Subscription();

    this.timeOptionsFormGroup = this.fb.group({
      scheduleOptionId: this.fb.control<string>('')
    });
  }

  private fetchCurrentDoctorSchedule(): void {
    this.doctorApiService.getCurrentDoctorSchedule()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (dates) => {
          this.reserveDates = dates.result;
          this.setTimeOptionsValidChecked();
        }
      );
  }
}
