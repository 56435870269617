import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TooltipComponent } from '@angular/material/tooltip';

import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

import {
  AlertsService,
  AuthService,
  ConfigService,
  DoctorApiService,
  ProfileService,
  ScrollService,
  SideBarService
} from '../../services';

import { DoctorStatusEnum, RoleEnum } from '../../enums';
import { BadgesFormGroup } from '../../types';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'vi-clinic-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() public sidebarExpanded = true;

  @Output() public toggleSidebar: EventEmitter<void> = new EventEmitter();
  @Output() public hideSidebar: EventEmitter<void> = new EventEmitter();

  public status: DoctorStatusEnum;
  public doctorStatus = DoctorStatusEnum;
  public isProfileSettingsAvailable: boolean;
  public isLogoutProcessing: boolean = false;
  public openManageProfileSettingsItem: boolean = true;
  public currentProfileSettingsSection: string;
  public checkProfileSettingsUrl: boolean;
  public completedPersonalInformation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public badgesFormGroup: FormGroup<BadgesFormGroup>;
  public menuItems = [];
  public currentUserRole: RoleEnum;
  public userRole = RoleEnum;
  public completedPatientPersonalInformation = true;
  public hasMedicalInformation = true;
  public preVisitUrl: string;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly router: Router,
              private readonly scrollService: ScrollService,
              private readonly authService: AuthService,
              private readonly fb: FormBuilder,
              private readonly alertService: AlertsService,
              private readonly profileService: ProfileService,
              private readonly sideBarService: SideBarService,
              private readonly doctorApiService: DoctorApiService,
              private readonly configService: ConfigService) {
  }

  public ngOnInit(): void {
    this.checkUser();
    this.subscribeToPatientRequiredInfo();

    Object.defineProperty(TooltipComponent.prototype, 'message', {
      set(value: string) {
        const el = document.querySelectorAll('.mdc-tooltip');

        if (el.length) {
          el[el.length - 1].innerHTML = value;
        }
      },
    });

    this.scrollService.scrollSection$.pipe(
      takeUntil(this.unsubscribe$))
      .subscribe(
        (value) => {
          this.currentProfileSettingsSection = value;
        }
      );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (value: any) => {
          this.checkProfileSettingsUrl = value.url === '/profile-settings';
        }
      );

    this.setForms();
    this.updateBadge();
    this.completedPersonalInformation$ = this.profileService.completedPersonalInformation$;
    this.preVisitUrl = `${environment.emrAddress}/home/${this.authService.getRedirectUrl()}`
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  public doToggleSidebar(): void {
    this.toggleSidebar.emit();
  }

  public selectItemMenu(): void {
    this.hideSidebar.emit();
  }

  public toggleManageProfileSettings(): void {
    if (this.router.url === '/profile-settings') {
      this.openManageProfileSettingsItem = !this.openManageProfileSettingsItem;
    } else {
      this.openManageProfileSettingsItem = true;
    }
  }

  public scrollToElement(section: string): void {
    this.router.navigate(['profile-settings']).then();

    setTimeout(
      () => this.scrollService.scrollSection$.next(section),
      300);
  }

  public getBadges(): void {
    this.sideBarService.getBadges(this.currentUserRole)
      .pipe(
        map((el) => {
          return {
            ...el,
            totalVisits: el.totalAppointments + el.totalRequests
          };
        }),
        takeUntil(this.unsubscribe$))
      .subscribe(
        (badges) => {
          this.badgesFormGroup.setValue(badges);
        }
      );
  }

  public updateBadge(): void {
    this.alertService.newAlert$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.getBadges();
        }
      );

    this.alertService.markMessageAsRead$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.getBadges();
        }
      );
  }

  public setForms(): void {
    this.badgesFormGroup = this.fb.group<BadgesFormGroup>({
      totalRequests: this.fb.control(null),
      totalAppointments: this.fb.control(null),
      totalNotifications: this.fb.control(null),
      totalUnreadMessages: this.fb.control(null),
      totalVisits: this.fb.control(null),
      totalSubscriptionRequests: this.fb.control(null)
    });
  }

  public logout(): void {
    this.isLogoutProcessing = true;
    this.authService.logout();
  }

  private subscribeToPatientRequiredInfo() {
    this.sideBarService.patientRequiredInfo$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (value) => {
          this.completedPatientPersonalInformation = value.completedPersonalInformation;
          this.hasMedicalInformation = value.hasMedicalInformation;
        }
      );
  }

  private checkUser(): void {
    this.authService.currentUser$
      .pipe(
        filter((user) => !!user),
        switchMap((user) => {
          this.currentUserRole = user.role;
          this.getBadges();
          if (user.role === RoleEnum.Doctor) {
            this.getStatus();
          } else if (user.role === RoleEnum.Patient) {
            this.status = DoctorStatusEnum.Active;
          }

          return this.configService.getConfigMenu();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        if (this.currentUserRole === RoleEnum.Doctor) {
          this.menuItems = result.doctorMenu;
          this.checkDoctorActivation();
        } else if (this.currentUserRole === RoleEnum.Patient) {
          this.menuItems = result.patientMenu;
        }
      });
  }

  private getStatus(): void {
    this.authService.status$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        this.status = result;
        this.setProfileSettingsAvailability();
      });
  }

  private setProfileSettingsAvailability(): void {
    this.isProfileSettingsAvailable = (this.status === this.doctorStatus.Registered || this.status === this.doctorStatus.ChangeRequested
      || this.status === this.doctorStatus.Active) && this.currentUserRole === RoleEnum.Doctor;
  }

  private checkDoctorActivation(): void {
    this.doctorApiService.getDoctorActivationRequiredInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }
}
