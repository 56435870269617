import { UsageRouteEnum } from '../enums';

export const usageRouteListConstant = [
  {
    'code': UsageRouteEnum.Oral,
    'name': 'patientEHR.usageRoute.oralLabel',
  },
  {
    'code': UsageRouteEnum.IV,
    'name': 'patientEHR.usageRoute.IVLabel',
  },
  {
    'code': UsageRouteEnum.IM,
    'name': 'patientEHR.usageRoute.IMLabel',
  },
  {
    'code': UsageRouteEnum.SC,
    'name': 'patientEHR.usageRoute.SCLabel',
  },
  {
    'code': UsageRouteEnum.Rect,
    'name': 'patientEHR.usageRoute.rectLabel',
  },
  {
    'code': UsageRouteEnum.Vagn,
    'name': 'patientEHR.usageRoute.vagnLabel',
  },
  {
    'code': UsageRouteEnum.Eye,
    'name': 'patientEHR.usageRoute.eyeLabel',
  },
  {
    'code': UsageRouteEnum.Nasal,
    'name': 'patientEHR.usageRoute.nasalLabel',
  },
  {
    'code': UsageRouteEnum.Ear,
    'name': 'patientEHR.usageRoute.earLabel',

  },
  {
    'code': UsageRouteEnum.Top,
    'name': 'patientEHR.usageRoute.topLabel',

  },
  {
    'code': UsageRouteEnum.Other,
    'name': 'patientEHR.usageRoute.otherLabel',
  }
];
