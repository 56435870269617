import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalHistoryAvailabilityInterface, SelectedPatientInterface } from '../../../core/interfaces';
import { ServicesComponent } from '../../../+services/services.component';


@Component({
  templateUrl: './provide-ehr-access-dialog.component.html',
  styleUrls: ['./provide-ehr-access-dialog.component.scss']
})
export class ProvideEhrAccessDialogComponent {
  public hasEhrAccess = false;

  constructor(
    private readonly dialogRef: MatDialogRef<ServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      selectedPatient: SelectedPatientInterface,
      medicalHistoryAvailability: MedicalHistoryAvailabilityInterface
    }) {
  }

  public save(): void {
    this.dialogRef.close(true);
  }
}
