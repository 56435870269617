import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl } from '@angular/forms';


@Component({
  templateUrl: './write-message-dialog.component.html',
  styleUrls: ['./write-message-dialog.component.scss']
})
export class WriteMessageDialogComponent implements OnInit, OnDestroy {
  public messageTextFormControl: FormControl<string> = this.fb.control('');
  public isTextValue: boolean = false;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly dialogRef: MatDialogRef<WriteMessageDialogComponent>,
              private readonly fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) private readonly dialogData: { id: string }) {
  }

  public ngOnInit(): void {
    this.manageMessageTextChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public sendMessage(): void {
    const data = {
      otherUserId: this.dialogData.id,
      text: this.messageTextFormControl.value
    };

    const formData: FormData = new FormData();

    for (const key of Object.keys(data)) {
      const value = data[key];
      formData.append(key, value);
    }

    this.dialogRef.close(formData);
  }

  private manageMessageTextChanges(): void {
    this.messageTextFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => this.isTextValue = !!value.trim());
  }
}
