import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { RecommendationDialogsEnum } from '../enums';


@Injectable({
  providedIn: 'root',
})
export class SelectedRecommendationDialogService {
  private selectedRecommendationDialogSubject: Subject<RecommendationDialogsEnum> = new Subject();
  public selectedRecommendationDialog$: Observable<RecommendationDialogsEnum> = this.selectedRecommendationDialogSubject.asObservable();


  public onSelectRecommendationDialog(selectedRecommendationDialog: RecommendationDialogsEnum): void {
    this.selectedRecommendationDialogSubject.next(selectedRecommendationDialog);
  }
}
