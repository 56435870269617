<div class="modal-wrapper">
  <h1 class="page-title">{{ 'symptoms.chatTranscriptionLabel' | translate }}</h1>

  <div *ngIf="transcript.length || isLoading else emptyState" class="overflow-auto d-flex flex-col row-gap-1">
    <div *ngFor="let message of transcript" class="message-item">
      <div class="d-flex flex-col gap-1-6 m-l-4">
        <div class="label">{{ 'symptoms.youLabel' | translate }}</div>

        <p class="text" [innerHTML]="message.request"></p>
      </div>

      <div class="d-flex gap-1-6">
        <mat-icon [svgIcon]="'ai'"></mat-icon>

        <div class="d-flex flex-col gap-1-6">
          <div class="label">{{ 'symptoms.drViLabel' | translate }}</div>

          <p *ngIf="message.response?.text" class="text" [innerHTML]="message.response?.text"></p>

          <mat-chip-listbox *ngIf="message.response?.options?.length ||
         message.response?.suggestions?.length ||
          message.response?.multiOptions?.length">
            <ng-container *ngIf="message.response?.options?.length">
              <mat-chip-option *ngFor="let option of message.response?.options" [selectable]="false">{{ option }}
              </mat-chip-option>
            </ng-container>

            <ng-container *ngIf="message.response?.suggestions?.length">
              <mat-chip-option *ngFor="let suggestion of message.response?.suggestions"
                               [selectable]="false">{{ suggestion }}
              </mat-chip-option>
            </ng-container>

            <ng-container *ngIf="message.response?.multiOptions?.length">
              <mat-chip-option *ngFor="let multiOption of message.response?.multiOptions"
                               [selectable]="false">{{ multiOption }}
              </mat-chip-option>
            </ng-container>
          </mat-chip-listbox>
        </div>
      </div>
    </div>
  </div>

  <ng-template #emptyState>
    <vi-clinic-empty-state
      [subtitle]="'general.noRecordsFoundLabel' | translate">
    </vi-clinic-empty-state>
  </ng-template>

  <div class="button-container">
    <button mat-dialog-close class="transparent-button" mat-button>
      {{ 'general.closeLabel' | translate }}
    </button>
  </div>
</div>
