import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import * as uniq from 'lodash/uniq.js';
import { MediaDeviceInterface, RequestAppointmentInterface } from '../interfaces';

import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root'
})
export class VideoService {
  public roomKey: string;
  public appointmentInfoSubject$: BehaviorSubject<RequestAppointmentInterface> = new BehaviorSubject(null);
  public appointmentInfo$: Observable<RequestAppointmentInterface> = this.appointmentInfoSubject$.asObservable();
  public onJoinSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public onJoin$: Observable<boolean> = this.onJoinSubject.asObservable();
  public isPreview: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isPreview$: Observable<boolean> = this.isPreview.asObservable();
  public isUnMute: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isUnMute$: Observable<boolean> = this.isUnMute.asObservable();
  public participantName: BehaviorSubject<string> = new BehaviorSubject('');
  public participantName$: Observable<string> = this.participantName.asObservable();
  public participantLeftCallSubject: Subject<void> = new Subject<void>();
  public participantLeftCall$: Observable<void> = this.participantLeftCallSubject.asObservable();
  public participantJoinCallSubject: Subject<void> = new Subject<void>();
  public participantJoinCall$: Observable<void> = this.participantJoinCallSubject.asObservable();
  private videoConnector: any;
  private vidyoRuntime: any;
  private sdkTimeout: boolean;
  private cameraPermission: boolean = false;
  private microphonePermission: boolean = false;
  private cameras: MediaDeviceInterface[] = [];
  private microphones: MediaDeviceInterface[] = [];
  private speakers: MediaDeviceInterface[] = [];
  private displayName: string;
  private runtimeLoadedSubject: Subject<void> = new Subject<void>();
  public runtimeLoaded$: Observable<void> = this.runtimeLoadedSubject.asObservable();
  private devicesChanged: BehaviorSubject<any> =
    new BehaviorSubject({ speakers: [], cameras: [], microphones: [] });
  public devices$: Observable<any> = this.devicesChanged.asObservable();
  private callStartedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public callStarted$: Observable<boolean> = this.callStartedSubject.asObservable();
  private videoFailureSubject: Subject<boolean> = new Subject<boolean>();
  public videoFailure$: Observable<boolean> = this.videoFailureSubject.asObservable();

  constructor(private readonly notificationService: NotificationService) {
  }

  public setUsername(name: string): void {
    this.displayName = name;
  }

  public startCall(): void {
    if (!this.videoConnector) {
      this.initializePreviewAndStart();

      return;
    }
    this.joinRoom();
  }

  public initSdk(): void {
    if (typeof this.vidyoRuntime !== 'undefined') {
      this.runtimeLoadedSubject.next();

      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://vcmain.blob.core.windows.net/static/VidyoClient.min.js';
    script.onload = (event) => this.onScriptLoaded(event);
    document.body.appendChild(script);
  }

  private setDefaultDevices(): void {
    this.videoConnector.SelectDefaultSpeaker();
    this.setDefaultMicrophone();
    this.setDefaultCamera();
  }

  private setDefaultCamera(): void {
    this.videoConnector.SelectDefaultCamera();
  }

  private setDefaultMicrophone(): void {
    this.videoConnector.SelectDefaultMicrophone();
  }

  public toggleMic(result: boolean): void {
    if (!this.videoConnector) {
      return;
    }

    this.videoConnector.SetMicrophonePrivacy({ privacy: !result });
  }

  public toggleCameraPrivacy(result: boolean): void {
    if (!this.videoConnector) {
      return;
    }

    this.videoConnector.SetCameraPrivacy({ privacy: !result });
  }

  public drop(): void {
    this.cameras = [];
    this.microphones = [];
    this.speakers = [];
    this.displayName = '';
    this.roomKey = null;
    this.participantName.next('');
    this.callStartedSubject.next(false);
    this.onJoinSubject.next(false);

    if (!this.videoConnector) {

      return;
    }

    this.videoConnector.SetMicrophonePrivacy({ privacy: true });
    this.videoConnector.SetCameraPrivacy({ privacy: true });
    this.videoConnector.HideView({ viewId: 'videoPreview' });

    this.videoConnector.Disconnect()
      .then(() => {
        this.videoConnector.Disable();
        this.videoConnector = null;
      })
      .catch(() => {
        this.videoConnector.Disable();
        this.videoConnector = null;
      });
  }

  public changeCamera(): void {
    if (!this.videoConnector) {

      return;
    }
    this.videoConnector.CycleCamera();
  }

  public async checkVideoMicrophoneAccess() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      let audio = 0;
      let camera = 0;

      devices.forEach(device => {
        if (device.kind == 'audioinput' && device.label) {
          audio++;
        }
        if (device.kind == 'videoinput' && device.label) {
          camera++;
        }
      });

      let permission = {
        hasMicrophonePermissions: audio > 0,
        hasCameraPermissions: camera > 0
      };

      if (!permission.hasMicrophonePermissions && this.isUnMute.value) {
        this.isUnMute.next(false);
      }

      if (!permission.hasCameraPermissions && this.isPreview.value) {
        this.isPreview.next(false);
      }
      if (!this.cameraPermission && permission.hasCameraPermissions && this.videoConnector) {
        this.setDefaultCamera();
      }
      if (!this.microphonePermission && permission.hasMicrophonePermissions && this.videoConnector) {
        this.setDefaultMicrophone();
      }

      this.microphonePermission = permission.hasMicrophonePermissions;
      this.cameraPermission = permission.hasCameraPermissions;

      return permission;
    } catch (error) {
      throw error;
    }
  }

  private onScriptLoaded = (result: any): void => {
    switch (result.state) {
      case 'RETRYING':
        this.notificationService.showErrorNotification(null,'video.error.genericCallErrorLabel');
        break;
      case 'FAILED':
        if (this.sdkTimeout) {
          break;
        }
        this.notificationService.showErrorNotification(null,'video.error.genericCallErrorLabel');
        break;
      case 'FAILEDVERSION':
      case 'NOTAVAILABLE':
        this.notificationService.showErrorNotification(null,'video.error.genericCallErrorLabel');
        break;
      case 'TIMEDOUT':
        this.sdkTimeout = true;
        break;
      case 'READY':
      default: {
        this.vidyoRuntime = new (window as any).VidyoClientLib.VidyoClient('', () => {
        });
        this.runtimeLoadedSubject.next();
      }
    }
  };

  private initializePreviewAndStart(): void {
    if (!!this.videoConnector) {
      return;
    }

    this.vidyoRuntime.CreateVidyoConnector({
      viewId: null,
      viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
      remoteParticipants: 10,
      logFileFilter: 'debug@VidyoClient debug@VidyoSDP debug@VidyoResourceManager all@VidyoSignaling',
      logFileName: '',
      userData: ''
    })
      .then((vc: any) => this.afterInitialized(vc));
  }

  private afterInitialized(vc: any): void {
    this.videoConnector = vc;
    this.showRenderer();
    this.registerDeviceListeners();
    this.handleParticipantChangeListener();
    this.joinRoom();
  }

  private showRenderer(): void {
    const previewElement = document.getElementById('videoPreview');
    if (!previewElement) {
      return;
    }

    this.videoConnector.AssignViewToCompositeRenderer({
      viewId: 'videoPreview',
      viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default',
      remoteParticipants: 3
    });

    this.videoConnector.ShowViewAt({
      viewId: 'videoPreview',
      x: previewElement.offsetLeft,
      y: previewElement.offsetTop,
      width: previewElement.offsetWidth,
      height: previewElement.offsetHeight
    });

    this.videoConnector.ShowViewLabel({ showLabel: false, viewId: 'videoPreview' });
  }

  private sanitizeCameraName = (name: string): string => name.includes('(') ? name.split('(')[0].trim() : name;

  private notifyDeviceChange(): void {
    this.devicesChanged.next({
      cameras: uniq(this.cameras),
      microphones: uniq(this.microphones),
      speakers: uniq(this.speakers)
    });
  }

  private registerDeviceListeners(): void {
    this.videoConnector.RegisterLocalCameraEventListener({
      onAdded: (localCamera: MediaDeviceInterface) => {
        if (this.cameras.some(item => item.name === localCamera.name)) {
          return;
        }

        localCamera.name = this.sanitizeCameraName(localCamera.name);
        this.cameras.push(localCamera);
        this.notifyDeviceChange();
      },
      onRemoved: () => {
        this.notifyDeviceChange();
      },
      onSelected: () => {
        this.notifyDeviceChange();
      },
      onStateUpdated: () => {
      }
    });

    this.videoConnector.RegisterLocalMicrophoneEventListener({
      onAdded: (localMic: MediaDeviceInterface) => {
        if (this.microphones.some(item => item.name === localMic.name)) {
          return;
        }

        this.microphones.push(localMic);
        this.notifyDeviceChange();
      },
      onRemoved: (localMic: MediaDeviceInterface) => {
        this.microphones = this.microphones.filter(mic => mic.id === localMic.id);
        if (this.microphones.length <= 1) {
        }
        this.notifyDeviceChange();
      },
      onSelected: () => {
        this.notifyDeviceChange();
      },
      onStateUpdated: () => {
      }
    });

    this.videoConnector.RegisterLocalSpeakerEventListener({
      onAdded: (localSpeaker: MediaDeviceInterface) => {
        if (this.speakers.some(item => item.name === localSpeaker.name)) {
          return;
        }

        this.speakers.push(localSpeaker);
        this.notifyDeviceChange();
      },
      onRemoved: (localSpeaker: MediaDeviceInterface) => {
        this.speakers = this.speakers.filter(mic => mic.id === localSpeaker.id);
        this.notifyDeviceChange();
      },
      onSelected: () => {
        this.notifyDeviceChange();
      },
      onStateUpdated: () => {
      }
    });
  }

  private handleParticipantChangeListener(): void {
    this.videoConnector.RegisterParticipantEventListener({
      onJoined: (result) => {
        this.participantName.next(result.name);
        this.participantJoinCallSubject.next();
      },
      onLeft: () => {
        this.participantName.next('');
        this.participantLeftCallSubject.next();
      },
      onLoudestChanged: (participant: any, audioOnly: any) => participant.audioOnly = audioOnly,
      onDynamicChanged: () => {
      }
    });
  }

  private joinRoom(): void {
    this.videoConnector.SetMicrophonePrivacy({ privacy: true });
    this.videoConnector.SetCameraPrivacy({ privacy: true });
    this.videoConnector.ConnectToRoomAsGuest({
      host: environment.vidyoHost,
      roomKey: this.roomKey,
      // '8huaP05z6Z',
      displayName: this.displayName,
      roomPin: '',
      // enableDebug: '1',
      onSuccess: () => {
        this.setDefaultDevices();
        this.callStartedSubject.next(true);

        this.isPreview$
          .pipe(take(1))
          .subscribe(
            result => {
              this.videoConnector.SetCameraPrivacy({ privacy: !result });
            }
          );

        this.isUnMute$
          .pipe(take(1))
          .subscribe(result => {
            this.videoConnector.SetMicrophonePrivacy({ privacy: !result });
          });
      },
      onFailure: () => {
        this.callStartedSubject.next(false);
        this.videoFailureSubject.next(true);
      },
      onDisconnected: () => {
        this.callStartedSubject.next(false);
      }
    })
      .catch(() => {
        this.callStartedSubject.next(false);
      });
  }
}
