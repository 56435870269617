export * from './create-appointment-request.interface';
export * from './selected-patient.interface';
export * from './request-visit-grid.interface';
export * from './price-request.interface';
export * from './medical-history-availability.interface';
export * from './price-response.interface';
export * from './accept-follow-up-visit.interface';
export * from './accept-visit.interface';
export * from './patient-required-info.interface';
export * from './accept-visit-response.interface';
