export class SignalrEvent {
  public static readonly newAppointmentRequest = 'NewAppointmentRequest';
  public static readonly newAsapAppointmentRequest = 'NewAsapAppointmentRequest';
  public static readonly patientAcceptedFollowUp = 'PatientAcceptedFollowUp';
  public static readonly patientRejectedFollowUp = 'PatientRejectedFollowUp';
  public static readonly patientAcceptedScheduleProposal = 'PatientAcceptedScheduleProposal';
  public static readonly patientRejectedScheduleProposal = 'PatientRejectedScheduleProposal';
  public static readonly patientAppointmentConfirmed = 'PatientAppointmentConfirmed';
  public static readonly patientAppointmentRejected = 'PatientAppointmentRejected';
  public static readonly newChatMessage = 'NewChatMessage';
  public static readonly newChat = 'NewChat';
  public static readonly chatMessageRemoved = 'ChatMessageRemoved';
  public static readonly chatNameUpdated = 'ChatNameUpdated';
  public static readonly appointmentReminder = 'AppointmentReminder';
  public static readonly followUpReminder = 'FollowUpReminder';
  public static readonly patientAcceptedInvitation = 'PatientAcceptedInvitation';
  public static readonly paymentHoldExpired = 'PaymentHoldExpired';
  public static readonly paymentSucceeded = 'PaymentSucceeded';
  public static readonly paymentFailed = 'PaymentFailed';
  public static readonly adminApprovedDoctorRequest = 'AdminApprovedDoctorRequest';
  public static readonly adminRequestedChangeOnDoctorRequest = 'AdminRequestedChangeOnDoctorRequest';
  public static readonly adminRejectedDoctorRequest = 'AdminRejectedDoctorRequest';
  public static readonly patientAppointmentCanceled = 'PatientAppointmentCanceled';
  public static readonly patientAppointmentNoAnswer = 'PatientAppointmentNoAnswer';
  public static readonly errorPatientFundsCapture = 'ErrorPatientFundsCapture';
  public static readonly doctorNotifyAbsence = 'DoctorNotifyAbsence';
  public static readonly appointmentExpired = 'AppointmentExpired';
  public static readonly errorFundsCapture = 'ErrorFundsCapture';
  public static readonly newAppointmentScheduleProposal = 'NewAppointmentScheduleProposal';
  public static readonly newFollowUpRequest = 'NewFollowUpRequest';
  public static readonly followUpExpired = 'FollowUpExpired';
  public static readonly waitingRoomUpdated = 'WaitingRoomUpdated';
  public static readonly videoRoomCreatedOrUpdated = 'VideoRoomCreatedOrUpdated';
  public static readonly doctorAcceptedYourRequest = 'DoctorAcceptedYourRequest';
  public static readonly doctorRejectedYourRequest = 'DoctorRejectedYourRequest';
  public static readonly newAppointmentNote = 'NewAppointmentNote';
  public static readonly appointmentNoteUpdated = 'appointmentNoteUpdated';
  public static readonly newPatientRating = 'NewPatientRating';
  public static readonly patientRatingUpdated = 'PatientRatingUpdated';
  public static readonly newAppointmentRecommendation = 'NewAppointmentRecommendation';
  public static readonly appointmentRecommendationUpdated = 'AppointmentRecommendationUpdated';
  public static readonly doctorAcceptedYourRequestAsap = 'DoctorAcceptedYourRequestAsap';
  public static readonly newDoctorRequestCreated = 'NewDoctorRequestCreated';
  public static readonly doctorAcceptedInvitation = 'DoctorAcceptedInvitation';
  public static readonly appointmentFinished = 'AppointmentFinished';
  public static readonly userIsWaiting = 'UserIsWaiting';
  public static readonly patientNotifyAbsence = 'PatientNotifyAbsence';
  public static readonly newCustomOffer = 'NewCustomOffer';
  public static readonly newSubscriptionRequest = 'NewSubscriptionRequest';
  public static readonly providerApprovedSubscriptionRequest = 'ProviderApprovedSubscriptionRequest';
  public static readonly providerRejectedSubscriptionRequest = 'ProviderRejectedSubscriptionRequest';
  public static readonly subscriberCanceledSubscriptionRequest = 'SubscriberCanceledSubscriptionRequest';
  public static readonly subscriptionCreatedOrUpdated = 'SubscriptionCreatedOrUpdated';
}

