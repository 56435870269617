import { BankAccountTypeEnum } from '../../enums';
import { AddressInterface } from './address.interface';
import { LanguageInterface } from './language.interface';


export interface PersonalInfoInterface {
  bankAccountType?: BankAccountTypeEnum;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  biography?: string;
  email: string;
  phoneNumber?: string;
  phonePrefix?: string;
  photoUrl?: string;
  availabilityStatus?: number;
  countryCode?: string;
  countryName?: string;
  createdTimestamp?: string;
  defaultLanguage?: number;
  id?: string;
  languages?: LanguageInterface[];
  address: AddressInterface;
  mainSpecialty?: string;
  status?: number;
  updatedTimestamp?: string;
  state?: string;
  invitationCode?: string;
}
