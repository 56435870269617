import { Component, Input } from '@angular/core';
import { SymptomSummaryInterface } from '../../../core/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { TranscriptionDialogComponent } from '../../dialogs/transcription-dialog/transcription-dialog.component';


@Component({
  selector: 'vi-clinic-symptoms',
  templateUrl: './symptoms.component.html',
  styleUrls: ['./symptoms.component.scss']
})
export class SymptomsComponent {
  @Input() public symptoms: SymptomSummaryInterface[];

  constructor(private readonly matDialog: MatDialog) {
  }

  public openTranscription(id: string): void {
    this.matDialog.open(TranscriptionDialogComponent, { data: { symptomId: id } });
  }
}
