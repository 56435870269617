import { AppointmentStatusEnum } from '../../enums';

export interface AppointmentPaginationRequestDataInterface {
  patientId?: string;
  doctorId?: string;
  statuses?: AppointmentStatusEnum[];
  currentPage?: number;
  pageSize?: number;
  isHistory?: boolean;
  skip?: number;
  take?: number;
}
