import { Component, Input } from '@angular/core';

import { ScheduleOptionInterface } from '../../../core/interfaces';


@Component({
  selector: 'vi-clinic-suggested-time-options',
  templateUrl: './suggested-time-options.component.html',
  styleUrls: ['./suggested-time-options.component.scss']
})
export class SuggestedTimeOptionsComponent {
  @Input() public timeOptions: ScheduleOptionInterface[];
}
