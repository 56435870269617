import { Component } from '@angular/core';
import { AuthService } from '../../services';


@Component({
  templateUrl: './pending-approval.component.html',
  styleUrls: ['./pending-approval.component.scss']
})
export class PendingApprovalComponent {
  constructor(private readonly authService: AuthService) {
  }

  public logout(): void {
    this.authService.logout();
  }
}
