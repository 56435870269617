import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankingInfoInterface } from '../../interfaces';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DoctorBillingApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/DoctorBilling`;

  constructor(private readonly httpClient: HttpClient) { }

  public getBankingInfo(): Observable<BankingInfoInterface> {
    return this.httpClient.get<BankingInfoInterface>(this.apiUrl);
  }

  public updateBankingInfo(data: BankingInfoInterface): Observable<null> {
    return this.httpClient.post<null>(this.apiUrl, data);
  }
}
