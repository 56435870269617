import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Colleague,
  ColleaguesInterface,
} from '../../../core/interfaces';

import { MessagesComponent } from '../../../+messages/messages.component';

import {
  ChatApiService,
  NotificationService
} from '../../../core/services';



@Component({
  templateUrl: './add-colleague-dialog.component.html',
  styleUrls: ['./add-colleague-dialog.component.scss']
})
export class AddColleagueDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  public searchName: FormControl<string> = this.fb.control('');
  public canCreateChat: boolean;
  public colleagues: ColleaguesInterface[];
  public selectedDoctorsIds: string[] = [];
  public participantsIds: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { participantsIds: string[], canCreateChat: boolean },
              private readonly fb: FormBuilder,
              private readonly notificationService: NotificationService,
              private readonly chatApiService: ChatApiService,
              private readonly dialogRef: MatDialogRef<MessagesComponent>) {
  }

  public ngOnInit(): void {
    this.canCreateChat = this.dialogData.canCreateChat;
    this.participantsIds = this.dialogData.participantsIds
    this.getColleagues();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getColleagues(): void {
    this.chatApiService.getColleagues()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (colleagues) =>  {
          if(this.participantsIds?.length) {
            this.colleagues  = colleagues.filter((colleague) => !this.participantsIds.includes(colleague.doctorId));
          } else {
            this.colleagues = colleagues;
          }
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public selectDoctor(selectedColleagueId: string): void {
    if (this.selectedDoctorsIds.includes(selectedColleagueId)) {
      const selectedColleagueIndex = this.selectedDoctorsIds.findIndex((colleagueId) => colleagueId === selectedColleagueId);
      this.selectedDoctorsIds.splice(selectedColleagueIndex, 1);
    } else {
      this.selectedDoctorsIds.push(selectedColleagueId);
    }
  }

  public save(): void {
    let participants = [];

    const newParticipants = this.selectedDoctorsIds.map((id) => {
      return new Colleague(this.colleagues.find((colleague) => colleague.doctorId === id));
    });

   participants.push(...newParticipants);

    const data = {
      otherUserIds: this.selectedDoctorsIds,
      participants
    };

    this.dialogRef.close(data);
  }

  public resetSearch(): void {
    this.searchName.reset();
  }
}
