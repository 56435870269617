import { SpecialityInterface } from '../profile-settings';
import { UserGeneralInfoInterface } from '../general';
import { PaymentInterface } from './payment.interface';
import { ScheduleOptionInterface } from './schedule-option.interface';
import { AttachmentInterface } from './attachment.interface';
import { PriceResponseInterface } from '../visits';
import { AppointmentStatusEnum } from '../../enums';

export interface RequestAppointmentInterface {
  createdTimestamp: string;
  scheduledStart: string;
  followUpScheduledStart: string;
  seenByDoctor: boolean;
  status: AppointmentStatusEnum;
  appointmentType: number;
  paymentStatus: number;
  payment: PaymentInterface;
  price: PriceResponseInterface;
  additionalInfo: string;
  description: string;
  countryCode: string;
  countryName: string;
  countryIconUrl: string;
  phoneNumber: string;
  email: string;
  scheduleOptions: ScheduleOptionInterface[];
  followUpScheduleOptions: ScheduleOptionInterface[];
  languageCodes: string[];
  languageName: string;
  attachments: AttachmentInterface[];
  specialty: SpecialityInterface;
  doctorInfo: UserGeneralInfoInterface;
  patientInfo: UserGeneralInfoInterface;
  familyMemberInfo: UserGeneralInfoInterface;
  id: string;
  isFree: boolean;
  visitType: number;
  canCancel?: boolean;
  serviceName: string;
}
