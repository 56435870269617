import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  EditRecommendationDialogComponent
} from '../../dialogs/edit-recommendation/edit-recommendation-dialog.component';
import { EditPrescriptionDialogComponent } from '../../dialogs/edit-prescription/edit-prescription-dialog.component';

import { PreviousVisitInfoInterface } from '../../../core/interfaces';

import {
  AppointmentNoteApiService,
  AppointmentRecommendationApiService,
  NotificationService
} from '../../../core/services';
import { RoleEnum } from '../../../core/enums';


@Component({
  selector: 'vi-clinic-patient-visit',
  templateUrl: './patient-visit.component.html',
  styleUrls: ['./patient-visit.component.scss']
})
export class PatientVisitComponent {
  @Input() public visitInfo: PreviousVisitInfoInterface;
  @Input() public doctorId: string;
  @Input() public showNewRecommendationButton = true;
  @Input() public role: RoleEnum;

  public visitSectionStatus = false;
  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly dialog: MatDialog,
              private readonly notificationService: NotificationService,
              private readonly appointmentRecommendationApiService: AppointmentRecommendationApiService,
              private readonly appointmentNoteApiService: AppointmentNoteApiService,) {
  }

  public get allowingEditingDate(): boolean {
    const currentDate: Date = new Date();
    const visitDate: Date = new Date(this.visitInfo.visitDate);
    const allowEditingTime: number = 604800000;

    return +currentDate < (+visitDate + allowEditingTime);
  }


  public changeVisitSection(): void {
    this.visitSectionStatus = !this.visitSectionStatus;
  }

  public openEditRecommendationDialog(): void {

    const dialogRef = this.dialog.open(EditRecommendationDialogComponent, {
      data: {
        summary: this.visitInfo.summary,
        isEdit: !!this.visitInfo.summary
      }
    });

    dialogRef.afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.appointmentNoteApiService.editRecommendation(this.visitInfo.id, result)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                (response) => {
                  this.visitInfo.summary = response;
                  this.notificationService.showSuccessNotification();
                },
                (error) => this.notificationService.showErrorNotification(error?.error?.detail));
          }
        }
      );
  }

  public openAddNewPrescriptionDialog(appointmentId: string): void {
    const dialogRefs = this.dialog.open(EditPrescriptionDialogComponent, {
      data: {
        prescription: { appointmentId: appointmentId },
        isEdit: false
      }
    });

    dialogRefs.afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.appointmentRecommendationApiService.createPrescription(this.visitInfo.id, result)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                (response) => {
                  this.visitInfo.recommendations.push(response);
                  this.notificationService.showSuccessNotification();
                },
                (error) => this.notificationService.showErrorNotification(error?.error?.detail));
          }
        });
  }

  public deletedPrescription(prescriptionId: string): void {
    this.appointmentRecommendationApiService.deletePrescription(this.visitInfo.id, prescriptionId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          const index = this.visitInfo.recommendations.findIndex(element => element.id === prescriptionId);

          this.visitInfo.recommendations.splice(index, 1);
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public exportRecommendation(): void {
    this.appointmentRecommendationApiService.exportRecommendation(this.visitInfo?.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        const downloadURL = window.URL.createObjectURL(new Blob([result.body as BlobPart]));
        const link = document.createElement('a');
        const contentDispositionHeader = result.headers.get('Content-Disposition');
        const fileName = contentDispositionHeader.split(';')[1].split('filename')[1].split('=')[1].trim();
        link.href = downloadURL;
        link.download = fileName;
        link.click();
      }, (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }
}
