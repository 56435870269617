import { SubscriptionPriceInterface } from './subscription-price.interface';
import { ProviderServiceFeatureTypeEnum } from '../../enums';
import { PaymentPriceInterface } from './payment-price.interface';

export interface NewProviderServiceRequestInterface {
  name: string,
  description: string,
  serviceFeatureTypes: ProviderServiceFeatureTypeEnum,
  providerServiceSubscriptionPrices: SubscriptionPriceInterface[],
  providerServicePaymentPrices: PaymentPriceInterface[],
}
