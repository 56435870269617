import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultMessageInterface } from '../../interfaces/patients/result-message.interface';


@Injectable({
  providedIn: 'root'
})
export class AccountApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/Account/stripe`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getStripeStatus(): Observable<{ result: number }> {
    return this.httpClient.get<{ result: number }>(`${this.apiUrl}/status`);
  }

  public getLoginLinkUrl(): Observable<ResultMessageInterface> {
    return this.httpClient.get<ResultMessageInterface>(`${this.apiUrl}/login-link`);
  }

  public createConnectAccountByUrl(currentUrl: { redirectUrl: string }): Observable<ResultMessageInterface> {
    return this.httpClient.post<ResultMessageInterface>(`${this.apiUrl}/create-connect-account`, currentUrl);
  }
}
