import { Component, Input } from '@angular/core';
import { SubscriptionSelectedLocalPricesInterface } from '../../../core/interfaces';

@Component({
  selector: 'vi-clinic-subscription-selected-price-option',
  templateUrl: './subscription-selected-price-option.component.html',
  styleUrls: ['./subscription-selected-price-option.component.scss']
})
export class SubscriptionSelectedPriceOptionComponent {
  @Input() public subscriptionPrice: SubscriptionSelectedLocalPricesInterface;
}
