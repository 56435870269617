import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CountryInterface } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class StaticDataService {
  private readonly counties$: BehaviorSubject<CountryInterface[]> = new BehaviorSubject<CountryInterface[]>(null);
  private readonly states$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

  public getCountries(): Observable<CountryInterface[]> {
    return this.counties$.asObservable();
  }

  public setCountries(countries: CountryInterface[]): void {
    this.counties$.next(countries);
  }

  public getStates(): Observable<string[]> {
    return this.states$.asObservable();
  }

  public setStates(states: string[]): void {
    this.states$.next(states);
  }
}
