import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MedicalInformationComponent } from '../../../+medical-information/medical-information.component';

import { PatientEhrGeneralInfoInterface } from '../../../core/interfaces';


@Component({
  templateUrl: './complete-medical-history-dialog.component.html',
  styleUrls: ['./complete-medical-history-dialog.component.scss']
})
export class CompleteMedicalHistoryDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<MedicalInformationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { patientId: string, familyMemberId: string }) {
  }


  public addPatient($event: PatientEhrGeneralInfoInterface): void {
    this.dialogRef.close($event);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
