import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserGeneralInfoInterface } from '../../../core/interfaces';

import { ChatComponent } from '../../components/chat/chat.component';


@Component({
  templateUrl: './chat-participants-dialog.component.html',
  styleUrls: ['./chat-participants-dialog.component.scss']
})
export class ChatParticipantsDialogComponent implements OnInit {

  public participants: UserGeneralInfoInterface[];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: { participants: UserGeneralInfoInterface[] },
              private readonly dialogRef: MatDialogRef<ChatComponent>) {
  }

  public ngOnInit(): void {
    this.participants = this.dialogData.participants;
  }

  public addColleague(): void {
    this.dialogRef.close({ openAddColleagues: true });
  }
}
