import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { AuthService, VideoService } from '../services';


@Injectable({
  providedIn: 'root',
})
export class VideoGuardService {

  constructor(private readonly videoService: VideoService,
              private readonly authService: AuthService,
              private readonly router: Router) {
  }

  public canActivate(): Observable<boolean> {
    return this.videoService.appointmentInfo$
      .pipe(
        take(1),
        map(result => !!result),
        tap(result => {
          if (!result) {
            if (this.authService.isDoctor) {
              this.router.navigate(['/appointments']).then();
            } else {
              this.router.navigate(['/visits']).then();
            }
          }
        }));
  }
}

export const VideoGuard: CanActivateFn = (): Observable<boolean> | boolean => inject(VideoGuardService).canActivate();
