import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AlertsInterface, PaginationInterface } from '../../interfaces/';


@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/notifications`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getNotifications(userId: string, skip: number, take: number): Observable<PaginationInterface<AlertsInterface>> {

    return this.httpClient.get<PaginationInterface<AlertsInterface>>(`${this.apiUrl}?userId=${userId}&skip=${skip}&take=${take}`);
  }

  public updateNotifications(alertsId: string[]): Observable<void> {
    const alerts = {
      notificationIds: alertsId
    };

    return this.httpClient.post<void>(`${this.apiUrl}`, alerts);
  }

  public removeNotifications(alertId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${alertId}`);
  }
}
