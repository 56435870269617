import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export class IconRegistry {
  public static register(
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer): void {
    matIconRegistry.addSvgIcon(
      'star-icon-gray',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/rating/star-gray-icon.svg'));
    matIconRegistry.addSvgIcon(
      'star-icon-gold',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/rating/star-gold-icon.svg'));
    matIconRegistry.addSvgIcon(
      'request-patients',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/requests-patients-icon.svg'));
    matIconRegistry.addSvgIcon(
      'visits-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/visits-icon.svg'));
    matIconRegistry.addSvgIcon(
      'doctors-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/doctors-icon.svg'));
    matIconRegistry.addSvgIcon(
      'account-settings-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/account-settings-icon.svg'));
    matIconRegistry.addSvgIcon(
      'medical-information-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/medical-information-icon.svg'));
    matIconRegistry.addSvgIcon(
      'appointments-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/appointments-icon.svg'));
    matIconRegistry.addSvgIcon(
      'my-network',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/my-network-icon.svg'));
    matIconRegistry.addSvgIcon(
      'message-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/message-icon.svg'));
    matIconRegistry.addSvgIcon(
      'notifications',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/alerts-icon.svg'));
    matIconRegistry.addSvgIcon(
      'invitation-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/invitation-icon.svg'));
    matIconRegistry.addSvgIcon(
      'profile-settings',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/profile-settings-icon.svg'));
    matIconRegistry.addSvgIcon(
      'question-mark',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/question-mark-icon.svg'));
    matIconRegistry.addSvgIcon(
      'visit-history',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/visit-history-icon.svg'));
    matIconRegistry.addSvgIcon(
      'request-icon',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/request-icon.svg'));
    matIconRegistry.addSvgIcon(
      'message-circle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/message-circle-icon.svg'));
    matIconRegistry.addSvgIcon(
      'info',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/info-icon.svg'));
    matIconRegistry.addSvgIcon(
      'success',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/success-icon.svg'));
    matIconRegistry.addSvgIcon(
      'copy-icon-green',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/copy-icon-green.svg'));
    matIconRegistry.addSvgIcon(
      'log-out',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/log-out-icon.svg'));
    matIconRegistry.addSvgIcon(
      'arrow-right',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/arrow-right-icon.svg'));
    matIconRegistry.addSvgIcon(
      'arrow-left',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/arrow-left-icon.svg'));
    matIconRegistry.addSvgIcon(
      'plus',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/plus-icon.svg'));
    matIconRegistry.addSvgIcon(
      'active-heart',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/active-heart-icon.svg'));
    matIconRegistry.addSvgIcon(
      'inactive-heart',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/inactive-heart-icon.svg'));
    matIconRegistry.addSvgIcon(
      'calendar',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/calendar-icon.svg'));
    matIconRegistry.addSvgIcon(
      'delete',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/delete-icon.svg'));
    matIconRegistry.addSvgIcon(
      'rx',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/rx-icon.svg'));
    matIconRegistry.addSvgIcon(
      'prescription',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/prescription-icon.svg'));
    matIconRegistry.addSvgIcon(
      'concierge-service',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/concierge-service-icon.svg'));
    matIconRegistry.addSvgIcon(
      'video',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/video-icon.svg'));
    matIconRegistry.addSvgIcon(
      'ai',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/ai-icon.svg'));
    matIconRegistry.addSvgIcon(
      'camera-on',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/camera-on-icon.svg'));
    matIconRegistry.addSvgIcon(
      'camera-off',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/camera-off-icon.svg'));
    matIconRegistry.addSvgIcon(
      'microphone-on',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/microphone-on-icon.svg'));
    matIconRegistry.addSvgIcon(
      'microphone-off',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/microphone-off-icon.svg'));
    matIconRegistry.addSvgIcon(
      'switch-camera',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/switch-camera-icon.svg'));
    matIconRegistry.addSvgIcon(
      'chat',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/chat-icon.svg'));
    matIconRegistry.addSvgIcon(
      'tick',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/video/tick-icon.svg'));
    matIconRegistry.addSvgIcon(
      'close',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/close-icon.svg'));
    matIconRegistry.addSvgIcon(
      'check',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/check-icon.svg'));
    matIconRegistry.addSvgIcon(
      'search',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/search-icon.svg'));
    matIconRegistry.addSvgIcon(
      'menu',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-bar/menu-icon.svg'));
    matIconRegistry.addSvgIcon(
      'add-user',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/add-user-icon.svg'));
    matIconRegistry.addSvgIcon(
      'add-group',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/group-chat-icon.svg'));
    matIconRegistry.addSvgIcon(
      'filter',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/filter-icon.svg'));
    matIconRegistry.addSvgIcon(
      'support',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/support-icon.svg'));
    matIconRegistry.addSvgIcon(
      'send-message',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/send-message-icon.svg'));
    matIconRegistry.addSvgIcon(
      'attachment',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/attachment-icon.svg'));
    matIconRegistry.addSvgIcon(
      'arrow-forward',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/arrow-forward.svg'));
    matIconRegistry.addSvgIcon(
      'reset',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/reset-icon.svg'));
    matIconRegistry.addSvgIcon(
      'more-vert',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/more-vert-icon.svg'));
    matIconRegistry.addSvgIcon(
      'edit',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/edit-icon.svg'));
    matIconRegistry.addSvgIcon(
      'expand-less',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/arrow-bottom-icon.svg'));
    matIconRegistry.addSvgIcon(
      'expand-more',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/arrow-top-icon.svg'));
    matIconRegistry.addSvgIcon(
      'archive',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/archive-icon.svg'));
    matIconRegistry.addSvgIcon(
      'unarchive',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/unarchive-icon.svg'));
    matIconRegistry.addSvgIcon(
      'download',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/download-icon.svg'));
    matIconRegistry.addSvgIcon(
      'faq',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/faq-icon.svg'));
    matIconRegistry.addSvgIcon(
      'file',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/file-icon.svg'));
    matIconRegistry.addSvgIcon(
      'file-upload-failed',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/file-upload-failed-icon.svg'));
    matIconRegistry.addSvgIcon(
      'file-upload-uploaded',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/file-upload-uploaded-icon.svg'));
    matIconRegistry.addSvgIcon(
      'green-circle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/green-circle-icon.svg'));
    matIconRegistry.addSvgIcon(
      'location',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/location-icon.svg'));
    matIconRegistry.addSvgIcon(
      'ua-flag',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/general/ua-flag-icon.svg'));
    matIconRegistry.addSvgIcon(
      'avatar-placeholder',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/profile-settings/avatar-placeholder-icon.svg'));
    matIconRegistry.addSvgIcon(
      'upload',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/profile-settings/upload-icon.svg'));
    matIconRegistry.addSvgIcon(
      'black-arrow',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/black-arrow-icon.svg'));
    matIconRegistry.addSvgIcon(
      'error',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/error-icon.svg'));
    matIconRegistry.addSvgIcon(
      'warning',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/request-visit/warning-icon.svg'));
  }
}
