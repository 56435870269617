<div class="provide-ehr-access-wrapper">
  <div class="patient-info">
    <p class="label">{{ 'visits.patientLabel' | translate }}</p>
    <p class="name">
      {{ selectedUser?.firstName + ' ' + selectedUser?.lastName }}
      <span
        class="label">{{ selectedUser?.relationshipName ? (selectedUser.relationshipName | translate) : '' }}</span>
    </p>
  </div>

  <h2
    class="title">{{ 'visits.completeMedicalHistoryPriorProceedLabel' | translate }}</h2>

  <div *ngIf="!medicalHistoryAvailability?.isAvailable || medicalHistoryAvailability?.isOld"
       class="info-message">

    <mat-icon class="icon" [svgIcon]="!medicalHistoryAvailability?.isAvailable ? 'error': 'warning'"></mat-icon>
    <div>
      <p>{{ 'visits.payAttentionLabel' | translate }}</p>
      <p>{{
          !medicalHistoryAvailability?.isAvailable ?
            ('visits.errorMessageLabel' | translate) :
            ('visits.warningMessageLabel' | translate)
        }}</p>
    </div>
  </div>

  <div class="medical-history" (click)="openCompleteMedicalHistoryDialog()">
    <div>
      <p
        class="name">{{ selectedUser?.firstName + ' ' + selectedUser?.lastName }}</p>
      <p
        *ngIf="selectedUser?.medicalHistoryUpdatedTimestamp; else completeMedicalHistoryLabel"
        class="update-label">{{ 'visits.lastUpdateLabel' | translate }}: <span
        class="date">{{ selectedUser?.medicalHistoryUpdatedTimestamp | localDate:'longDateTime' }}</span>
      </p>
      <ng-template #completeMedicalHistoryLabel>
        <p class="name">{{ 'visits.completeMedicalHistoryLabel' | translate }}</p>
      </ng-template>
    </div>

    <mat-icon class="icon" [svgIcon]="'black-arrow'"></mat-icon>
  </div>

  <div class="d-flex align-items-center green-checkbox">
    <mat-checkbox [formControl]="shareMyEHRFormControl"><span
      class="checkbox-label">{{ 'visits.iAgreeShareEHRLabel' | translate }} <a
      [href]="confirmationDocumentLink" target="_blank">{{ 'general.doctorLabel' | translate }}</a></span>
    </mat-checkbox>
  </div>
</div>
