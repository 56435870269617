import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MedicalHistoryApiService } from '../../../core/services';

import { PatientEhrGeneralInfoInterface } from '../../../core/interfaces';

import { GenderEnum } from '../../../core/enums';

import { PatientEhrGeneralInfoFormGroup } from '../../../core/types';


@Component({
  selector: 'vi-clinic-offline-ehr-general-information',
  templateUrl: './offline-ehr-general-information.component.html',
  styleUrls: ['./offline-ehr-general-information.component.scss']
})
export class OfflineEhrGeneralInformationComponent implements OnInit, OnDestroy {
  @Input() public patientId: string;
  @Input() public familyMemberId: string;

  @Output() public noClick = new EventEmitter<void>();
  @Output() public patient = new EventEmitter<PatientEhrGeneralInfoInterface>();

  public generalInfo: FormGroup<PatientEhrGeneralInfoFormGroup>;
  public maxBirthDate: Date = new Date();
  public minBirthDate: Date = new Date();
  public gender = GenderEnum;
  public heightUnits = [
    {
      value: 0,
      displayedName: 'patientEHR.strength.cmLabel'
    },
    {
      value: 1,
      displayedName: 'patientEHR.strength.footLabel'
    },
  ];
  public weightUnits = [
    {
      value: 0,
      displayedName: 'patientEHR.strength.kgLabel'
    },
    {
      value: 1,
      displayedName: 'patientEHR.strength.poundLabel'
    },
  ];
  public generalInformation: PatientEhrGeneralInfoInterface;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly medicalHistoryApiService: MedicalHistoryApiService,
              private readonly fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.setMinSinceDate();
    this.setForm();
    this.getInfo();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onNoClick(): void {
    this.noClick.emit();
  }

  public addPatient(): void {
    this.patient.emit(this.generalInfo.value as PatientEhrGeneralInfoInterface);
  }

  private getInfo(): void {
    if (!!this.patientId) {
      this.medicalHistoryApiService.getPersonalInfo(this.patientId, this.familyMemberId).pipe(takeUntil(this.unsubscribe$))
        .subscribe(el => {
          this.generalInformation = el;
          this.setForm();
        });
    }
  }

  private setMinSinceDate(): void {
    this.minBirthDate.setFullYear(1900);
  }

  private setForm(): void {
    this.generalInfo = this.fb.group<PatientEhrGeneralInfoFormGroup>({
      gender: this.fb.control(this.generalInformation?.gender || this.gender.Male),
      weightUnit: this.fb.control(this.generalInformation?.weightUnit || this.weightUnits[1].value, Validators.required),
      weight: this.fb.control(this.generalInformation?.weight || null, Validators.required),
      dateOfBirth: this.fb.control(this.generalInformation?.dateOfBirth || '', Validators.required),
      heightUnit: this.fb.control(this.generalInformation?.heightUnit || this.heightUnits[1].value, Validators.required),
      height: this.fb.control(this.generalInformation?.height || null, Validators.required)
    });
  }
}
