import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { languagesListConstant } from '../constants';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private readonly translateService: TranslateService,
              @Inject(DOCUMENT) private readonly document: Document) {
  }

  public get currentLanguage(): string {
    const currentLanguage = localStorage.getItem('currentLanguageLocale');

    if (!currentLanguage) {
      this.currentLanguage = 'en';
    }

    return currentLanguage;
  }

  public set currentLanguage(lang: string) {
    const language = languagesListConstant.some(el => el.languageCode === lang) ? lang : 'en'
    localStorage.setItem('currentLanguageLocale', language);
    this.document.documentElement.lang = language;
    this.translateService.use(language);
  }
}
