export const alertsTypeConstant = {
  newAppointmentNote: 0,
  appointmentNoteUpdated: 1,
  newPatientRating: 2,
  patientRatingUpdated: 3,
  newAppointmentRecommendation: 4,
  appointmentRecommendationUpdated: 5,
  newAppointmentRequest: 6,
  newAsapAppointmentRequest: 7,
  doctorAcceptedYourRequestAsap: 8,
  doctorRejectedYourRequest: 9,
  patientAcceptedFollowUp: 10,
  patientRejectedFollowUp: 11,
  patientAcceptedScheduleProposal: 12,
  patientRejectedScheduleProposal: 13,
  patientAppointmentConfirmed: 14,
  patientAppointmentRejected: 15,
  newAppointmentScheduleProposal: 16,
  newFollowUpRequest: 17,
  newChatMessage: 18,
  newDoctorRequestCreated: 19,
  appointmentReminderForDoctor: 20,
  followUpReminder: 21,
  doctorAcceptedInvitation: 22,
  patientAcceptedInvitation: 23,
  paymentHoldExpired: 24,
  paymentSucceeded: 25,
  paymentFailed: 26,
  waitingRoomUpdated: 27,
  videoRoomCreatedOrUpdated: 28,
  adminApprovedDoctorRequest: 29,
  adminRequestedChangeOnDoctorRequest: 30,
  adminRejectedDoctorRequest: 31,
  patientAppointmentCanceled: 32,
  appointmentExpired: 33,
  patientAppointmentNoAnswer: 34,
  errorFundsCapture: 35,
  errorPatientFundsCapture: 36,
  doctorNotifyAbsence: 40,
  followUpExpired: 41,
  newChat: 42,
  chatMessageRemoved: 43,
  appointmentFinished: 44,
  followUpFinished: 45,
  chatNameUpdated: 46,
  userIsWaiting: 47,
  patientNotifyAbsence: 48,
  newCustomOffer: 49,
  newSubscriptionRequest: 50,
  providerApprovedSubscriptionRequest: 51,
  providerRejectedSubscriptionRequest: 52,
  subscriberCanceledSubscriptionRequest: 53,
  subscriptionCreatedOrUpdated: 54
};
