import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FavoriteDoctorInterface } from '../../interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FavoriteDoctorApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/FavoriteDoctor`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getFavoriteDoctors(): Observable<FavoriteDoctorInterface[]> {
    return this.httpClient.get<FavoriteDoctorInterface[]>(this.apiUrl);
  }

  public markDoctorAsFavorite(doctorId: string, patientId: string): Observable<void> {
    return this.httpClient.put<void>(this.apiUrl, {
      doctorId,
      patientId
    });
  }

  public removeDoctorAsFavorite(doctorId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${doctorId}`);
  }
}
