import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppointmentRatingInterface } from '../../interfaces';


@Injectable({ providedIn: 'root' })
export class AppointmentsRatingApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/AppointmentRating`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public setAppointmentRating(appointmentId: string, value: number, description: string): Observable<AppointmentRatingInterface> {
    return this.httpClient.put<AppointmentRatingInterface>(`${this.apiUrl}/${appointmentId}`, {
      value,
      description,
    });
  }
}
