import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import {
  ChatHistoryInterface,
  ChatInterface,
  ColleaguesInterface,
  CreateGroupChatInterface,
  MarkMessagesAsDeliveredInterface,
  MessageInterface,
  UserGeneralInfoInterface
} from '../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class ChatApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/chat`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getChats(isDoctors = false, skipSpinner = false): Observable<ChatInterface[]> {
    return this.httpClient.get<ChatInterface[]>(`${this.apiUrl}?IsDoctors=${isDoctors}&skipSpinner=${skipSpinner}`);
  }

  public getDrAiChat(): Observable<ChatHistoryInterface> {
    return this.httpClient.get<ChatHistoryInterface>(`${this.apiUrl}/doctorAI?take=1`);
  }

  public getChatHistory(chatId: string, lastMessageId?: string): Observable<ChatHistoryInterface> {
    const lastMessageIdQuery = lastMessageId ? `&lastMessageId=${lastMessageId}` : '';

    return this.httpClient.get<ChatHistoryInterface>(`${this.apiUrl}/${chatId}?take=20${lastMessageIdQuery}`);
  }

  public sendMessage(data: FormData, skipSpinner = false): Observable<MessageInterface> {
    return this.httpClient.post<MessageInterface>(`${this.apiUrl}?skipSpinner=${skipSpinner}`, data);
  }

  public markMessageAsDelivered(data: MarkMessagesAsDeliveredInterface, chatId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/delivered/${chatId}`, data);
  }

  public getChatByPartnerId(partnerId: string): Observable<ChatHistoryInterface> {
    return this.httpClient.get<ChatHistoryInterface>(`${this.apiUrl}/byPartner/${partnerId}?take=1`);
  }

  public getUnreadMessageCount(partnerId: string): Observable<{ result: number }> {
    return this.httpClient.get<{ result: number }>(`${this.apiUrl}/unread-by-partner/${partnerId}`);
  }

  public deleteMessage(chatId: string, messageId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${chatId}/${messageId}`);
  }

  public deleteChat(chatId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${chatId}`);
  }

  public cleanChat(chatId: string): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/${chatId}/clean`, {});
  }

  public createGroupChat(data: CreateGroupChatInterface): Observable<ChatInterface> {
    return this.httpClient.post<ChatInterface>(`${this.apiUrl}/group`, data);
  }

  public addParticipantsToChat(chatId: string, data: CreateGroupChatInterface): Observable<ChatInterface> {
    return this.httpClient.put<ChatInterface>(`${this.apiUrl}/${chatId}/add-members`, data);
  }

  public renameChat(chatId: string, data: { chatName: string }): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${chatId}/name`, data);
  }

  public getChatParticipants(chatId: string): Observable<UserGeneralInfoInterface[]> {
    return this.httpClient.get<UserGeneralInfoInterface[]>(`${this.apiUrl}/${chatId}/participants`);
  }

  public getColleagues(): Observable<ColleaguesInterface[]> {
    return this.httpClient.get<ColleaguesInterface[]>(`${this.apiUrl}/doctor-invite-list`);
  }
}
