import { RecommendationInterface } from './recommendation.interface';
import { PrescriptionInterface } from './prescription.interface';

export interface PreviousVisitInfoInterface {
  visitDate: Date;
  visitType: number;
  specialty: {
    id: string;
    name: string;
  };
  doctorInfo: {
    id: string;
    firstName: string;
    lastName: string;
    photoUrl: string;
  };
  summary: RecommendationInterface;
  recommendations: PrescriptionInterface[];
  id: string;
}


