import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ChatComponent } from '../../components/chat/chat.component';
import { FormBuilder, FormControl } from '@angular/forms';


@Component({
  templateUrl: './rename-chat-dialog.component.html',
  styleUrls: ['./rename-chat-dialog.component.scss']
})
export class RenameChatDialogComponent implements OnInit {

  public newChatName: FormControl<string> = this.fb.control('');

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: { chatName: string },
              private readonly fb: FormBuilder,
              private readonly dialogRef: MatDialogRef<ChatComponent>) {
  }

  public ngOnInit(): void {
    this.newChatName.setValue(this.dialogData.chatName);
  }

  public renameChat(): void {
    this.dialogRef.close(this.newChatName.value);
  }
}
