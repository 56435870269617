<!--    full screen spinner-->
<!--    <vi-clinic-spinner></vi-clinic-spinner>-->

<mat-sidenav-container class="mat-sidebar-container">
  <mat-sidenav [mode]="sidebarMode" [(opened)]="sidebarExpanded" class="vc-sidebar">
    <vi-clinic-sidebar (toggleSidebar)="toggleSidebar()" (hideSidebar)="hideSidebar()"
                       [sidebarExpanded]="sidebarExpanded"></vi-clinic-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="vc-sidebar-content">
    <div class="general-container">
      <vi-clinic-spinner class="progress-bar" [isProgressBar]="true"></vi-clinic-spinner>

      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<vi-clinic-mobile-header *ngIf="!!user"></vi-clinic-mobile-header>
