import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  public transform(value: string, args?: string): string {
    const phoneCode = value.length === 12 ? value.substring(0, 1) : value.substring(0, 2);
    const mask = args ? args : phoneCode + (value.length === 12 ? '##*****####' : '##*****####');

    return mask?.replace(/#/gi, function (_, o): string {
      return value[o];
    });
  }
}
