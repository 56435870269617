<div class="row-center">
  <div [ngClass]="appointmentTypeClass" class="appointment-type">
    {{appointmentTypeLabel | translate}}
  </div>

  <div *ngIf="showAppointmentStatus" class="appointment-status">
    <div [ngClass]="appointmentStatusClass" class="status-label">

      <mat-icon *ngIf="appointmentStatusClass !== 'success' else successIcon" [svgIcon]="'info'"
                class="icon"></mat-icon>

      <ng-template #successIcon>
        <mat-icon [svgIcon]="'success'"
                  class="icon"></mat-icon>
      </ng-template>

      {{ appointmentStatusLabel | translate: {minutesToCall: minutesToCall} }}
    </div>
  </div>
</div>
