import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { RecommendationInterface } from '../../interfaces';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppointmentNoteApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/AppointmentNote`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getRecommendation(appointmentId: string): Observable<RecommendationInterface> {
    return this.httpClient.get<RecommendationInterface>(`${this.apiUrl}/${appointmentId}`);
  }

  public getDiagnose(appointmentId: string): Observable<RecommendationInterface> {
    return this.httpClient.get<RecommendationInterface>(`${this.apiUrl}/diagnose/${appointmentId}`);
  }

  public editRecommendation(appointmentId: string, recommendation: RecommendationInterface): Observable<RecommendationInterface> {
    return this.httpClient.put<RecommendationInterface>(`${this.apiUrl}/${appointmentId}`, recommendation);
  }
}
