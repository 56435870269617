import { SubscriptionPriceInterface } from './subscription-price.interface';
import { SubscriptionSubscriberInterface } from './subscription-subscriber.interface';
import { SubscriptionProviderInterface } from './subscription-provider.interface';
import { ProviderServiceInterface } from './provider-service.interface';
import { AddressInterface, SpecialityInterface } from '../profile-settings';

export interface SubscriptionInterface {
  nextInvoice: string,
  subscriberAddress: AddressInterface
  id: string,
  status: number,
  providerServiceSubscriptionPrice: SubscriptionPriceInterface,
  startDate: string,
  endDate: string,
  subscriber?: SubscriptionSubscriberInterface,
  provider?: SubscriptionProviderInterface,
  specialty: SpecialityInterface,
  subscriptionPrice: SubscriptionPriceInterface,
  subscriptionService: ProviderServiceInterface
}
