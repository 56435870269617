export interface NewPatientInfoInterface {
  firstName: string,
  lastName: string,
  language: number,
  timeMode: number,
  timeZoneId: string,
  email: string,
  phoneNumber: string,
  id: string
}
