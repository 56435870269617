import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  ProposeTimeOptionsFormInterface
} from '../../../core/interfaces';
import { AuthService, ValidationService } from '../../../core/services';
import * as moment from 'moment';
import { TimeModeEnum } from '../../../core/enums';
import { DateTimeFormGroup, ProposeTimeOptionsFormGroup } from '../../../core/types';


@Component({
  selector: 'vi-clinic-propose-time-options',
  templateUrl: './propose-time-options.component.html',
  styleUrls: ['./propose-time-options.component.scss']
})
export class ProposeTimeOptionsComponent implements OnInit, OnDestroy {
  @Output() public proposeTimeOptionsEvent = new EventEmitter<Date[]>();
  public proposeOptionsForm: FormGroup<ProposeTimeOptionsFormGroup>;
  public newDate: Date = new Date();
  public lastDate: Date = new Date();
  public timeFormat: number;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly validationService: ValidationService,
              private readonly authService: AuthService,
              private readonly fb: FormBuilder) {
  }

  public get firstTimeOption(): FormGroup<DateTimeFormGroup> {
    return this.proposeOptionsForm.controls.firstTimeOption as FormGroup<DateTimeFormGroup>;
  }

  public get secondTimeOption(): FormGroup<DateTimeFormGroup> {
    return this.proposeOptionsForm?.controls.secondTimeOption as FormGroup<DateTimeFormGroup>;
  }

  public get thirdTimeOption(): FormGroup<DateTimeFormGroup> {
    return this.proposeOptionsForm.controls.thirdTimeOption as FormGroup<DateTimeFormGroup>;
  }

  public ngOnInit(): void {
    this.setProposeOptionsForm();
    this.subscribeToFormChanges();
    this.lastDate.setDate(this.newDate.getDate() + 6);
    this.timeFormat = this.authService.timeMode === TimeModeEnum.TwelveHours ? 12 : 24;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public resetTimeOptionGroup(formGroup: FormGroup<DateTimeFormGroup>): void {
    formGroup.controls.date.reset();
    formGroup.controls.time.setValue('00:00');
  }


  public clearForm(): void {
    this.resetTimeOptionGroup(this.firstTimeOption);
    this.resetTimeOptionGroup(this.secondTimeOption);
    this.resetTimeOptionGroup(this.thirdTimeOption);
  }

  private setProposeOptionsForm(): void {
    this.proposeOptionsForm = this.fb.group<ProposeTimeOptionsFormGroup>({
      firstTimeOption: this.fb.group<DateTimeFormGroup>({
        date: this.fb.control<string>(null, Validators.required),
        time: this.fb.control<string>('00:00', Validators.required)
      }),
      secondTimeOption: this.fb.group<DateTimeFormGroup>({
        date: this.fb.control<string>(null),
        time: this.fb.control<string>('00:00')
      }),
      thirdTimeOption: this.fb.group<DateTimeFormGroup>({
        date: this.fb.control<string>(null),
        time: this.fb.control<string>('00:00')
      })
    });

    this.proposeOptionsForm.addValidators(this.validationService?.checkTimeDifference);
    this.firstTimeOption.setValidators([this.validationService?.proposeTimeValidator, Validators.required])
    this.secondTimeOption.setValidators([this.validationService?.proposeTimeValidator])
    this.thirdTimeOption.setValidators([this.validationService?.proposeTimeValidator])
  }

  private subscribeToFormChanges(): void {
    this.proposeOptionsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (timeOptions) => {
          if (this.proposeOptionsForm.valid) {

            const optionsData = this.transformTimeOptions(timeOptions as ProposeTimeOptionsFormInterface);
            this.proposeTimeOptionsEvent.emit(optionsData);
          } else {
            this.proposeTimeOptionsEvent.emit(null);
          }
        }
      );
  }

  private transformTimeOptions(options: ProposeTimeOptionsFormInterface): Date[] {
    const optionsData: Date[] = [];
    Object.values(options).forEach((option) => {
      if (option.date && option.time) {
        const date = new Date(option.date);
        const parsedTime = moment(option.time, ['h:mm A', 'HH:mm'], true).format('HH:mm');
        const separateTime = parsedTime.split(':');
        date.setHours(+separateTime[0]);
        date.setMinutes(+separateTime[1]);

        optionsData.push(date);
      }
    });

    return optionsData;
  }
}
