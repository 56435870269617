import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import {
  AcceptRequestDataInterface,
  AcceptVisitInterface,
  AcceptVisitResponseInterface,
  CreateAppointmentRequest,
  CustomAppointmentOfferInterface,
  OfflineAppointmentInterface,
  PriceRequestInterface,
  PriceResponseInterface,
  RequestAppointmentInterface
} from '../../interfaces';


@Injectable({
  providedIn: 'root'
})
export class AppointmentApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/Appointment`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getAppointmentById(id: string): Observable<RequestAppointmentInterface> {
    return this.httpClient.get<RequestAppointmentInterface>(`${this.apiUrl}/${id}`);
  }

  public finalizeAppointment(id: string): Observable<RequestAppointmentInterface> {
    return this.httpClient.patch<RequestAppointmentInterface>(`${this.apiUrl}/finalize/${id}`, {});
  }

  public createOfflineAppointment(model: OfflineAppointmentInterface): Observable<RequestAppointmentInterface> {
    return this.httpClient.post<RequestAppointmentInterface>(`${this.apiUrl}/offline`, model);
  }

  public createCustomAppointmentOffer(data: CustomAppointmentOfferInterface): Observable<RequestAppointmentInterface> {
    return this.httpClient.post<RequestAppointmentInterface>(`${this.apiUrl}/custom-offer`, data);
  }

  public getRequestById(id: string): Observable<RequestAppointmentInterface> {
    return this.httpClient.get<RequestAppointmentInterface>(`${this.apiUrl}/${id}`);
  }

  public rejectKnownPatientRequest(id: string): Observable<null> {
    return this.httpClient.patch<null>(`${this.apiUrl}/doctorreject/${id}`, {});
  }

  public rejectUnknownPatientRequest(id: string): Observable<null> {
    return this.httpClient.put<null>(`${this.apiUrl}/doctorignore/${id}`, {});
  }

  public acceptTimeOptions(id: string, data: AcceptRequestDataInterface): Observable<RequestAppointmentInterface> {
    return this.httpClient.patch<RequestAppointmentInterface>(`${this.apiUrl}/doctoraccept/${id}`, data);
  }

  public sendProposedTimeOptions(id: string, data: { options: Date[] }): Observable<null> {
    return this.httpClient.patch<null>(`${this.apiUrl}/proposeschedule/${id}`, data);
  }

  public getPrice(body: PriceRequestInterface): Observable<PriceResponseInterface> {
    return this.httpClient.post<PriceResponseInterface>(`${this.apiUrl}/getPrice`, body);
  }

  public createAppointment(body: CreateAppointmentRequest): Observable<RequestAppointmentInterface> {
    return this.httpClient.post<RequestAppointmentInterface>(this.apiUrl, body);
  }

  public cancelAppointment(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/cancel/${id}`, {});
  }

  public rejectAppointment(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/patientreject/${id}`, {});
  }

  public acceptAppointment(id: string, body: AcceptVisitInterface): Observable<AcceptVisitResponseInterface> {
    return this.httpClient.patch<AcceptVisitResponseInterface>(`${this.apiUrl}/patientaccept/${id}`, body);
  }

  public checkIfAppointmentRequestRelevant(specialtyId: string): Observable<{ result: boolean }> {
    return this.httpClient.get<{ result: boolean }>(`${this.apiUrl}/request/relevance`, { params: { specialtyId } });
  }
}
