export * from './language.interface';
export * from './address.interface';
export * from './country.interface';
export * from './banking-info.interface';
export * from './billing-address.interface';
export * from './doctor-profile.interface';
export * from './experiences-form.interface';
export * from './education-experience.interface';
export * from './work-experience.interface';
export * from './license.interface';
export * from './license-file.interface';
export * from './speciality.interface';
export * from './specialty-update.interface';
export * from './personal-info-form.interface';
export * from './personal-info.interface';
export * from './phone-number.interface';
export * from './biography.interface';
export * from './license-suspension-history.interface';
export * from './board-certifications.interface';
export * from './time-zone.interface';
export * from './new-patient-info.interface';
export * from './create-patient.interface';
export * from './doctor-configuration.interface';
export * from './patient-configuration.interface';
