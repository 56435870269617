import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActiveProviderServiceInterface,
  PaginationInterface,
  SubscriberProviderInterface,
  SubscriptionInterface
} from '../../interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/Subscription`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getPatientActiveProviderServices(doctorId: string): Observable<ActiveProviderServiceInterface[]> {
    return this.httpClient.get<ActiveProviderServiceInterface[]>(`${this.apiUrl}/providers/${doctorId}/active-subscription-services`);
  }

  public getSubscription(id: string): Observable<SubscriptionInterface> {
    return this.httpClient.get<SubscriptionInterface>(`${this.apiUrl}/${id}`);
  }

  public getSubscribers(currentPage: number, pageSize: number): Observable<PaginationInterface<SubscriberProviderInterface>> {
    return this.httpClient.get<PaginationInterface<SubscriberProviderInterface>>(`${this.apiUrl}/subscribers?CurrentPage=${currentPage}&pageSize=${pageSize}`);
  }

  public getProviders(currentPage: number, pageSize: number): Observable<PaginationInterface<SubscriberProviderInterface>> {
    return this.httpClient.get<PaginationInterface<SubscriberProviderInterface>>(`${this.apiUrl}/providers?CurrentPage=${currentPage}&pageSize=${pageSize}`);
  }

  public cancelSubscription(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${id}/cancel`, null);
  }

  public manageSubscription(id: string, returnUrl: string): Observable<{ link: string }> {
    return this.httpClient.get<{ link: string }>(`${this.apiUrl}/${id}/portal?returnUrl=${returnUrl}`);
  }
}
