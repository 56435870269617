import { AddressInterface } from '../profile-settings';

export interface PatientGeneralInfoInterface {
  medicalHistoryUpdatedTimestamp: string;
  createdTimestamp: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  language: number;
  phoneNumber: string;
  userPhoneNumber: {
    countryCode: string;
    countryPrefix: string;
    phoneNumber: string;
    fullPhoneNumber: string;
  };
  email: string;
  dateOfBirth: string;
  address: AddressInterface;
  invitationCode: string;
  id: string;
}
