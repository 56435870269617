import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientConfigurationInterface, RequestsPaginationInterface } from '../../interfaces';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PatientSearchInterface } from '../../interfaces/patients/patient-search.interface';


@Injectable({
  providedIn: 'root'
})
export class PatientSearchApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/PatientSearch`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getConfiguration(patientId: string): Observable<PatientConfigurationInterface> {
    return this.httpClient.get<PatientConfigurationInterface>(`${this.apiUrl}/${patientId}/configuration`);
  }

  public patientSearch(currentPage: number, pageSize: number, search: string): Observable<RequestsPaginationInterface<PatientSearchInterface>> {
    return this.httpClient.get<RequestsPaginationInterface<PatientSearchInterface>>(`${this.apiUrl}`, {
      params: {
        currentPage: currentPage.toString(),
        pageSize: pageSize.toString(),
        search: search
      }
    });
  }
}
