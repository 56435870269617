import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { PrescriptionInterface } from '../../interfaces';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppointmentRecommendationApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/AppointmentRecommendation`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public createPrescription(appointmentId: string, prescription: PrescriptionInterface): Observable<PrescriptionInterface> {
    return this.httpClient.post<PrescriptionInterface>(`${this.apiUrl}/${appointmentId}`, prescription);
  }

  public getPrescription(appointmentId: string): Observable<PrescriptionInterface[]> {
    return this.httpClient.get<PrescriptionInterface[]>(`${this.apiUrl}/${appointmentId}`);
  }

  public deletePrescription(appointmentId: string, recommendationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${appointmentId}/${recommendationId}`);
  }

  public editPrescription(appointmentId: string, recommendationId: string, prescription: PrescriptionInterface): Observable<PrescriptionInterface> {
    return this.httpClient.put<PrescriptionInterface>(`${this.apiUrl}/${appointmentId}/${recommendationId}`, prescription);
  }

  public exportRecommendation(appointmentId: string): Observable<HttpResponse<Blob> | any> {
    return this.httpClient.get<Blob>(`${this.apiUrl}/${appointmentId}/export`,
      {
        observe: 'response',
        responseType: 'blob' as 'json'
      });
  }
}
