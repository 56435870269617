export interface PaginationInterface<T> {
  count: number;
  currentPage: number;
  items: T[];
  pageSize: number;
  skip: number;
  take: number;
  total: number;
  totalPages: number;
}
