import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultMessageInterface } from '../../interfaces/patients/result-message.interface';
import { environment } from '../../../../environments/environment';
import { LanguageTypeEnum, TimeModeEnum } from '../../enums';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CommonUserApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/CommonUser`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public saveAvatar(file: FormData): Observable<ResultMessageInterface> {
    return this.httpClient.post<ResultMessageInterface>(`${this.apiUrl}/avatar`, file);
  }

  public saveTimeFormat(timeMode: TimeModeEnum): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/timemode`, { timeMode });
  }

  public saveTimeZone(timeZoneId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/timezone`, { timeZoneId });
  }

  public saveLanguage(language: LanguageTypeEnum): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/language`, { language });
  }
}
