import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { ChatApiService } from './api';
import { NotificationService } from './notification.service';

import { WriteMessageDialogComponent } from '../../shared/dialogs/write-message-dialog/write-message-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class WriteMessageDialogService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly dialog: MatDialog,
              private readonly chatApiService: ChatApiService,
              private readonly router: Router,
              private readonly notificationService: NotificationService) {
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public writeMessage(id: string): void {
    this.chatApiService.getChatByPartnerId(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((chatHistory) => {
          this.router.navigate(['messages'], { queryParams: { chatId: chatHistory.id } });
        }, () => {
          this.openDialog(id);
        }
      );
  }

  private openDialog(id: string): void {
    const dialogRef = this.dialog.open(WriteMessageDialogComponent, { data: { id } });

    dialogRef?.afterClosed()
      .pipe(
        filter((formData) => !!formData),
        switchMap((formData) => this.chatApiService.sendMessage(formData)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ({ chatId }) => {
          this.router.navigate(['messages'], { queryParams: { chatId } });
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }
}
