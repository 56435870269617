import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';

import { FamilyMemberInterface, NewFamilyMemberInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FamilyMemberApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/FamilyMember`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getFamilyMembers(): Observable<FamilyMemberInterface[]> {
    return this.httpClient.get<FamilyMemberInterface[]>(this.apiUrl);
  }

  public addFamilyMember(familyMember: NewFamilyMemberInterface): Observable<FamilyMemberInterface> {
    return this.httpClient.post<FamilyMemberInterface>(this.apiUrl, familyMember);
  }

  public deleteFamilyMember(familyMemberId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${familyMemberId}`);
  }
}
