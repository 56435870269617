<div class="modal-wrapper">
  <div class="title">
    <h1 class="page-title">
      {{ (canCreateChat ? 'messages.createNewGroupChatLabel' : 'messages.addAColleagueLabel') | translate }}
    </h1>

    <mat-icon
      matTooltip="{{'messages.addAColleagueTooltipLabel' | translate}}"
      [svgIcon]="'support'"
      matTooltipClass="tooltip">
    </mat-icon>
  </div>
  <div class="small-icon-button">
    <mat-form-field appearance="outline" class="input-container-item-large search-filter">
      <mat-icon matPrefix [svgIcon]="'search'"></mat-icon>

      <input [formControl]="searchName" [placeholder]="'general.searchLabel' | translate" matInput/>

      <button (click)="resetSearch()" mat-icon-button matSuffix class="m-r-1" *ngIf="searchName.value">
        <mat-icon [svgIcon]="'close'"></mat-icon>
      </button>
    </mat-form-field>
  </div>

  <div *ngFor="let colleague of colleagues | search:searchName.value"
       [ngClass]="{'selected': selectedDoctorsIds.includes(colleague.doctorId)}"
       class="colleague-item">
    <div class="name">
      <span>{{ colleague.firstName + ' ' + colleague.lastName }}</span>

      <mat-icon [svgIcon]="'check'" *ngIf="selectedDoctorsIds.includes(colleague.doctorId)"></mat-icon>
    </div>

    <div class="basic-icon-button">
      <button (click)="selectDoctor(colleague.doctorId)"
              *ngIf="!selectedDoctorsIds.includes(colleague.doctorId) else removeButton"
              class="button-white transparent-button"
              mat-button>
        <mat-icon [svgIcon]="'add-user'"></mat-icon>

        <span>{{ 'messages.addColleagueLabel' | translate }}</span>s
      </button>
    </div>

    <ng-template #removeButton>
      <button class="transparent-button basic-icon-button button-transparent-red"
              (click)="selectDoctor(colleague.doctorId)" mat-button>
        <mat-icon [svgIcon]="'delete'"></mat-icon>
        {{ 'general.cancelLabel' | translate }}
      </button>
    </ng-template>
  </div>

  <p *ngIf="!(colleagues | search:searchName.value)?.length"
     class="no-record-found">{{ 'messages.noParticipantsToChatLabel' | translate }}
  </p>

  <div class="button-container">
    <button class="transparent-button" mat-button mat-dialog-close>{{ 'general.cancelLabel' | translate }}</button>

    <button (click)="save()" [disabled]="!selectedDoctorsIds.length" class="button-primary"
            mat-button>{{ 'general.saveLabel' | translate }}
    </button>
  </div>
</div>
