export enum RequestVisitStepperEnum {
  service = '0',
  requestVisit = '1' ,
  scheduleVisit = '2',
  choseFamilyMember = '3',
  choseLanguage = '4',
  chooseSpecialty = '5',
  medicalHistory = '6',
  contactInfo = '7',
  summary = '8'
}
