<div (click)="selectCardEmitter.emit()" class="list-item">
  <div [ngClass]="{'active': isSelectedCard}" class="list-item-wrapper">
    <div class="list-item-info-container">
        <ng-content></ng-content>
    </div>

    <button mat-icon-button>
      <mat-icon [svgIcon]="'arrow-forward'"></mat-icon>
    </button>
  </div>
</div>

