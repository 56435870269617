import { GenderEnum } from '../../enums';

export interface PatientEhrGeneralInfoInterface {
  familyMemberId?: string;
  dateOfBirth: string;
  height: number;
  heightUnit: number;
  weight: number;
  weightUnit: number;
  gender: GenderEnum;
}
