import { AppointmentVideoEventStageEnum } from '../../enums';

export interface AlertsInterface {
  actualTill?: any;
  appointmentId?: string;
  chatId?: string;
  createdTimestamp: Date;
  delivered: boolean;
  fromName?: string;
  id?: string;
  message?: string;
  notificationType: number;
  requiresInteraction?: boolean;
  notificationId?: string;
  title: string;
  videoEvent?: AppointmentVideoEventStageEnum;
}
