import { MessagePaginationInterface } from './message-pagination.interface';
import { MessageInterface } from './message.interface';

export interface ChatHistoryInterface {
  messages?: MessagePaginationInterface;
  lastMessage?: MessageInterface;
  unreadMessagesCount?: number;
  chatName?: string;
  isAIChat?: boolean;
  isGroupChat?: boolean;
  isDoctors?: boolean;
  lastUpdateDate?: string;
  isAttachmentsAllowed?: boolean;
  id?: string;
}


