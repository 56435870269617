export interface EducationExperienceInterface {
  institutionName: string;
  city?: string;
  department?: string;
  degree: string;
  since?: string;
  till: string;
  name?: string;
  id?: string;
  doctorId?: string;
}
