import { SubscriptionProviderInterface } from './subscription-provider.interface';
import { SubscriptionRequestStatusEnum } from '../../enums';
import { SubscriptionSubscriberInterface } from './subscription-subscriber.interface';
import { ProviderServiceInterface } from './provider-service.interface';
import { SubscriptionPriceInterface } from './subscription-price.interface';
import { AddressInterface, SpecialityInterface } from '../profile-settings';

export interface SubscriptionRequestInterface {
  subscriber?: SubscriptionSubscriberInterface,
  provider?: SubscriptionProviderInterface,
  subscriptionPrice: SubscriptionPriceInterface,
  subscriptionService: ProviderServiceInterface
  status: SubscriptionRequestStatusEnum,
  specialty: SpecialityInterface,
  subscriberAddress: AddressInterface,
  id: string
}
