<div [ngClass]="{'vc-sidebar-closed-container': !sidebarExpanded}" class="vc-sidebar-container">
  <div class="vc-sidebar-header">
    <img *ngIf="sidebarExpanded" alt="ViClinic Doctor Portal" src="assets/images/vc-logo.svg">

    <button (click)="doToggleSidebar()" mat-icon-button>
      <mat-icon [svgIcon]="'menu'"></mat-icon>
    </button>
  </div>

  <div class="vc-sidebar-items">
    <div class="overflow-auto">
      <a *ngIf="currentUserRole === userRole.Patient && menuItems.length"
         [href]="preVisitUrl"
         class="vc-menu-item">
        <mat-icon [svgIcon]="'ai'"
                  class="icon">
        </mat-icon>

        <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{ 'sideBar.preVisitLabel' | translate}}</p>
      </a>

      <ng-container *ngFor="let menuItem of menuItems">
        <a *ngIf="menuItem?.controlName" [ngClass]="{'disabled-item': doctorStatus.Active !== status}"
           [routerLink]="menuItem?.routerLink" (click)="selectItemMenu()"
           class="vc-menu-item" routerLinkActive="active">
          <mat-icon [matBadgeHidden]="badgesFormGroup.get(menuItem.controlName).value <= 0 || sidebarExpanded"
                    [matBadge]="badgesFormGroup.get(menuItem.controlName).value"
                    [svgIcon]="menuItem?.icon"
                    class="icon">
          </mat-icon>

          <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

          <div *ngIf="sidebarExpanded && badgesFormGroup?.get(menuItem.controlName).value > 0"
               class="vc-menu-item-badge">
            {{badgesFormGroup?.get(menuItem.controlName).value}}
          </div>
        </a>

        <a
          *ngIf="menuItem?.icon && !menuItem?.controlName && !menuItem?.children"
          [ngClass]="{'disabled-item': doctorStatus.Active !== status}" [routerLink]="menuItem.routerLink" (click)="selectItemMenu()"
          class="vc-menu-item" routerLinkActive="active">
          <mat-icon [svgIcon]="menuItem.icon"
                    class="icon">
          </mat-icon>

          <div *ngIf="menuItem?.routerLink === 'account-settings' && !completedPatientPersonalInformation" class="red-point"></div>
          <div *ngIf="menuItem?.routerLink === 'medical-information' && !hasMedicalInformation" class="red-point"></div>

          <div [ngClass]="{'w-100': sidebarExpanded}" class="d-flex">

            <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

            <mat-icon
              *ngIf="!!menuItem?.tooltip && sidebarExpanded"
              matTooltip="{{'sideBar.doctorViTooltip' | translate}}"
              class="dr-viai-support-icon"
              matTooltipClass="wide-tooltip"
              matTooltipPosition="right"
              [svgIcon]="'support'">
            </mat-icon>
          </div>
        </a>

        <a (click)="toggleManageProfileSettings()" *ngIf="menuItem?.routerLink === 'profile-settings'"
           [ngClass]="{'disabled-item': !isProfileSettingsAvailable}"
           [routerLink]="menuItem.routerLink" class="vc-menu-item"
           routerLinkActive="active">
          <mat-icon [svgIcon]="menuItem.icon" class="icon"></mat-icon>

          <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{menuItem.title | translate}}</p>

          <div *ngIf="sidebarExpanded">
            <mat-icon [svgIcon]="openManageProfileSettingsItem ? 'expand-less' : 'expand-more'"></mat-icon>
          </div>
        </a>

        <div *ngIf="sidebarExpanded && openManageProfileSettingsItem && menuItem?.routerLink === 'profile-settings'"
             [ngClass]="{'disabled-item': !isProfileSettingsAvailable}" class="vc-menu-item-element-wrapper" (click)="selectItemMenu()">
          <ng-container *ngFor="let item of menuItem.children">
            <div *ngIf="!(item.scrollElement === 'bankingInformation' && !(completedPersonalInformation$ | async))"
                 (click)="scrollToElement(item.scrollElement)"
                 [ngClass]="{'active-profile-settings-item': currentProfileSettingsSection === item.scrollElement && checkProfileSettingsUrl}"
                 class="vc-menu-item-element">
              <p>{{item.title | translate}}</p>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div>
      <div *ngIf="sidebarExpanded" class="vc-menu-item">
        <button class="button-red" (click)="logout()" [disabled]="isLogoutProcessing"
                mat-raised-button>
          {{'general.logoutLabel' | translate}}
        </button>
      </div>

      <div *ngIf="!sidebarExpanded" class="vc-menu-item">
        <button (click)="logout()" [disabled]="isLogoutProcessing">
          <mat-icon [svgIcon]="'log-out'"
                    class="icon">
          </mat-icon>
        </button>
      </div>

      <a [routerLink]="['support']" (click)="selectItemMenu()" class="vc-menu-item"
         routerLinkActive="active">
        <mat-icon [svgIcon]="'question-mark'"
                  class="icon">
        </mat-icon>

        <p *ngIf="sidebarExpanded" class="vc-menu-item-label">{{'sideBar.helpSupportLabel' | translate}}</p>
      </a>

      <p *ngIf="sidebarExpanded" class="vc-copyright">© Copyright ViClinic LLC</p>
    </div>
  </div>
</div>
