import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import {
  AppointmentServiceInterface,
  DoctorConfigurationInterface,
  DoctorInterface,
  DoctorProfileSettingsInterface,
  DoctorRequestInterface,
  DoctorReviewsInterface,
  LanguageInterface,
  PaginationInterface,
  ProviderServiceInterface,
  SpecialityInterface
} from '../../interfaces';
import { ProviderServiceFeatureTypeEnum } from '../../enums';


@Injectable({
  providedIn: 'root'
})
export class DoctorSearchApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/DoctorSearch`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getConfiguration(doctorId: string): Observable<DoctorConfigurationInterface> {
    return this.httpClient.get<DoctorConfigurationInterface>(`${this.apiUrl}/${doctorId}/configuration`);
  }

  public getDoctors(params: DoctorRequestInterface | HttpParams): Observable<PaginationInterface<DoctorInterface>> {
    return this.httpClient.get<PaginationInterface<DoctorInterface>>(this.apiUrl, { params: params as HttpParams });
  }

  public getDoctorById(doctorId: string): Observable<DoctorProfileSettingsInterface> {
    return this.httpClient.get<DoctorProfileSettingsInterface>(`${this.apiUrl}/${doctorId}`);
  }

  public getDoctorReviews(doctorId: string): Observable<PaginationInterface<DoctorReviewsInterface>> {
    return this.httpClient.get<PaginationInterface<DoctorReviewsInterface>>(`${this.apiUrl}/${doctorId}/reviews`);
  }

  public getPatientProviderServices(doctorId: string, featureType: ProviderServiceFeatureTypeEnum): Observable<ProviderServiceInterface[]> {
    return this.httpClient.get<ProviderServiceInterface[]>(`${this.apiUrl}/${doctorId}/provider-services?featureType=${featureType}`);
  }

  public getAppointmentServices(doctorId: string): Observable<AppointmentServiceInterface[]> {
    return this.httpClient.get<any>(`${this.apiUrl}/${doctorId}/appointment-services`);
  }

  public getLanguages(): Observable<LanguageInterface[]> {
    return this.httpClient.get<LanguageInterface[]>(`${this.apiUrl}/languages`);
  }

  public getSpecialties(languageCode: string): Observable<SpecialityInterface[]> {
    return this.httpClient.get<SpecialityInterface[]>(`${this.apiUrl}/specialties?languageCode=${languageCode}`);
  }
}
