import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AuthService, LanguageService } from '../../core/services';
import { TimeModeEnum } from '../../core/enums';

@Pipe({ name: 'localDate' })
export class LocalDatePipe implements PipeTransform {
  constructor(private readonly authService: AuthService,
              private readonly languageService: LanguageService) {
  }

  public transform(date: any, format = 'longDate'): any {
    const timeMode = this.authService.timeMode;
    let dateFormat;
    switch (format) {
      case 'longDateTime':
       dateFormat = timeMode === TimeModeEnum.TwelveHours ? 'MMMM dd, y h:mm a' : 'MMMM dd, y HH:mm';
        break;
      case 'shortDate':
        dateFormat = 'MMMM dd, y';
        break;
      case 'shortTime':
        dateFormat = timeMode === TimeModeEnum.TwelveHours ? 'h:mm a' : 'HH:mm';
        break;
      default:
        dateFormat = format;
        break;
    }

    const datePipe = new DatePipe(this.languageService.currentLanguage);

    return datePipe.transform(date, dateFormat);
  }
}
