import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, throwError } from 'rxjs';

import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  AppointmentStatusEnum,
  CurrentRecordPagesEnum,
  FilterItemEnum,
  FilterVisitItemEnum,
  GenderEnum, RecommendationDialogsEnum,
  RoleEnum
} from '../../../core/enums';

import {
  AddressInterface,
  CustomAppointmentOfferInterface,
  FilterVisitsInterface,
  MedicalHistoryInterface,
  PatientConfigurationInterface,
  PatientInterface,
  PrescriptionInterface,
  PreviousVisitInfoInterface,
  RecommendationInterface,
  RequestAppointmentInterface
} from '../../../core/interfaces';
import { User } from '../../../core/models/user.model';

import {
  AlertsService,
  AppointmentApiService,
  AppointmentNoteApiService,
  AppointmentRecommendationApiService,
  AuthService,
  ConfirmDialogService, DoctorApiService,
  MedicalHistoryApiService,
  FamilyMemberApiService,
  MedicalInformationService,
  NotificationService,
  PatientService,
  SelectedAppointmentService,
  SelectedRecommendationDialogService,
  SideBarService,
  VideoService,
  WriteMessageDialogService,
  PatientApiService
} from '../../../core/services';
import { EditPrescriptionDialogComponent } from '../../dialogs/edit-prescription/edit-prescription-dialog.component';

import {
  EditRecommendationDialogComponent
} from '../../dialogs/edit-recommendation/edit-recommendation-dialog.component';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from '../../dialogs/offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';
import {
  CreateCustomAppointmentOfferDialogComponent
} from '../../dialogs/create-custom-appointment-offer-dialog/create-custom-appointment-offer-dialog.component';
import {
  CompleteMedicalHistoryDialogComponent
} from '../../dialogs/complete-medical-history-dialog/complete-medical-history-dialog.component';


@Component({
  selector: 'vi-clinic-patient-ehr',
  templateUrl: './patient-ehr.component.html',
  styleUrls: ['./patient-ehr.component.scss']
})
export class PatientEhrComponent implements OnInit, OnDestroy {
  @ViewChild('filterMenu') public filterMenu: MatMenuTrigger;
  @ViewChild('addNewPrescriptionButton') public addNewPrescriptionButton: ElementRef;
  @ViewChild('editRecordButton') public editRecordButton: ElementRef;

  @Output() public isProposeTimeOptionState = new EventEmitter();
  @Output() public removePatientEvent = new EventEmitter();
  @Output() public removeFamilyMember = new EventEmitter();
  @Output() public openChatOnVideoEvent = new EventEmitter();
  @Output() public openChatOnAppointmentsEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public openChatOnVisitHistoryEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public openConciergeServiceEvent = new EventEmitter();

  @Input() public currentRecord: CurrentRecordPagesEnum;
  @Input() public waitingRoomAvailability: boolean;
  @Input() public canRemovePatient: boolean = false;
  @Input() public canEditDoctor: string;
  @Input() public patientId: string;
  @Input() public isConciergeServicePage: boolean = false;
  @Input() public patientConfiguration: PatientConfigurationInterface;

  public doctorId: string;
  public appointmentInfo: RequestAppointmentInterface;
  public previousVisits: PreviousVisitInfoInterface[];
  public patient: PatientInterface;
  public generalMedicalHistory: MedicalHistoryInterface;
  public genderText = GenderEnum;
  public canEditDialog: boolean = true;
  public sectionCurrentComplaintStatus: boolean = false;
  public sectionMedicalHistoryStatus: boolean = true;
  public callStarted: boolean = false;
  public recommendationInfo: RecommendationInterface;
  public prescriptionInfo: PrescriptionInterface[];
  public appointmentStatus = AppointmentStatusEnum;
  public currentRecordPages = CurrentRecordPagesEnum;
  public openMedicalHistoryDialog = false;
  public currentUser: User;
  public patientAddress: AddressInterface;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();
  private copyPreviousVisits: PreviousVisitInfoInterface[];

  constructor(private readonly familyMemberApiService: FamilyMemberApiService,
              private readonly notificationService: NotificationService,
              private readonly patientService: PatientService,
              private readonly patientApiService: PatientApiService,
              private readonly selectedAppointmentService: SelectedAppointmentService,
              private readonly medicalInformationService: MedicalInformationService,
              private readonly authService: AuthService,
              private readonly videoService: VideoService,
              private readonly dialog: MatDialog,
              private readonly sideBarService: SideBarService,
              private readonly alertService: AlertsService,
              private readonly selectedRecommendationDialogService: SelectedRecommendationDialogService,
              private readonly router: Router,
              private readonly writeMessageDialogService: WriteMessageDialogService,
              private readonly appointmentsApiService: AppointmentApiService,
              private readonly translateService: TranslateService,
              private readonly confirmDialogService: ConfirmDialogService,
              private readonly doctorApiService: DoctorApiService,
              private readonly appointmentNoteApiService: AppointmentNoteApiService,
              private readonly appointmentRecommendationApiService: AppointmentRecommendationApiService,
              private readonly medicalHistoryApiService: MedicalHistoryApiService,) {
  }

  public get isDoctorMode(): boolean {
    return this.currentUser.role === RoleEnum.Doctor;
  }

  public get allowingEditingDate(): boolean {
    const currentDate: Date = new Date();
    const visitDate: Date = new Date(this.appointmentInfo?.followUpScheduledStart || this.appointmentInfo?.scheduledStart);
    const allowEditingTime: number = 604800000;

    return +currentDate < (+visitDate + allowEditingTime);
  }

  public ngOnInit(): void {
    this.getPatient();
    if (this.currentRecord === CurrentRecordPagesEnum.Appointments ||
      this.currentRecord === CurrentRecordPagesEnum.Video ||
      this.currentRecord === CurrentRecordPagesEnum.VisitHistory) {
      this.subscribeToSelectedAppointment();
    }
    this.setDoctorId();
    this.getCurrentUser();
    this.checkCall();
    this.checkOnJoin();
    this.subscribeToOpenRecommendationDialog();
    this.subscribeToOpeningHistoryDialog();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openWriteMessageDialog(): void {
    if (this.currentRecord === this.currentRecordPages.Video) {
      this.openChatOnVideoEvent.emit();
    } else if (this.currentRecord === this.currentRecordPages.Appointments) {
      this.openChatOnAppointmentsEvent.emit(this.patient.patientId || this.patient.id);
    } else if (this.currentRecord === this.currentRecordPages.VisitHistory) {
      this.openChatOnVisitHistoryEvent.emit(this.patient.patientId || this.patient.id);
    } else {
      this.writeMessageDialogService.writeMessage(this.patient.patientId || this.patient.id);
    }
  }

  public joinToRoom(): void {
    this.router.navigate(['../video']).then();
  }

  public openCreateCustomAppointmentOfferDialog() {
    const dialogRef = this.dialog.open(CreateCustomAppointmentOfferDialogComponent);

    dialogRef.afterClosed()
      .pipe(
        filter((data) => !!data),
        switchMap((value) => {

          const customOffer: CustomAppointmentOfferInterface = {
            patientId: this.patient.patientId ? this.patient.patientId : this.patient.id,
            familyMemberId: this.patient?.familyMemberId,
            additionalInformation: value.additionalInformation,
            customPrice: value.customPrice * 100,
            scheduleOptions: value.scheduleOptions
          }

          return this.appointmentsApiService.createCustomAppointmentOffer(customOffer)
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        () => {
          this.alertService.newAlert$.next(null);
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public removePatient(): void {
    const title = this.translateService.instant('general.doYouWantDeletePatientLabel');

    this.confirmDialogService.openConfirmDialog(title)
      .pipe(
        first(),
        filter(result => !!result),
        switchMap(() => {
          return this.doctorApiService.deletePatient(this.patient.id)
            .pipe(
              tap(() => {
                this.cleanMedicalHistory();
                this.removePatientEvent.emit();
                this.notificationService.showSuccessNotification();
              }),
              catchError((error) => {
                this.notificationService.showErrorNotification(error?.error?.detail);

                return of(error);
              }),
              takeUntil(this.unsubscribe$)
            );
        })
      )
      .subscribe();
  }

  public changeCurrentComplaintSection(): void {
    this.sectionCurrentComplaintStatus = !this.sectionCurrentComplaintStatus;
  }

  public changeMedicalHistorySection(): void {
    this.sectionMedicalHistoryStatus = !this.sectionMedicalHistoryStatus;
  }

  public applyFilters(event: FilterVisitsInterface): void {
    this.previousVisits = this.copyPreviousVisits;
    if (event.byDoctor > FilterItemEnum.None) {
      if (event.byDoctor === FilterVisitItemEnum.MyVisits) {
        this.previousVisits = this.previousVisits.filter(visit => visit.doctorInfo.id.includes(this.doctorId));
      } else if (event.byDoctor === FilterVisitItemEnum.Others) {
        this.previousVisits = this.previousVisits.filter(visit => visit.doctorInfo.id !== this.doctorId);
      }
    }
    if (event.visitTime > FilterItemEnum.None) {
      if (event.visitTime === FilterItemEnum.Ascending) {
        this.previousVisits = this.previousVisits.sort((a, b) => new Date(a.visitDate) > new Date(b.visitDate) ? 1 : -1);
      } else if (event.visitTime === FilterItemEnum.Descending) {
        this.previousVisits.sort((a, b) => new Date(a.visitDate) < new Date(b.visitDate) ? 1 : -1);
      }
    }
    this.filterMenu.closeMenu();
  }

  public openEditRecommendationDialog(): void {
    const dialogRef = this.dialog.open(EditRecommendationDialogComponent, {
      data: {
        summary: this.recommendationInfo,
        isEdit: !!this.recommendationInfo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.appointmentNoteApiService.editRecommendation(this.appointmentInfo.id, result)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (response) => {
              this.recommendationInfo = response;
              this.editRecordButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            },
            (error) => this.notificationService.showErrorNotification(error?.error?.detail));
        ;
      }
    });
  }

  public openAddNewPrescriptionDialog(): void {
    const dialogRefs = this.dialog.open(EditPrescriptionDialogComponent, {
      data: {
        prescription: { appointmentId: this.appointmentInfo.id },
        isEdit: false
      }
    });

    dialogRefs.afterClosed()
      .subscribe(
        (result) => {
          if (result) {
            this.appointmentRecommendationApiService.createPrescription(this.appointmentInfo.id, result)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                (response) => {
                  this.prescriptionInfo.push(response);
                  this.addNewPrescriptionButton.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                },
                (error) => this.notificationService.showErrorNotification(error?.error?.detail));
          }
        }
      );
  }

  public exportEhr(): void {
    const model = {
      patientId: this.patient?.patientId || this.patient?.id,
      familyMemberId: this.patient?.familyMemberId || null,
    };
    this.medicalHistoryApiService.exportEhr(model)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        const downloadURL = window.URL.createObjectURL(new Blob([result.body as BlobPart]));
        const link = document.createElement('a');
        const contentDispositionHeader = result.headers.get('Content-Disposition');
        const fileName = contentDispositionHeader.split(';')[1].split('filename')[1].split('=')[1].trim();
        link.href = downloadURL;
        link.download = fileName;
        link.click();
      }, (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public deletedPrescription(prescriptionId: string): void {
    this.appointmentRecommendationApiService.deletePrescription(this.appointmentInfo.id, prescriptionId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          const index = this.prescriptionInfo.findIndex(element => element.id === prescriptionId);
          if (index != -1) {
            this.prescriptionInfo.splice(index, 1);
            this.notificationService.showSuccessNotification();
          }
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public scheduleNow(): void {
    this.isProposeTimeOptionState.emit(true);
  }

  public editMedicalHistoryDialog(): void {
    const patientId = this.patient?.patientId ? this.patient.patientId : this.patient.id
    const familyMemberId = this.patient?.familyMemberId

    this.openCompleteMedicalHistoryDialog(patientId, familyMemberId);
  }

  public openCompleteMedicalHistoryDialog(patientId: string, familyMemberId: string): void {
    const dialogRefs = this.dialog.open(CompleteMedicalHistoryDialogComponent, {
      data: { patientId: patientId, familyMemberId: familyMemberId }
    });

    dialogRefs.afterClosed()
      .pipe(
        filter(value => !!value),
        switchMap((value) => this.medicalHistoryApiService.editPersonalInfo(value, this.patient.id, this.patient?.familyMemberId)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(
        () => {
          this.openOfflineEhrPersonalMedicalHistoryDialog(patientId, familyMemberId);
        });
  }

  private openOfflineEhrPersonalMedicalHistoryDialog(patientId: string, familyMemberId: string): void {
    const dialog = this.dialog.open(OfflineEhrPersonalMedicalHistoryDialogComponent, {
      data: {
        patientId: patientId,
        familyMemberId: familyMemberId
      },
      disableClose: true
    });
    dialog.afterClosed().pipe(
      filter((result) => !!result),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      const { id, patientId, familyMemberId } = this.patient;
      this.getPatientRequiredInfo()
      this.getMedicationHistory(patientId || id, familyMemberId);
    });
  }

  private getPatientRequiredInfo(): void {
    this.patientApiService.getRequiredInfo().pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.sideBarService.setPatientRequiredInfo(value);
    });
  }

  public openConfirmDialog(): void {
    const title = this.translateService.instant(
      'patientEHR.doYouWantDeleteFamilyMemberLabel',
      { name: `${this.patient.firstName} ${this.patient.lastName}` }
    );
    const message = this.translateService.instant('patientEHR.deleteFamilyMemberLabelMessage');

    this.confirmDialogService.openConfirmDialog(title, message)
      .pipe(
        filter(result => !!result),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.deleteFamilyMember();
      });
  }

  private cleanMedicalHistory(): void {
    this.generalMedicalHistory = null;
    this.previousVisits = null;
    this.copyPreviousVisits = null;
    this.patient = null;
    this.patientAddress = null;
  }

  private checkCall(): void {
    this.videoService.callStarted$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (result) => {
          this.callStarted = result;
        }
      );
  }

  private checkOnJoin(): void {
    this.videoService.onJoin$
      .pipe(
        filter(result => !!result),
        takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          if (this.currentUser.role === RoleEnum.Doctor) {
            this.getRecommendation();
          } else {
            this.getDiagnose();
          }

          this.getPrescription();
        }
      );
  }

  private subscribeToSelectedAppointment(): void {
    this.selectedAppointmentService.selectedAppointment
      .pipe(
        takeUntil(this.unsubscribe$))
      .subscribe(
        (appointment) => {
          this.appointmentInfo = appointment;
          if (appointment?.patientInfo?.address)  {
            this.patientAddress = appointment.patientInfo.address;
          }
        }
      );
  }

  private getPatient(): void {
    const mySub = this.currentRecord !== this.currentRecordPages.Video
      ? this.patientService.getPatientInfo()
      : this.patientService.getPatientInfoVideo();
    mySub
      .pipe(
        tap((patient) => {
          this.patient = patient;
          this.patientAddress = {
            state: patient?.state,
            countryName: patient?.countryName
          };
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => {
        if (this.patient) {
          const { id, patientId, familyMemberId } = value;
          this.getMedicationHistory(patientId || id, familyMemberId);
        } else {
          this.cleanMedicalHistory();
        }
      });
  }

  private getMedicationHistory(patientId: string, familyMemberId: string): void {
    this.medicalHistoryApiService.getGeneralMedicalHistory(patientId, familyMemberId)
      .pipe(takeUntil(this.unsubscribe$)).subscribe((medicalHistory) => {
      this.generalMedicalHistory = medicalHistory;
      this.previousVisits = medicalHistory.previousVisitsInfo;
      this.canEditDialog = true;
      this.copyPreviousVisits = [...medicalHistory.previousVisitsInfo];
      if ((this.currentRecord === this.currentRecordPages.Video && this.callStarted) ||
        this.currentRecord === this.currentRecordPages.VisitHistory) {
        if (this.currentUser.role === 'doctor') {
          this.getRecommendation();
        } else {
          this.getDiagnose();
        }
        this.getPrescription();
      }

      if ((this.currentRecord === this.currentRecordPages.VisitHistory || this.currentRecord === this.currentRecordPages.Video) && this.previousVisits.length) {
        const index = this.previousVisits.findIndex(element => element.id === this.appointmentInfo?.id);

        if (index != -1) {
          this.previousVisits.splice(index, 1);
          this.copyPreviousVisits.splice(index, 1);
        }
      }

      if (this.openMedicalHistoryDialog) {
        this.openMedicalHistoryDialog = false;

        this.openOfflineEhrPersonalMedicalHistoryDialog(patientId, familyMemberId);
      }
    }, (error) => {
      this.notificationService.showErrorNotification(error?.error?.detail);
      this.canEditDialog = false;

      return this.handleMedicationHistoryError(error);
    });
  }

  private subscribeToOpenRecommendationDialog(): void {
    this.selectedRecommendationDialogService.selectedRecommendationDialog$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(result => !!result))
      .subscribe((result) => {
        if (this.callStarted) {
          if (result === RecommendationDialogsEnum.Record) {
            this.openEditRecommendationDialog();
          } else {
            this.openAddNewPrescriptionDialog();
          }
        }
      });
  }

  private setDoctorId(): void {
    this.authService.userInfo$
      .pipe(
        filter((user) => !!user),
        takeUntil(this.unsubscribe$))
      .subscribe(
        (personalInfo) => {
          this.doctorId = personalInfo.id;
        }
      );
  }


  private getCurrentUser(): void {
    this.authService.currentUser$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  private getRecommendation(): void {
    if (this.appointmentInfo) {
      this.appointmentNoteApiService.getRecommendation(this.appointmentInfo?.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            this.recommendationInfo = response;
          }
        );
    }
  }

  private getDiagnose(): void {
    if (this.appointmentInfo) {
      this.appointmentNoteApiService.getDiagnose(this.appointmentInfo?.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            this.recommendationInfo = response;
          }
        );
    }
  }

  private getPrescription(): void {
    if (this.appointmentInfo) {
      this.appointmentRecommendationApiService.getPrescription(this.appointmentInfo?.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            this.prescriptionInfo = response;
          }
        );
    }
  }

  private handleMedicationHistoryError(error?: Error): Observable<never | null> {
    this.generalMedicalHistory = null;
    this.previousVisits = null;
    this.copyPreviousVisits = null;

    return error ? throwError(error) : of(null);
  }

  private subscribeToOpeningHistoryDialog(): void {
    this.medicalInformationService.getOpenMedicalHistoryDialog()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value => this.openMedicalHistoryDialog = value));
  }

  private deleteFamilyMember(): void {
    this.familyMemberApiService.deleteFamilyMember(this.patient.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.removeFamilyMember.emit();
          this.notificationService.showSuccessNotification();
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  protected readonly roleEnum = RoleEnum;
}
