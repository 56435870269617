import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  NewProviderServiceRequestInterface,
  ProviderServiceInterface,
  PaymentPriceInterface,
  EditPaymentPriceInterface,
  EditProviderServiceInterface,
} from '../../interfaces/';


@Injectable({
  providedIn: 'root',
})
export class ProviderServicesApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/ProviderServices`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public getProviderServices(): Observable<ProviderServiceInterface[]> {
    return this.httpClient.get<ProviderServiceInterface[]>(this.apiUrl);
  }

  public addProviderService(data: NewProviderServiceRequestInterface): Observable<ProviderServiceInterface> {
    return this.httpClient.post<ProviderServiceInterface>(this.apiUrl, data);
  }

  public editProviderService(subscriptionServiceId: string, data: EditProviderServiceInterface): Observable<ProviderServiceInterface> {
    return this.httpClient.put<ProviderServiceInterface>(`${this.apiUrl}/${subscriptionServiceId}/info`, data);
  }

  public addBillingOptions(providerServiceId: string, providerServiceSubscriptionPrices: PaymentPriceInterface[]): Observable<ProviderServiceInterface> {
    return this.httpClient.put<ProviderServiceInterface>(`${this.apiUrl}/${providerServiceId}/prices`, {
      providerServiceSubscriptionPrices,
      providerServicePaymentPrices: []
    });
  }

  public editOneTimePrice(oneTimePaymentPrice: EditPaymentPriceInterface): Observable<ProviderServiceInterface> {
    return this.httpClient.put<ProviderServiceInterface>(`${this.apiUrl}/${oneTimePaymentPrice.providerServiceId}/price/${oneTimePaymentPrice.providerServicePriceId}`, oneTimePaymentPrice);
  }

  public deleteProviderService(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${id}`);
  }

  public archiveSubscriptionProviderService(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${id}/archive`, null);
  }

  public unArchiveProviderService(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.apiUrl}/${id}/reactivate`, null);
  }

  public archiveProviderServicePrice(providerServiceId: string, priceId: string): Observable<void> {

    return this.httpClient.patch<void>(`${this.apiUrl}/${providerServiceId}/price/${priceId}/archive`, null);
  }
}
