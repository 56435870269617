import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { AddressFormGroup, DateTimeFormGroup, ProposeTimeOptionsFormGroup } from '../types';


@Injectable({
  providedIn: 'root',
})

export class ValidationService {
  private config = {};

  constructor(private readonly translateService: TranslateService) {
    this.initConfig();

    translateService.onLangChange
      .subscribe(() => this.initConfig());
  }

  public emailValidator(control: FormControl): any {
    const emailRegex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');

    if (control.value === null) {
      return null;
    }
    if (control.value === '') {
      return null;
    }
    if (!emailRegex.test(control.value)) {
      return { 'emailIncorrect': true };
    }

    return null;
  }

  public swiftValidator(control: FormControl): any {
    if (control.value?.length === 8 || control.value?.length === 11) {
      return null;
    }

    return { 'swiftIncorrect': true };
  }

  public whitespaceValidator(control: FormControl): { whitespace: boolean } | null {
    const isSpace = !(control?.value || '').match(/\S/g);

    return isSpace && !!control.value?.length ? { 'whitespace': true } : null;
  }

  public profileLanguageValidator(control: FormControl): any {
    if (typeof control.value === 'string' && control.value?.length > 0) {
      return { 'incorrectLanguage': true };
    }

    return null;
  }

  public getValidatorErrorMessage(control: any, config = this.config): any {
    if (control) {
      for (const propertyName in control.errors) {
        if (control.errors.hasOwnProperty(propertyName)) {
          if (propertyName === 'min') {
            return config[propertyName];
          }

          return config[propertyName];
        }
      }
    }

    return null;
  }

  public proposeTimeValidator(control: any): any {
    if (control.controls.time.value && control.controls.date.value) {
      const currentDate: moment.Moment = moment();

      const parsedTime = moment(control.controls.time.value, ['h:mm A', 'H:mm'], true);
      const hours = parsedTime.hours();
      const minutes = parsedTime.minutes();

      const value = moment(control.controls.date.value);
      value.hours(hours);
      value.minutes(minutes);

      const limitDate = moment().add(7, 'days');

      currentDate.add(1, 'hour');

      if (value.isBefore(currentDate) || value.isAfter(limitDate)) {
        control.controls.time.setErrors({ wrongDate: true });
        control.controls.date.setErrors({ wrongDate: true });
      } else {
        control.controls.time.setErrors(null);
        control.controls.date.setErrors(null);
      }
    }
  }

  public checkAddressGroupValidator(formGroup: FormGroup<AddressFormGroup>): void {
    const streetValue = formGroup.value.addressFirstLine;
    const countryCode = formGroup.value.countryCode as unknown as string;

    if (streetValue) {
      formGroup.get('city').setValidators(Validators.required);
      formGroup.get('city').updateValueAndValidity({ emitEvent: false, onlySelf: true });
    } else {
      formGroup.get('city').clearValidators();
      formGroup.get('city').updateValueAndValidity({ emitEvent: false, onlySelf: true });
    }

    if (countryCode === 'US') {
      formGroup.get('state').setValidators(Validators.required);
      formGroup.get('state').updateValueAndValidity({ emitEvent: false, onlySelf: true });
    } else {
      formGroup.get('state').clearValidators();
      formGroup.get('state').updateValueAndValidity({ emitEvent: false, onlySelf: true });
    }
  }

  public checkTimeDifference(formGroup: FormGroup<ProposeTimeOptionsFormGroup>): any {
    const forms = Object.values(formGroup.controls);

    const times = forms.map(
      (dateTimeForm) => {
        const parsedTime = moment(dateTimeForm.value.time, ['h:mm A', 'HH:mm'], true).format('HH:mm');

        return ({
          value: dateTimeForm.value.date + parsedTime,
          isValid: !!dateTimeForm.value.date && !!dateTimeForm.value.time
        });
      }
    );

    if (!forms[0].get('time').hasError('wrongDate')) {
      if (times[0].isValid && (times[0].value === times[1].value || times[0].value === times[2].value)) {
        (forms[0] as FormGroup<DateTimeFormGroup>).controls.time.setErrors({ sameTime: true });
        (forms[0] as FormGroup<DateTimeFormGroup>).controls.date.setErrors({ sameTime: true });
      }
    }

    if (!forms[1].get('time').hasError('wrongDate')) {
      if (times[1].isValid && (times[1].value === times[0].value || times[1].value === times[2].value)) {
        (forms[1] as FormGroup<DateTimeFormGroup>).controls.time.setErrors({ sameTime: true });
        (forms[1] as FormGroup<DateTimeFormGroup>).controls.date.setErrors({ sameTime: true });
      }
    }

    if (!forms[2].get('time').hasError('wrongDate')) {
      if (times[2].isValid && (times[2].value === times[0].value || times[2].value === times[1].value)) {
        (forms[2] as FormGroup<DateTimeFormGroup>).controls.time.setErrors({ sameTime: true });
        (forms[2] as FormGroup<DateTimeFormGroup>).controls.date.setErrors({ sameTime: true });
      }
    }
  }

  public fileSizeValidator(maxFileSize: number): any {
    return (control: FormControl): ValidationErrors | null => {
      if (control?.value?.size >= maxFileSize) {
        return { 'incorrectSize': maxFileSize };
      } else
        return null;
    };
  }

  public fileTypeValidator(allowedTypes: string[] ): any {
    return (control: FormControl): ValidationErrors | null => {
      if (!allowedTypes.includes(control?.value?.type)) {
        return { 'incorrectType': allowedTypes };
      } else
        return null;
    };
  }

  private initConfig(): void {
    this.translateService.get('validationService').subscribe(result => {
      this.config = result;
    });
  }

  public atLeastOneCheckboxCheckedValidator(fields: string[]): any {
    return (control: FormControl): ValidationErrors | null => {
      let checkboxes: boolean[] = []

      fields.forEach((field) => checkboxes.push(control.get(field).value))
      const isChecked = checkboxes.some(control => control === true);

      return isChecked ? null : { atLeastOneCheckboxChecked: true };
    };
  };
}
