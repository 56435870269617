export interface CreateAppointmentRequest {
  familyMemberId: string;
  selectedDoctorId: string;
  description: string;
  phoneCountryCode: string;
  phoneNumber: string;
  email: string;
  providerServicePaymentPriceId: string;
  specialtyId: string;
  languageCodes: string[];
  scheduleOptions: Date[];
}
