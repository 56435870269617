import { PaymentPriceInterface } from './payment-price.interface';

export interface AppointmentServiceInterface {
  name: string,
  description: string,
  paymentPrices: PaymentPriceInterface[];
  id: string,
  coveredBySubscription: boolean
}


