import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';

import { MedicalHistoryApiService } from '../../../core/services';
import { finalize, takeUntil } from 'rxjs/operators';
import {
  SymptomSummaryTranscriptInterface
} from '../../../core/interfaces/general/symptom-summary-transcript.interface';
import { NotificationService } from '../../../core/services';


@Component({
  templateUrl: './transcription-dialog.component.html',
  styleUrls: ['./transcription-dialog.component.scss']
})
export class TranscriptionDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  public transcript: SymptomSummaryTranscriptInterface[] = [];
  public isLoading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: { symptomId: string },
              private readonly medicalHistoryApiService: MedicalHistoryApiService,
              private readonly notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    this.getTranscriptions()
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getTranscriptions(): void {
    if (!this.dialogData.symptomId) {
      return
    }
    this.isLoading = true;

    this.medicalHistoryApiService.getSymptomSummaryTranscript(this.dialogData.symptomId)
      .pipe(
        finalize(() => this.isLoading = false),
        takeUntil(this.unsubscribe$))
      .subscribe({
        next: (transcript) => {
          this.transcript = transcript
        },
        error: (error) => {
          this.notificationService.showErrorNotification(error?.error?.detail)
        }
      })
  }
}
