import { MessageInterface } from './message.interface';

export interface ChatInterface {
  lastMessage: MessageInterface;
  lastUpdateDate: string;
  isAIChat: boolean;
  unreadMessagesCount: number;
  chatName: string;
  isGroupChat: boolean;
  isDoctors: boolean;
  id: string;
}
