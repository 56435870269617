/**
 Wait until element is rendered in the dom.
 Useful use case in this project was with mat-icon and star rating (mat-icon needs some time until it renders svg elements)
 @param el Element it can be document BODY or any other element.
 @param selector query selector string which is passed in "querySelector" method.
  This function returns element which was queried from the dom.
 */
export function waitElementToRender(el: Element, selector: string): Promise<ReturnType<typeof el.querySelector>> {
  return new Promise(resolve => {
    if (el.querySelector(selector)) {
      return resolve(el.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (el.querySelector(selector)) {
        observer.disconnect();
        resolve(el.querySelector(selector));
      }
    });

    observer.observe(el, {
      childList: true,
      subtree: true
    });
  });
}
