import { UserGeneralInfoInterface } from '../general';

export interface MessageInterface {
  id?: string;
  createdTimestamp?: string;
  createdBy?: string;
  text: string;
  attachmentUrl?: string;
  originalFileName?: string;
  contentType?: string;
  delivered?: boolean;
  removed?: boolean;
  chatId?: string;
  otherUserId?: string;
  file?: File;
  participant?: UserGeneralInfoInterface;
}
