<div class="doctor-profile-personal-info-wrapper">
  <div class="doctor-general-info d-flex flex-wrap">
    <div class="profile-avatar-container d-flex">
      <div class="avatar-image-wrapper">

        <!-- TODO: Will be added in the future-->
        <div *ngIf="false"
             class="is-online"></div>

        <img class="avatar-image" *ngIf="personalInfo?.photoUrl else emptyAvatar" [src]="personalInfo?.photoUrl"
             alt="x">
        <ng-template #emptyAvatar>
          <mat-icon class="icon" [svgIcon]="'avatar-placeholder'"></mat-icon>
        </ng-template>
      </div>
      <div>
        <span class="profile-fullName">{{ personalInfo?.firstName }} {{ personalInfo?.lastName }}</span>
        <div class="d-block">
          <vi-clinic-star-rating [rating]="personalInfo?.averageRating"></vi-clinic-star-rating>
        </div>
        <span class="d-flex profile-address" *ngIf="personalInfo?.countryName || personalInfo?.city">
          <mat-icon class="user-location-icon" [svgIcon]="'location'"></mat-icon>

          <span>{{ personalInfo?.countryName }} {{ personalInfo?.state ? ', ' + personalInfo?.state : '' }} {{ personalInfo?.city? ', ' + personalInfo?.city : ''  }}</span>
        </span>
      </div>
    </div>
    <div class="price-container">
      <div class="d-flex flex-wrap gap-1 m-b-1" *ngIf="!personalInfo?.isVirtualAssistant && !isDialog">
        <a *ngIf="doctorConfiguration?.canSubscribe" class="button-white-green basic-icon-button"
           [routerLink]="['../services']"
           [queryParams]="{doctorId: personalInfo?.id, tabIndex: serviceTabsEnum.AllPlans}"
           mat-button>
          <mat-icon [svgIcon]="'concierge-service'" class="icon"></mat-icon>
          {{ 'doctorProfile.viewServicesLabel' | translate }}</a>

        <a *ngIf="doctorConfiguration?.canCreateAppointment" class="button-green basic-icon-button"
           [routerLink]="['../visits/create-appointment']"
           [queryParams]="{doctorId: personalInfo?.id}"
           mat-button>
          <mat-icon [svgIcon]="'calendar'" class="icon"></mat-icon>
          {{ 'doctorProfile.bookSingleVisitLabel' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="personal-information-wrapper">
    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'myNetwork.specialityLabel' | translate }}</span>
        <span class="info-description">{{ personalInfo?.specialtyName }}</span>
      </div>
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'doctorProfile.spokenLanguagesLabel' | translate }}</span>
        <span class="info-description">
          <ng-container *ngFor="let language of personalInfo?.languages; let last = last">
              <span>{{ language.name }}<span *ngIf="!last">, </span></span>
          </ng-container>
        </span>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'doctorProfile.areaOfExpertiseLabel' | translate }}</span>
        <span class="info-description">{{ personalInfo?.specialtyDescription }}</span>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'doctorProfile.medicalSchoolLabel' | translate }}</span>
        <span class="info-description">
          <ng-container *ngFor="let education of personalInfo?.educations; let last = last">
              <span>{{ education.institutionName }}<span *ngIf="!last">, </span></span>
          </ng-container>
        </span>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'profileSettings.practiceLocationLabel' | translate }}</span>
        <div class="info-description">
          <span *ngFor="let experience of personalInfo?.workExperiences" class="d-block">{{ experience.address }}</span>
        </div>
      </div>
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'doctorProfile.licenseStatesLabel' | translate }}</span>
        <div class="info-description">
          <span *ngFor="let experience of personalInfo?.medicalLicenses; let last = last">
            {{ experience.stateOrRegion }}<span *ngIf="!last && experience.stateOrRegion">, </span>
          </span>
        </div>
      </div>
    </div>

    <div class="personal-info-box">
      <div class="info-box-description-wrapper d-flex">
        <span class="description-title">{{ 'doctorProfile.bioLabel' | translate }}
          ({{ 'doctorProfile.aboutLabel' | translate }})</span>
        <span class="info-description">{{ personalInfo?.biography }}</span>
      </div>
    </div>
  </div>
</div>
