import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MedicalHistoryAvailabilityInterface, SelectedPatientInterface } from '../../../core/interfaces';
import { FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import {
  CompleteMedicalHistoryDialogComponent
} from '../../dialogs/complete-medical-history-dialog/complete-medical-history-dialog.component';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { MedicalHistoryApiService } from '../../../core/services';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from '../../dialogs/offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';
import * as moment from 'moment/moment';


@Component({
  selector: 'vi-clinic-provide-ehr-access',
  templateUrl: './provide-ehr-access.component.html',
  styleUrls: ['./provide-ehr-access.component.scss']
})
export class ProvideEhrAccessComponent implements OnInit, OnDestroy {
  @Input() public selectedUser: SelectedPatientInterface;
  @Input() public shareMyEHR: boolean;
  @Input() public medicalHistoryAvailability: MedicalHistoryAvailabilityInterface;

  @Output() public hasEhrAccess = new EventEmitter<boolean>();

  public shareMyEHRFormControl = this.fb.control<boolean>(false);
  public confirmationDocumentLink = `${environment.apiAddress}/api/documents/appointmentconfirmation`;

  private unsubscribe$: Subject<void> = new Subject();

  constructor(private readonly fb: FormBuilder,
              private readonly matDialog: MatDialog,
              private readonly medicalHistoryApiService: MedicalHistoryApiService) {
  }

  public ngOnInit(): void {
    this.subscribeToMyEHRFormControl();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private editMedicalHistoryDialog(): void {
    const dialog = this.matDialog.open(OfflineEhrPersonalMedicalHistoryDialogComponent, {
      data: {
        patientId: this.selectedUser.patientId,
        familyMemberId: this.selectedUser?.familyMemberId
      },
      disableClose: true
    });
    dialog.afterClosed().pipe(
      filter((result) => !!result),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: () => {
        this.checkMedicalHistory();
      }
    });
  }

  private checkMedicalHistory(): void {
    this.medicalHistoryApiService.checkMedicalHistory(this.selectedUser?.familyMemberId)
      .pipe(
        map((el) => {
          const lastUpdateDate = moment(el.lastUpdateDate);
          const currentDay = moment();

          return {
            ...el,
            isOld: currentDay.diff(lastUpdateDate, 'days') > 14
          };
        }),
        takeUntil(this.unsubscribe$))
      .subscribe({
        next: (value) => {
          this.selectedUser.medicalHistoryUpdatedTimestamp = value.lastUpdateDate;
          this.medicalHistoryAvailability = value;

          if (value.isAvailable && this.shareMyEHRFormControl.value) {
            this.hasEhrAccess.emit(true);
          } else {
            this.hasEhrAccess.emit(false);
          }
        }
      });
  }

  private subscribeToMyEHRFormControl(): void {
    this.shareMyEHRFormControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$))
      .subscribe({
      next: (value) => {
        if (this.medicalHistoryAvailability?.isAvailable && value) {
          this.hasEhrAccess.emit(true);
        } else {
          this.hasEhrAccess.emit(false);
        }
      }
    })
  }

  public openCompleteMedicalHistoryDialog(): void {
    const dialogRefs = this.matDialog.open(CompleteMedicalHistoryDialogComponent, {
      data: { patientId: this.selectedUser.patientId, familyMemberId: this.selectedUser?.familyMemberId }
    });

    dialogRefs.afterClosed()
      .pipe(
        filter(value => !!value),
        switchMap((value) => this.medicalHistoryApiService.editPersonalInfo(value, this.selectedUser.patientId, this.selectedUser?.familyMemberId)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: () => {
          this.editMedicalHistoryDialog();
        }
      });
  }
}
