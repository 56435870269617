import { ProviderServiceFeatureTypeEnum } from '../enums';

export const subscriptionServiceOptionsConstant = [
  {
    code: ProviderServiceFeatureTypeEnum.Messages,
    name: 'providerService.messagesLabel',
    icon: 'message-icon',
  },
  {
    code: ProviderServiceFeatureTypeEnum.Appointments,
    name: 'providerService.appointmentsLabel',
    icon: 'video',
  },
  {
    code: ProviderServiceFeatureTypeEnum.AsapAppointments,
    name: 'providerService.appointmentsLabel',
    icon: 'video',
  }
];
