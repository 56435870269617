import { Injectable } from '@angular/core';
import {
  BoardCertificationsInterface,
  LicenseInterface,
  LicenseSuspensionHistoryInterface
} from '../../interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MedicalLicenseApiService {
  private readonly apiUrl: string = `${environment.apiAddress}/api/MedicalLicense`;

  constructor(private readonly httpClient: HttpClient) {
  }

  public saveLicenseSuspensionHistory(data: LicenseSuspensionHistoryInterface): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/licenseSuspensionHistory`, data);
  }

  public updateLicenseSuspensionHistory(data: LicenseSuspensionHistoryInterface): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/licenseSuspensionHistory`, data);
  }

  public saveBoardCertifications(data: BoardCertificationsInterface): Observable<void> {
    return this.httpClient.post<void>(`${this.apiUrl}/boardCertification`, data);
  }

  public updateBoardCertifications(data: BoardCertificationsInterface): Observable<void> {
    return this.httpClient.put<void>(`${this.apiUrl}/boardCertification`, data);
  }

  public saveLicenseExperience(data: LicenseInterface): Observable<LicenseInterface> {
    return this.httpClient.post<LicenseInterface>(this.apiUrl, data);
  }

  public updateLicenseExperience(data: LicenseInterface, id: string): Observable<LicenseInterface> {
    return this.httpClient.put<LicenseInterface>(`${this.apiUrl}/${id}`, data);
  }

  public deleteLicenseExperience(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}/${id}`);
  }
}
