<div class="modal-wrapper" [formGroup]="customAppointmentForm">
  <h1 class="page-title">{{'patients.customAppointmentOfferLabel' | translate}}</h1>

  <h2 class="dialog-title">{{'patients.appointmentTimeOptionsLabel' | translate}}</h2>

  <h2 class="dialog-subtitle">{{'patients.addYourTimeOptionsLabel' | translate}}</h2>

  <vi-clinic-propose-time-options
    (proposeTimeOptionsEvent)="setProposedTimeOptions($event)">
  </vi-clinic-propose-time-options>

  <h2 class="dialog-title">{{'patients.customAppointmentPriceLabel' | translate}}</h2>

  <h2 class="dialog-subtitle">{{'patients.addYourPriceLabel' | translate}}</h2>

  <div class="d-flex align-items-center m-t-2">
    <div>
      <mat-form-field class="price-input" appearance="outline">
        <mat-label>{{'patients.setPriceLabel' | translate}}</mat-label>

        <input formControlName="customPrice" matInput type="number"/>
      </mat-form-field>

      <vi-clinic-control-validation-message [control]="customAppointmentForm.get('customPrice')">
      </vi-clinic-control-validation-message>
    </div>

    <button (click)="customAppointmentForm.get('customPrice').reset()" class="m-l-1" mat-icon-button>
      <mat-icon [svgIcon]="'reset'"></mat-icon>
    </button>
  </div>

  <mat-form-field appearance="outline" class="textarea w-100 m-t-2">
    <mat-label>{{'patients.additionalInformationMessagePatientLabel' | translate}}</mat-label>

    <textarea formControlName="additionalInformation" matInput rows="5"></textarea>
  </mat-form-field>

  <div class="button-container">
    <button class="transparent-button" mat-dialog-close mat-button>
      {{'general.cancelLabel' | translate}}
    </button>

    <button (click)="send()" [disabled]="customAppointmentForm.invalid || !options?.length" class="button-primary" mat-button>
      {{'patients.sentToPatientLabel' | translate}}
    </button>
  </div>
</div>
