import { LanguageLongCodeInterface } from '../interfaces';
import { LanguageTypeEnum } from '../enums';

export const languagesListConstant: LanguageLongCodeInterface[] = [
  {
    'name': 'English',
    'languageCode': 'en',
    'code': LanguageTypeEnum.EN
  },
  {
    'name': 'Español',
    'languageCode': 'es',
    'code': LanguageTypeEnum.ES
  },
];
