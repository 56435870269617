import { MessageInterface } from './message.interface';

export interface ChatDataEventInterface {
  chatId: string;
  chatName?: string;
  fromName: string;
  isGroupChat: boolean;
  isNewChat: boolean;
  notificationId: string;
  value: MessageInterface;
}
