import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SpinnerService } from '../../services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'vi-clinic-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() public isProgressBar = false;

  public isLoadingHttp: boolean = false;

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private readonly spinnerService: SpinnerService,
              private readonly cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.spinnerService.onLoadingChanged$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value) => {
        this.isLoadingHttp = value;
        this.cdr.detectChanges();
      }
    })
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
