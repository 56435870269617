import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  CalendarCommonModule,
  CalendarDateFormatter,
  CalendarEventTitleFormatter,
  CalendarMonthModule,
  CalendarWeekModule
} from 'angular-calendar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../../environments/environment';
import { AppointmentsListItemComponent } from './components/appointments-list-item/appointments-list-item.component';
import { AvailableTimeOptionsComponent } from './components/available-time-options/available-time-options.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CustomDateFormatter } from './components/calendar-view/helpers/custom-date-formatter';
import { CustomEventTitleFormatter } from './components/calendar-view/helpers/custom-event-title-formatter';
import { ChatComponent } from './components/chat/chat.component';
import {
  ControlValidationMessageComponent
} from './components/control-validation-message/control-validation-message.component';
import { EditPrescriptionComponent } from './components/edit-prescription/edit-prescription.component';
import { EditRecommendationComponent } from './components/edit-recommendation/edit-recommendation.component';
import { FilterVisitsComponent } from './components/filter-visit/filter-visits.component';
import { MedicalHistoryComponent } from './components/medical-history/medical-history.component';
import {
  OfflineEhrGeneralInformationComponent
} from './components/offline-ehr-general-information/offline-ehr-general-information.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PatientEhrComponent } from './components/patient-ehr/patient-ehr.component';
import { PatientVisitComponent } from './components/patient-visit/patient-visit.component';
import { ProposeTimeOptionsComponent } from './components/propose-time-options/propose-time-options.component';
import { RecommendationComponent } from './components/recommendation/recommendation.component';
import { RecordsComponent } from './components/record/records.component';
import { RequestStatusComponent } from './components/request-status/request-status.component';
import { SectionSpinnerComponent } from './components/section-spinner/section-spinner.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { SuggestedTimeOptionsComponent } from './components/suggested-time-options/suggested-time-options.component';
import { AddColleagueDialogComponent } from './dialogs/add-colleague-dialog/add-colleague-dialog.component';
import { AddFamilyMemberDialogComponent } from './dialogs/add-family-member-dialog/add-family-member-dialog.component';
import { AddNewPatientsDialogComponent } from './dialogs/add-new-patients-dialog/add-new-patients-dialog.component';
import { ChatFileUploadDialogComponent } from './dialogs/chat-file-upload-dialog/chat-file-upload-dialog.component';
import { ChatParticipantsDialogComponent } from './dialogs/chat-participants-dialog/chat-participants-dialog.component';
import {
  CompleteMedicalHistoryDialogComponent
} from './dialogs/complete-medical-history-dialog/complete-medical-history-dialog.component';
import { RegistrationProcessConfirmationDialogComponent } from './dialogs/registration-process-confirmation-dialog/registration-process-confirmation-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { EditPrescriptionDialogComponent } from './dialogs/edit-prescription/edit-prescription-dialog.component';
import { EditRecommendationDialogComponent } from './dialogs/edit-recommendation/edit-recommendation-dialog.component';
import {
  OfflineEhrPersonalMedicalHistoryDialogComponent
} from './dialogs/offline-ehr-personal-medical-history-dialog/offline-ehr-personal-medical-history-dialog.component';
import { OfflineVisitDialogComponent } from './dialogs/offline-visit-dialog/offline-visit-dialog.component';
import {
  RemoveChatWarningDialogComponent
} from './dialogs/remove-chat-warning-dialog/remove-chat-warning-dialog.component';
import { RenameChatDialogComponent } from './dialogs/rename-chat-dialog/rename-chat-dialog.component';
import { WriteMessageDialogComponent } from './dialogs/write-message-dialog/write-message-dialog.component';
import { ChatFooterComponent } from './components/chat-footer/chat-footer.component';
import { AddPatientDialogComponent } from './dialogs/add-patient-dialog/add-patient-dialog.component';
import {
  CreateCustomAppointmentOfferDialogComponent
} from './dialogs/create-custom-appointment-offer-dialog/create-custom-appointment-offer-dialog.component';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import {
  RegistrationPatientDialogComponent
} from './dialogs/registration-patient-dialog/registration-patient-dialog.component';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { MaterialModule } from './modules/material.module';
import { FileSizeConverterPipe } from './pipes/file-size-converter.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { MaskPipe } from './pipes/mask.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { ListItemWrapperComponent } from './components/list-item-wrapper/list-item-wrapper.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ImageEmptyStateComponent } from './components/image-empty-state/image-empty-state.component';
import { SubscriptionOptionComponent } from './components/subscription-option/subscription-option.component';
import {
  SubscriptionSelectedPriceOptionComponent
} from './components/subscription-selected-price-option/subscription-selected-price-option.component';
import { RegisterNewPatientComponent } from './components/register-new-patien/register-new-patient.component';
import { TranscriptionDialogComponent } from './dialogs/transcription-dialog/transcription-dialog.component';
import { SymptomsComponent } from './components/symptoms/symptoms.component';
import { ProvideEhrAccessComponent } from './components/provide-ehr-access/provide-ehr-access.component';
import {
  ProvideEhrAccessDialogComponent
} from './dialogs/provide-ehr-acctss-dialog/provide-ehr-access-dialog.component';
import {
  DoctorProfilePersonalInfoComponent
} from './components/doctor-profile-personal-info/doctor-profile-personal-info.component';

const PIPES = [
  MaskPipe,
  SearchPipe,
  TimeAgoPipe,
  LocalDatePipe,
  FileSizeConverterPipe
];
const DIALOGS = [
  EditRecommendationDialogComponent,
  EditPrescriptionDialogComponent,
  ConfirmDialogComponent,
  WriteMessageDialogComponent,
  AddColleagueDialogComponent,
  RemoveChatWarningDialogComponent,
  RenameChatDialogComponent,
  ChatParticipantsDialogComponent,
  AddNewPatientsDialogComponent,
  OfflineEhrPersonalMedicalHistoryDialogComponent,
  OfflineVisitDialogComponent,
  RegistrationProcessConfirmationDialogComponent,
  AddFamilyMemberDialogComponent,
  CompleteMedicalHistoryDialogComponent,
  ChatFileUploadDialogComponent,
  AddPatientDialogComponent,
  CreateCustomAppointmentOfferDialogComponent,
  RegistrationPatientDialogComponent,
  TranscriptionDialogComponent,
  ProvideEhrAccessDialogComponent
];
const COMPONENTS = [
  DoctorProfilePersonalInfoComponent,
  AppointmentsListItemComponent,
  RequestStatusComponent,
  ControlValidationMessageComponent,
  PatientEhrComponent,
  PatientVisitComponent,
  FilterVisitsComponent,
  ChatComponent,
  RecommendationComponent,
  RecordsComponent,
  SectionSpinnerComponent,
  ProposeTimeOptionsComponent,
  CalendarViewComponent,
  OfflineEhrGeneralInformationComponent,
  EditRecommendationComponent,
  EditPrescriptionComponent,
  PaginatorComponent,
  StarRatingComponent,
  AvailableTimeOptionsComponent,
  SuggestedTimeOptionsComponent,
  MedicalHistoryComponent,
  ChatFooterComponent,
  InviteUserComponent,
  ListItemWrapperComponent,
  EmptyStateComponent,
  ImageEmptyStateComponent,
  SubscriptionOptionComponent,
  SubscriptionSelectedPriceOptionComponent,
  RegisterNewPatientComponent,
  SymptomsComponent,
  ProvideEhrAccessComponent
];
const DIRECTIVES = [
  ObserveVisibilityDirective,
];
const MODULES: any[] = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MaterialModule,
  NgxIntlTelInputModule,
  CalendarMonthModule,
  CalendarWeekModule,
  CalendarCommonModule,
  NgxMaterialTimepickerModule,
  NgxStripeModule.forRoot(environment.stripeKey)
];


@NgModule({
  declarations: [
    ...PIPES,
    ...DIALOGS,
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...MODULES
  ],
  providers: [
    LocalDatePipe,
    CurrencyPipe,
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    }
  ]
})
export class SharedModule {
}
