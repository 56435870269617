import { AddressInterface } from '../profile-settings';

export interface AccountInfoInterface {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  phoneNumber?: string;
  email: string;
  address: AddressInterface;
}
