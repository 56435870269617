import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vi-clinic-list-item-wrapper',
  templateUrl: './list-item-wrapper.component.html',
  styleUrls: ['./list-item-wrapper.component.scss']
})
export class ListItemWrapperComponent {
  @Input() public isSelectedCard: boolean = false;
  @Output() public selectCardEmitter = new EventEmitter();
}
