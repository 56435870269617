import { WorkExperienceInterface } from './work-experience.interface';
import { EducationExperienceInterface } from './education-experience.interface';
import { LicenseInterface } from './license.interface';

export interface ExperiencesFormInterface {
  medicalSpeciality: string;
  workExperiences: WorkExperienceInterface[];
  educationExperiences: EducationExperienceInterface[];
  licenses: LicenseInterface[];
}
