import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { PatientInterface, } from '../../../core/interfaces';

import { MessagesComponent } from '../../../+messages/messages.component';

import { DoctorApiService, NotificationService } from '../../../core/services';


@Component({
  templateUrl: './add-patient-dialog.component.html',
  styleUrls: ['./add-patient-dialog.component.scss']
})
export class AddPatientDialogComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject();

  public searchName: FormControl<string> = this.fb.control('');
  public patients: PatientInterface[];
  public selectedPatientsIds: string[] = [];
  public participantsIds: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: { participantsIds: string[], canCreateChat: boolean },
              private readonly fb: FormBuilder,
              private readonly notificationService: NotificationService,
              private readonly doctorApiService: DoctorApiService,
              private readonly dialogRef: MatDialogRef<MessagesComponent>) {
  }

  public ngOnInit(): void {
    this.participantsIds = this.dialogData.participantsIds
    this.getPatients();
  }

  private getPatients(): void {
    this.doctorApiService.getRelatedPatients()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (patients) =>  {
          if(this.participantsIds?.length) {
            this.patients  = patients.filter((patient) => !this.participantsIds.includes(patient.id));
          } else {
            this.patients = patients;
          }
        },
        (error) => this.notificationService.showErrorNotification(error?.error?.detail));
  }

  public selectPatient(selectedPatientId: string): void {
    if (this.selectedPatientsIds.includes(selectedPatientId)) {
      const selectedPatientIndex = this.selectedPatientsIds.findIndex((patientId) => patientId === selectedPatientId);
      this.selectedPatientsIds.splice(selectedPatientIndex, 1);
    } else {
      this.selectedPatientsIds.push(selectedPatientId);
    }
  }

  public save(): void {
    let participants = [];

    const newParticipants = this.selectedPatientsIds.map((id) => {
      const selectedPatient = this.patients.find((patient) => patient.patientId === id);

      return {
        firstName: selectedPatient.firstName,
        lastName: selectedPatient.lastName,
        id: selectedPatient.patientId
      }
    });

   participants.push(...newParticipants);

    const data = {
      otherUserIds: this.selectedPatientsIds,
      participants
    };

    this.dialogRef.close(data);
  }

  public resetSearch(): void {
    this.searchName.reset();
  }
}
