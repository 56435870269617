export interface FamilyMemberInterface {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  relationship: number;
  medicalHistoryUpdatedTimestamp: string;
  id: string;
  patientId?: string;
  relationshipName?: string;
}
