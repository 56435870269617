import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as moment from 'moment';
import { DateTimeService } from '../../../../core/services';


@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
  constructor(private readonly dateTimeService: DateTimeService) {
    super(dateTimeService.locale);
  }

  public format(date: moment.Moment): string {
    const locale = this.dateTimeService.locale;
    const format = this.dateTimeService.format;

    return date.locale(locale).format(format);
  }
}
