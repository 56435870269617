import { BillingAddressInterface } from './billing-address.interface';

export interface BankingInfoInterface {
  firstName: string;
  lastName: string;
  iban: string;
  bankSwiftCode: string;
  bankName: string;
  bankBillingAddress: BillingAddressInterface;
  doctorBillingAddress: BillingAddressInterface;
  id?: string;
}
