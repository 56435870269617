// modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// components
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { ForbiddenComponent } from './core/components/forbidden/forbidden.component';
import { PendingApprovalComponent } from './core/components/pending-approval/pending-approval.component';
import { RejectedComponent } from './core/components/rejected/rejected.component';

// helpers
import { StatusGuard } from './core/guards/status-guard.service';
import { DoctorStatusEnum, RoleEnum } from './core/enums';
import { VideoGuard } from './core/guards/video-guard.service';
import { RoleGuard } from './core/guards/role-guard.service';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requests',
  },
  {
    path: 'requests',
    loadChildren: () => import('./+requests/requests.module').then(m => m.RequestsModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'appointments',
    loadChildren: () => import('./+appointments/appointments.module').then(m => m.AppointmentsModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'visits',
    loadChildren: () => import('./+visits/visits.module').then(m => m.VisitsModule),
    canActivate: [RoleGuard],
    data: {
      roles: [RoleEnum.Patient]
    }
  },
  {
    path: 'visit-history',
    loadChildren: () => import('./+visits-history/visits-history.module').then(m => m.VisitsHistoryModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor, RoleEnum.Patient], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'doctor-ai',
    loadChildren: () => import('./+chat-ai/chat-ai.module').then(m => m.ChatAiModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'messages',
    loadChildren: () => import('./+messages/messages.module').then(m => m.MessagesModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor, RoleEnum.Patient], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'alerts',
    loadChildren: () => import('./+alerts/alerts.module').then(m => m.AlertsModule),
    canActivate: [RoleGuard],
    data: {
      roles: [RoleEnum.Doctor, RoleEnum.Patient]
    }
  },
  {
    path: 'invitations',
    loadChildren: () => import('./+invitations/invitations.module').then(m => m.InvitationsModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor, RoleEnum.Patient], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'my-network',
    loadChildren: () => import('./+my-network/my-network.module').then(m => m.MyNetworkModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor, RoleEnum.Patient], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'patients',
    loadChildren: () => import('./+patients/patients.module').then(m => m.PatientsModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'concierge-service',
    loadChildren: () => import('./+concierge-service/concierge-service.module').then(m => m.ConciergeServiceModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor, RoleEnum.Patient], statuses: [DoctorStatusEnum.Active]
    }
  },
  {
    path: 'doctors',
    loadChildren: () => import('./+doctors/doctors.module').then(m => m.DoctorsModule),
    canActivate: [RoleGuard],
    data: {
      roles: [RoleEnum.Patient]
    }
  },
  {
    path: 'services',
    loadChildren: () => import('./+services/services.module').then(m => m.ServicesModule),
    canActivate: [RoleGuard],
    data: {
      roles: [RoleEnum.Patient]
    }
  },
  {
    path: 'medical-information',
    loadChildren: () => import('./+medical-information/medical-information.module').then(m => m.MedicalInformationModule),
    canActivate: [RoleGuard],
    data: {
      roles: [RoleEnum.Patient]
    }
  },
  {
    path: 'profile-settings',
    loadChildren: () => import('./+profile-settings/profile-settings.module').then(m => m.ProfileSettingsModule),
    canActivate: [RoleGuard, StatusGuard],
    data: {
      roles: [RoleEnum.Doctor],
      statuses: [DoctorStatusEnum.Registered, DoctorStatusEnum.ChangeRequested, DoctorStatusEnum.Active]
    }
  },
  {
    path: 'account-settings',
    loadChildren: () => import('./+account-settings/account-settings.module').then(m => m.AccountSettingsModule),
    canActivate: [RoleGuard],
    data: { roles: [RoleEnum.Patient, RoleEnum.Doctor], statuses: [DoctorStatusEnum.Active] }
  },
  {
    path: 'video',
    loadChildren: () => import('./+video/video.module').then(m => m.VideoModule),
    canActivate: [RoleGuard, StatusGuard, VideoGuard],
    data: { roles: [RoleEnum.Doctor, RoleEnum.Patient], statuses: [DoctorStatusEnum.Active] }
  },
  {
    path: 'support',
    loadChildren: () => import('./+support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'pending-approval',
    component: PendingApprovalComponent,
    canActivate: [RoleGuard, StatusGuard],
    data: { roles: [RoleEnum.Doctor], statuses: [DoctorStatusEnum.PendingApproval] }
  },
  {
    path: 'rejected',
    component: RejectedComponent,
    canActivate: [RoleGuard, StatusGuard],
    data: { roles: [RoleEnum.Doctor], statuses: [DoctorStatusEnum.Rejected] }
  },
  {
    path: '403',
    component: ForbiddenComponent
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}

