import { BoardCertificationsInterface } from './board-certifications.interface';
import { EducationExperienceInterface } from './education-experience.interface';
import { LicenseSuspensionHistoryInterface } from './license-suspension-history.interface';
import { LicenseInterface } from './license.interface';
import { WorkExperienceInterface } from './work-experience.interface';

export interface DoctorProfileInterface {
  mainSpecialtyId: string;
  mainSpecialty?: string;
  specialtyDescription?: string;
  workExperiences?: WorkExperienceInterface[];
  educations?: EducationExperienceInterface[];
  medicalLicenses?: LicenseInterface[];
  licenseSuspensionHistory?: LicenseSuspensionHistoryInterface;
  boardCertification?: BoardCertificationsInterface;
  doctorId?: string;
  references?: [];
  id?: string;
}
