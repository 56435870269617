import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  public transform(value: any[], field: string): any {
    if (!field) {
      return value;
    }
    if (!value) {
      return [];
    }

    return value.filter(item => {
      let searchValue: string;

      if (item.fullName) {
        searchValue = item.fullName;
      } else if (item.familyMemberInfo || item.patientInfo) {
        const user = item.familyMemberInfo || item.patientInfo;
        searchValue = `${user.firstName} ${user.lastName}`;
      } else if (item.chatName) {
        searchValue = item.chatName;
      } else if (item.firstName && item.lastName) {
        searchValue = `${item.firstName} ${item.lastName}`;
      }

      return searchValue.toLowerCase().includes(field.toLowerCase());
    });
  }
}

