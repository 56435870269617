import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './registration-process-confirmation-dialog.component.html',
  styleUrls: ['./registration-process-confirmation-dialog.component.scss']
})
export class RegistrationProcessConfirmationDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<RegistrationProcessConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public readonly data: { title: string }) {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public closeAll(): void {
    this.dialogRef.close(true);
  }
}
